
enum PhaseName  {
    Phase1 = 'Phase1-Assess',
    Phase2 = 'Phase2-Select',
    Phase3 = 'Phase3-Define',
    Phase4 = 'Phase4-Execute',
    Phase5 = 'Phase5-Operate',
    Phase52 = 'Phase5-Post Decomm.'
}

export default PhaseName