

export default class DateHelper{
    static getMonths (){
        return  ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    }

    static getDay(day:number){
        return (day < 10 ) ? '0' + day : day
    }

    static getMonth(index:number){
        let months = this.getMonths()
        return months[index]
    }

    static getDate2Digit(date:string | Date | null){
        if(!date) return ""
        
        let d = new Date(date)
        return this.getDay(d.getDate()) + ' ' + this.getMonth(d.getMonth()) + ' ' + d.getFullYear().toString().slice(-2)
    }

    static getDate(date:string | Date | null){
        if(!date) return ""
        
        let d = new Date(date)
        return this.getDay(d.getDate()) + ' ' + this.getMonth(d.getMonth()) + ' ' + d.getFullYear()
    }

    static getDateTime(date:string | Date | null){
        if(!date) return ""
        
        let d = new Date(date)
        let dd = this.getDay(d.getDate())
        let yyyy = d.getFullYear()
        let mmm = this.getMonth(d.getMonth())
        let hh =  d.getHours() < 10 ? '0' +  d.getHours() :  d.getHours()
        let mm =  d.getMinutes() < 10 ? '0' +  d.getMinutes() :  d.getMinutes()
        return dd + ' ' + mmm + ' ' + yyyy + ' ' + hh + ':' + mm
    }

    static getDateName(date:string | Date | null){
        if(!date) return ""
        
        let d = new Date(date)
        return  d.getFullYear() + "" + this.getMonth(d.getMonth()) + "" + this.getDay(d.getDate()) + "" + d.getHours() + "" + d.getMinutes() + "" + d.getSeconds()
    }

    static getMonthYear(date:string | Date){
        let d = new Date(date)
        return this.getMonth(d.getMonth()) + ' ' + d.getFullYear()
    }

    static getYearMonth(value:string | Date){
        let date = new Date(value) 
        let yy = date.getFullYear()
        let m:number =  date.getMonth()
        let M = m +1
        let mm = M < 10 ? '0' + M : M
        return parseInt(yy + '' + mm)
    }

    static getYearMonth2(value:string | Date | null | undefined){
        if(value == null || value ==undefined){
            return""
        }

        let date = new Date(value) 
        let yy = date.getFullYear()
        let m:number =  date.getMonth()
        return this.getMonth(m) + " " + yy
    }

    static getDateNumber(value:string | Date){
        let date = new Date(value) 
        let yy = date.getFullYear()
        let m = date.getMonth()+1
        let mm = m < 10 ? '0' + m : m
        let d = date.getDate()
        let dd = d < 10 ? '0' + d : d
        return parseInt(yy + '' + mm + '' + dd)
    }

    static getDateFormat(value:string | Date){
        let date = new Date(value) 
        let yyyy = date.getFullYear()
        let mm = date.getMonth() + 1
        let dd = date.getDate()
        return yyyy + '-' + mm + '-' + dd
    }

    static getDateFormat2(){
        
        let d = new Date()
        let dd = d.getDate()
        let yyyy = d.getFullYear()
        let M = d.getMonth() + 1;
        let MM = M < 10 ? '0' + M : M
        let hh =  d.getHours() < 10 ? '0' +  d.getHours() :  d.getHours()
        let mm =  d.getMinutes() < 10 ? '0' +  d.getMinutes() :  d.getMinutes()
        let ss =  d.getSeconds() < 10 ? '0' +  d.getSeconds() :  d.getSeconds()
        return yyyy + "" + MM + "" + dd + "_" + hh + "" + mm + "" + ss
    }

    static getStartDate(date:string | Date | null){
        if(!date) return null
        
        let d = new Date(date)
        return new Date(d.getFullYear(),0,1)
    }

    static getEndDate(date:string | Date | null){
        if(!date) return null
        
        let d = new Date(date)
        return new Date(d.getFullYear(),11,31)
    }
}