import axios from 'axios'
import { PageDataResponse, DataArrayResponse2,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import {ProjectProgress,DeleteData } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ProjectProgressService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    
    async getAll(id:number,  page: number,limit: number,startDate:string,endDate:string ){
        let options = {
                headers:this.headers,
                params: {
                    projectPhaseId: id,
                    page: page,
                    limit: limit,
                    startDate: startDate,
                    endDate: endDate
                }
            }
        
        const { data }  = await axios.get<PageDataResponse<ProjectProgress>>(`${apiUrl}/v1/projectprogresses/call`, options )
        
        return data;
    }

    async getByProjectId(id:number ){
        let options = {
                headers: this.headers,
                params: {
                    projectId: id
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse2<ProjectProgress>>(`${apiUrl}/v1/projectprogresses/project`, options )
        
        return data;
    }

    async getByDate(id:number,date:Date ){
        let options = {
                headers:this.headers,
                params: {
                    projectPhaseId: id,
                    progressDate: date
                }
            }
        
        const { data }  = await axios.get<DataObjectResponse<ProjectProgress>>(`${apiUrl}/v1/projectprogresses/date`, options )
        
        return data;
    }


    async create(projectProgres:ProjectProgress){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers,
        }

        const { data } = await axios.post<PageResponse>(`${apiUrl}/v1/projectprogresses/call`,JSON.stringify(projectProgres),options )
        return data;
    }

    async update(projectProgres:ProjectProgress){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
           
        }
        const { data } = await axios.put<PageResponse>(`${apiUrl}/v1/projectprogresses/call`,JSON.stringify(projectProgres),options )
        return data;
    }

    async delete(id: number,updateBy: string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v1/projectprogresses/delete`,JSON.stringify(deleteData),options )
        return data;
    }
}