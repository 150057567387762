import axios from 'axios'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import {EmailTemplate } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class EmailTemplateService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    async getAll(){
        let options = {
                headers: this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<EmailTemplate>>(`${apiUrl}/v1/emailtemplates/call`, options )
        
        return data;
    }

    async update(emailTemplate:EmailTemplate){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/emailtemplates/call`,JSON.stringify(emailTemplate),options )
        return data;
    }
    

   
}