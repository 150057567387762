import { createSlice } from '@reduxjs/toolkit'
import { AuthenUser } from '../models'

const initialUser:AuthenUser ={
  userId: 0,
  username:"",
  roleSystemLevel: null
}

export const userSlice = createSlice({
  name: 'userStore',
  initialState: {
      user: initialUser,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    }
  },
})
export default userSlice.reducer;

export const {
    setUser,
 
  } = userSlice.actions

  export const getUser = (state):AuthenUser => state.userStore.user