import React, { useState ,useEffect} from "react";
import { AiOutlineInfoCircle,AiOutlineFile,AiOutlineCloseCircle, 
    AiOutlineFilePdf,AiOutlineFileExcel,AiOutlineFileImage,AiOutlineFileWord,
    AiOutlineFolder, AiOutlineEdit 
} from "react-icons/ai";
import { RiDeleteBin6Line,RiErrorWarningLine } from "react-icons/ri";
import { VscCheck,VscChromeClose,VscAdd,VscLink,
    VscCloudDownload,VscClose,VscChevronRight,VscChevronDown,VscIssueReopened,VscCircleLargeOutline } from "react-icons/vsc";
import { ProjectDeliverableDocumentService ,FileManageService,HistoryLogService,EmailService, ProjectDeliverableService} from '../../services'
import { Tooltip } from 'react-tooltip'
import '../../assets/css/deliverable.css'
import Modal from 'react-bootstrap/Modal'
import { Color ,StatusDeliverableDocument ,StatusDeliverable} from '../../enum'
import { ProjectDeliverable, ProjectDeliverableDocument, ProjectDeliverableItemFile,UploadUrl,ProjectPhaseRole, UserItem,AuthenUser} from '../../models'
import uniqid from 'uniqid';
import { useApiAuth } from '../../hooks/useApiAuth';
import { DateHelper } from "../../helpers";
import Loading from "../Loading";
import LoadingMini from "../LoadingMini";
import { config } from '../../config'
import { ProjectLevel } from "../../enum";
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
    projectId:number
    projectName:string
    projectDeliverable:ProjectDeliverable
    projectPhaseRoles:ProjectPhaseRole[]
    projectOwners:UserItem[]
    gatekeepers:UserItem[]
    onChange:any
}

interface AppState{
    arrowDown:boolean[]
    projectDeliverableDocument:ProjectDeliverableDocument | null
    projectDeliverableDocuments:ProjectDeliverableDocument[]
    projectDeliverableIndex:number
    projectDeliverableItemFileId:number
    deliverableStatus:string
    //fileUploadList:string[]
    isAddUrl:boolean
    isCommentShow:boolean
    isDeleteUrl:boolean
    isDeleteFile:boolean
    isUploading:boolean[]
    isDownloading:boolean[]
    isLoading:boolean
}

const FocalPointDocument = (props:Props) => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        arrowDown: [],
        projectDeliverableDocument:{
            projectDeliverableDocumentId:0,
            projectPhaseId:0,
            deliverableTemplateId:0,
            deliverableItemId:0,
            deliverableItemName:"",
            stageId:0,
            assuranceActivityId:0,
            assuranceActivityName:"",
            deliverableTypeId:0,
            deliverableTypeName:"",
            recomendation:"",
            documentIndex:0,
            focalPointComment:"",
            gatekeeperFeedback:"",
            deliverableDocumentStatusId:0,
            verifyStatusId:0,
            verifyDateTime:new Date(),
            verifyBy:"",
            projectDeliverableItemFiles:[]
        },
        projectDeliverableDocuments: [],
        projectDeliverableIndex:0,
        deliverableStatus:"",
        projectDeliverableItemFileId:0,
        //fileUploadList:[],
        isDeleteUrl:false,
        isDeleteFile:false,
        isAddUrl:false,
        isCommentShow:false,
        isUploading:[],
        isDownloading:[],
        isLoading: true
    })
    const [errors,setErrors] = useState<string[]>([])
    
    useEffect(() => {
        if(accessToken){
            getProjectDeliverableDocuments()
        }
    
     },[accessToken])

     const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }
    const getProjectDeliverableDocuments = async () => {
        let { projectPhaseId,stageId,assuranceActivityId} = props.projectDeliverable
        let token = await getToken()
        let result = await new ProjectDeliverableDocumentService(token).getDocuments(projectPhaseId,stageId,assuranceActivityId)
        //console.log(result)
        if(result && result.success){
            let state = Object.assign({},appState)
            state.projectDeliverableDocuments = result.data
            let length = result.data.length
            state.arrowDown = state.arrowDown.fill(false,0,length)
            state.isUploading = state.isUploading.fill(false,0,length)
            state.isDownloading = state.isDownloading.fill(false,0,length)
            state.deliverableStatus = "Waiting"
            if(state.projectDeliverableDocuments.findIndex(x => x.deliverableDocumentStatusId === StatusDeliverableDocument.Reject) > -1){
                state.deliverableStatus = 'Reject'
            }
            else{
                if(state.projectDeliverableDocuments.filter(x => x.deliverableDocumentStatusId === StatusDeliverableDocument.Reject).length === length){
                    state.deliverableStatus = 'Accept'
                }
            }
            state.isLoading = false
            setAppState(state)
          
        }
  
    }

    const setArrowDown = (index,status) => {
        let state = Object.assign({},appState)
        state.arrowDown[index] = status
        setAppState(state)
    }

    const commentShow = (item:ProjectDeliverableDocument,index:number) => {
        let state = Object.assign({},appState)
        state.projectDeliverableDocument = item
        state.projectDeliverableIndex = index
        state.isCommentShow = true
        setAppState(state)
    }

    const commentClose = () => {
        let state = Object.assign({},appState)
        state.isCommentShow = false
        setAppState(state)
    }

    const upDateComment = async() => {
        let projectDeliverableDocument = Object.assign({},appState.projectDeliverableDocument)
        let inputComment = document.getElementById('focalpoint-comment') as HTMLTextAreaElement
        let comment = ""
        if(inputComment){
            comment = inputComment.value
        }

        let token = await getToken()
        let result = await new ProjectDeliverableDocumentService(token).updateComment(projectDeliverableDocument.projectDeliverableDocumentId,comment,name)
        //console.log(result)
        if(result && result.success){
            let projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === props.projectDeliverable.projectPhaseId).map((p) => { let userItem:UserItem = { username:p.username, name:p.name}; return userItem})
            let projectUsers:UserItem[] = props.projectOwners.concat(projectManagers).concat(props.gatekeepers)
           // new EmailService(accessToken).sendAttachFiles(props.projectId, props.projectName,projectDeliverableDocument.deliverableItemName ,projectDeliverableDocument.projectDeliverableDocumentId,"",projectUsers)
            new HistoryLogService(token).create(user.userId, props.projectId,"PREP Stage Gate Status","Update Comment ")

            let state = Object.assign({},appState)
            state.isCommentShow = false
            state.projectDeliverableDocuments[appState.projectDeliverableIndex].focalPointComment = comment
            setAppState(state)
        }
        
    }
    
    const uploadClick =(item:ProjectDeliverableDocument,index:number) => {
        let state = Object.assign({},appState)
        state.projectDeliverableDocument = item
        state.projectDeliverableIndex = index
        setAppState(state)
        const inputFile =  document.getElementById("input-file") as HTMLInputElement
        inputFile.click();
    }

    const validateFiles = async () => {
        //console.log('validate-files')
        let fileItems:string[] = []
        let errorMessages:string[] = []
      
        // if(appState.projectDeliverableDocument){
        //     fileItems = appState.projectDeliverableDocument.projectDeliverableItemFiles.map((item) =>{ return item.name })
        // }

        let fileInput= document.getElementById('input-file') as HTMLInputElement
        let files:FileList|null = fileInput.files
        
        if(files && files.length > 0){
            let fileUploadList:string[] = []
            if(appState.projectDeliverableDocument){
                let token = await getToken()
                let result = await new ProjectDeliverableService(token).getFiles(appState.projectDeliverableDocument.projectDeliverableDocumentId);
                //console.log(result)
                if(result && result.success){
                    fileUploadList = result.data
                }
            }
            Array.from(files).forEach(file => { 
                let type = file.type.split('/').reverse()[0].toLowerCase()
                let typename = file.name.split('.').reverse()[0].toLowerCase()
                if(config.Upload_File_Extensions.indexOf(type) === -1 && config.Upload_File_Extensions.indexOf(typename) === -1){
                    fileInput.value = ""
                    errorMessages.push(file.name + ", file type is not permitted for upload")
                }
                if(file.size === 0){
                    fileInput.value = ""
                    errorMessages.push(file.name + " has 0 byte size.")
                }
                if(file.size > config.Max_File_Size){
                    fileInput.value = ""
                    errorMessages.push(file.name + " has over size limit 10MB.")
                }
                if(fileUploadList.indexOf(file.name) > -1 ){
                    fileInput.value = ""
                    errorMessages.push(file.name + " already exist.")
                    //console.log("file already exist")
                }
            });

            return errorMessages
        }
        else{
            return ["File not found, please try again."]
        }
        
    }

    const handleFilesUpload = async () => {
        let state = Object.assign({},appState)
        state.arrowDown[appState.projectDeliverableIndex] = true
        state.isUploading[appState.projectDeliverableIndex] = true
        setAppState(state)

        let errorMessages = await validateFiles()
        setErrors([])
        
        if(errorMessages.length == 0){
            let fileInput= document.getElementById('input-file') as HTMLInputElement
            let projectDeliverableDocument:ProjectDeliverableDocument = Object.assign({},appState.projectDeliverableDocument)
            let files:FileList|null = fileInput.files
            let filenames:string[] = []
            ////console.log(files)
            let formData = new FormData();
            formData.append('projectDeliverableDocumentId', projectDeliverableDocument.projectDeliverableDocumentId.toString())
            formData.append('projectId', props.projectId.toString())
            formData.append('projectPhaseId', props.projectDeliverable.projectPhaseId.toString())
            formData.append('stageId', props.projectDeliverable.stageId.toString())
            formData.append('assuranceActivityId', props.projectDeliverable.assuranceActivityId.toString())
            formData.append('createBy', name)
           
            for( var i in files ){
                ////console.log(files[i])
                if(files[i].size){
                    formData.append('files[' + i + ']', files[i]);
                    filenames.push(files[i].name)
                }
            }
            
            try {
                let token = await getToken()
                let result = await new FileManageService(token).uploadFiles(formData);
                //console.log(result)
                if(result && result.success){
                    fileInput.value = ""
                    let historyAction = "Upload Files "+ filenames.join(", ") + " " + props.projectDeliverable.stageCode + "/" + props.projectDeliverable.assuranceActivityName 
                    new HistoryLogService(token).create(user.userId, props.projectId,"PREP Stage Gate Status",historyAction)
                    // let projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === props.projectDeliverable.projectPhaseId).map((p) => { let userItem:UserItem = { username:p.username, name:p.name}; return userItem})
                     // let projectUsers:UserItem[] = props.projectOwners.concat(projectManagers).concat(props.gatekeepers)
                    // new EmailService(accessToken).sendAttachFiles(props.projectId, props.projectName,projectDeliverableDocument.deliverableItemName ,projectDeliverableDocument.projectDeliverableDocumentId,filenames.join(", "),projectUsers)

                  
                    let state2 = Object.assign({},appState)
                        state2.projectDeliverableDocuments[appState.projectDeliverableIndex].deliverableDocumentStatusId = StatusDeliverableDocument.WaitingForVerification
                        state2.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles = result.data
                      //  state2.fileUploadList = state2.fileUploadList.concat(filenames)
                        state2.isUploading[appState.projectDeliverableIndex] = false
    
                    let projectDeliverable = Object.assign({},props.projectDeliverable)
                    projectDeliverable.deliverableStatusId = StatusDeliverable.InProgress
                    props.onChange(projectDeliverable)
                    setAppState(state2)
                }
                else{
                    if(result && result.message){
                        setErrors([result.message])
                    }
                    let state = Object.assign({},appState)
                    state.isUploading[appState.projectDeliverableIndex] = false
                    setAppState(state)
                }
            }
            catch(err) {
                setErrors([err])
                let state = Object.assign({},appState)
                    state.isUploading[appState.projectDeliverableIndex] = false
                    setAppState(state)
            }
            
        }
        else{
            setErrors(errorMessages)
            let state = Object.assign({},appState)
            state.isUploading[appState.projectDeliverableIndex] = false
            setAppState(state)
        }
       
    }

    const download = async (item:ProjectDeliverableItemFile) => {
        //console.log('download')
        let token = await getToken()
        new FileManageService(token).downloadFile(item.path,item.name);
    }

    const downloadZip = async (item:ProjectDeliverableDocument,index:number) => {
        if(item.projectDeliverableItemFiles.length == 0){
            return
        }
        //console.log('downloadZip')
        let state = Object.assign({},appState)
        state.isDownloading[index] = true
        setAppState(state)
        let path = "p" + props.projectId.toString() + "/ph" +  props.projectDeliverable.projectPhaseId.toString() + "/s" + props.projectDeliverable.stageId.toString() + "/a" + props.projectDeliverable.assuranceActivityId + "/d" + item.projectDeliverableDocumentId.toString()
        //console.log(path)
        let token = await getToken()
        await new FileManageService(token).downloadZipFile(path);
        let state2 = Object.assign({},appState)
        state2.isDownloading[index] = false
        setAppState(state2)
    }

    const confirmAddUrl = (item:ProjectDeliverableDocument,index:number) => {
        let state = Object.assign({},appState)
        state.projectDeliverableDocument = item
        state.projectDeliverableIndex = index
        state.isAddUrl = true
        setAppState(state)
    }

    const addUrl = async () =>{
        let projectDeliverableDocument = Object.assign({},appState.projectDeliverableDocument)
        let inputUrlName = document.getElementById('url-name') as HTMLInputElement
        let inputUrlPath = document.getElementById('url-path') as HTMLInputElement
        if(inputUrlName.value == "" || inputUrlPath.value == ""){
            return
        }
        let uploadUrl:UploadUrl = {
            projectDeliverableDocumentId: projectDeliverableDocument.projectDeliverableDocumentId,
            UrlName:inputUrlName.value,
            UrlPath:inputUrlPath.value,
            CreateBy:name
        }
        let token = await getToken()
        let result = await new FileManageService(token).uploadUrl(uploadUrl);

        let historyAction = "Add URL "+ uploadUrl.UrlName + " " + props.projectDeliverable.stageCode + "/" + props.projectDeliverable.assuranceActivityName
        new HistoryLogService(token).create(user.userId, props.projectId,"PREP Stage Gate Status",historyAction)
        let projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === props.projectDeliverable.projectPhaseId).map((p) => { let userItem:UserItem = { username:p.username, name:p.name}; return userItem})
        let projectUsers:UserItem[] = props.projectOwners.concat(projectManagers).concat(props.gatekeepers)
        //new EmailService(accessToken).sendAttachFiles(props.projectId, props.projectName,projectDeliverableDocument.deliverableItemName ,projectDeliverableDocument.projectDeliverableDocumentId,uploadUrl.UrlName,projectUsers)

        let state = Object.assign({},appState)
        if(result){
            state.projectDeliverableDocuments[appState.projectDeliverableIndex].deliverableDocumentStatusId = StatusDeliverableDocument.WaitingForVerification
            state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles = result.data
        }
        
        state.arrowDown[appState.projectDeliverableIndex] = true
        state.isAddUrl = false

        let projectDeliverable = Object.assign({},props.projectDeliverable)
        projectDeliverable.deliverableStatusId = StatusDeliverable.InProgress
        props.onChange(projectDeliverable)
        setAppState(state)
    }

    const confirmDeleteFile = (projectDeliverableItemFileId:number,index:number) => {
        let state = Object.assign({},appState)
        state.projectDeliverableItemFileId = projectDeliverableItemFileId
        state.projectDeliverableIndex = index
        state.isDeleteFile = true
        setAppState(state)
    }

    const deleteFile = async () => {
        let token = await getToken()
        let result = await new FileManageService(token).deleteFile(appState.projectDeliverableItemFileId,name);
        
        //console.log(result)
        let state = Object.assign({},appState)
        if(result && result.success){
            let fileInput= document.getElementById('input-file') as HTMLInputElement
            fileInput.value = ""
            let delFile = state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles.find(x => x.projectDeliverableItemFileId == appState.projectDeliverableItemFileId)
            let historyAction = "Delete File "+ delFile?.name + " " + props.projectDeliverable.stageCode + "/" + props.projectDeliverable.assuranceActivityName + "/" + state.projectDeliverableDocuments[appState.projectDeliverableIndex].deliverableItemName
            new HistoryLogService(token).create(user.userId,props.projectId,"PREP Stage Gate Status",historyAction)

            state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles = result.data
           // state.fileUploadList = []
        }
        else{
            if(result && result.message)
                setErrors([result.message])
        }
        state.isDeleteFile = false
        if(state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles.length == 0){
            state.projectDeliverableDocuments[appState.projectDeliverableIndex].deliverableDocumentStatusId = StatusDeliverableDocument.WaitingForAttach
            
            let projectDeliverable = Object.assign({},props.projectDeliverable)
            projectDeliverable.deliverableStatusId = StatusDeliverable.WaitingForStart
            props.onChange(projectDeliverable)
        }
        
        setAppState(state)
    }

    const confirmDeleteUrl = (projectDeliverableItemFileId:number,index:number) => {
        let state = Object.assign({},appState)
        state.projectDeliverableItemFileId = projectDeliverableItemFileId
        state.projectDeliverableIndex = index
        state.isDeleteUrl = true
        setAppState(state)
    }

    const deleteUrl = async () => {
        let token = await getToken()
        let result = await new FileManageService(token).deleteUrl(appState.projectDeliverableItemFileId,name);
        //console.log(result)
        let state = Object.assign({},appState)
        if(result){
            let delUrl = state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles.find(x => x.projectDeliverableItemFileId == appState.projectDeliverableItemFileId)
            let historyAction = "Delete Url "+ delUrl?.name + " " + props.projectDeliverable.stageCode + "/" + props.projectDeliverable.assuranceActivityName + "/" + state.projectDeliverableDocuments[appState.projectDeliverableIndex].deliverableItemName
            new HistoryLogService(token).create(user.userId, props.projectId,"PREP Stage Gate Status",historyAction)
            
            state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles = result.data
        }
        state.isDeleteUrl = false
        if(state.projectDeliverableDocuments[appState.projectDeliverableIndex].projectDeliverableItemFiles.length == 0){
            state.projectDeliverableDocuments[appState.projectDeliverableIndex].deliverableDocumentStatusId = StatusDeliverableDocument.WaitingForAttach
            
            let projectDeliverable = Object.assign({},props.projectDeliverable)
            projectDeliverable.deliverableStatusId = StatusDeliverable.WaitingForStart
            props.onChange(projectDeliverable)
        }
        setAppState(state)
    }


    const onAddUrlClose = () =>{
        let state = Object.assign({},appState)
        state.isAddUrl = false
        setAppState(state)
    }

    const onDeleteClose = () =>{
        let state = Object.assign({},appState)
        state.isDeleteFile = false
        state.isDeleteUrl = false
        setAppState(state)
    }

    const redirectUrl = (url:string) => {
        window.open(url, '_blank');
    }

    return (
        <div style={{marginTop:'10px' }} >
           
            <div className="row">
                <div className="col-md-6">
                    <span className="col-label font-blue">Stage:</span>
                    <span style={{ marginLeft: '5px'}} >{ props.projectDeliverable.stageName }</span>
                </div>
                <div className="col-md-6">
                    <span className="col-label font-blue">Assurance Activity:</span>
                    <span style={{ marginLeft: '5px'}} >{ props.projectDeliverable.assuranceActivityName}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <span className="col-label font-blue">Current Assurance Status:</span>
                    <span style={{ marginLeft: '5px' }} >{ props.projectDeliverable.deliverableStatusName }</span>

                </div>
                <div className="col-md-6">
                    <span className="col-label font-blue" style={{ marginRight: '5px' }} >Remark Assurance Status:</span>
                    <span style={{ marginLeft: '5px'}} >{ props.projectDeliverable.remark}</span>
                    {/*
                    <span className="col-label font-blue">Deliverable Status:</span>
                    { appState.deliverableStatus === 'Accept' ? <VscCheck size={18} color='green' style={{ marginLeft: '5px'}} /> : null }
                    { appState.deliverableStatus === 'Reject' ? <VscClose size={18} color="red" style={{ marginRight: '5px'}} />: null }
                    <span style={{ marginLeft: '5px'}} >{appState.deliverableStatus}</span>
                    */}
                </div>
            </div>
       
            <div className='row' style={{ marginTop: '30px'}} >
                <div className='col-md-4'>
                   

                </div>
                <div className='col-md-8' style={{ textAlign: 'right'}} >
                    <span style={{ marginRight: '5px'}} >
                        <span style={{ fontWeight: 'bold', fontSize: '12px' }} >Verification Status:</span>
                    </span>
                    <span>
                        <VscCheck size={18} color='green' style={{ marginRight: '5px'}} />
                        <span style={{ fontSize: '13px'}} >Accept</span>
                    </span>
                    <span style={{ marginLeft: '10px'}} >
                        <VscClose size={20} color="red" style={{ marginRight: '5px'}} />
                        <span style={{ fontSize: '13px'}} >Reject</span>
                    </span>
                    <span style={{ marginLeft: '10px'}} >
                        <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}} />
                        <span style={{ fontSize: '13px'}} >Waiting for Verification</span>
                    </span>
                    <span style={{ marginLeft: '10px'}} >
                        <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}} />
                        <span style={{ fontSize: '13px'}} >Wait for Deliverable</span>
                    </span>
                </div>
            </div>
            
        <div className="table-responsive table-responsive-sm" >
            <table className="table deliverable-table">
                <thead>
                    <tr style={{ borderTop: '1px solid'}} >
                        <th>Verification Status</th>
                            <th style={{ textAlign: 'center', minWidth: '600px' }} >Deliverable Name</th>
                            <th style={{ width: '150px' }} >Recommendation</th>
                            <th style={{ width: '150px' }} >Focal Point Comment</th>
                            <th style={{ width: '150px' }} >Gatekeeper Feedback</th>
                        <th style={{ width: '100px'}} >
                            <span>Deliverable Type</span>
                            <a data-tooltip-id='deliverable-type-info' style={{cursor: 'pointer'}} >
                                <AiOutlineInfoCircle color='#00338D' size={20} style={{ verticalAlign: 'middle',marginLeft: '2px'}} />
                            </a>
                            <Tooltip id='deliverable-type-info' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <div>M = Mandatory</div>
                                <div>C = Conditional</div>
                            </Tooltip>
                        </th>
                        
                        <th style={{ textAlign: 'center',minWidth: '100px'}} >Verified by</th>
                    </tr>
                </thead>
              
                { appState && !appState.isLoading ?
                     <tbody>
                    { appState.projectDeliverableDocuments.map((item,index) =>(
                    <>
                        <tr key={uniqid()} className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}} >
                            <td>
                                <div className="deliverable-user">
                                { !appState.arrowDown[index] ?
                                    <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}}  onClick={ () => setArrowDown(index,true)}/>
                                    :
                                    <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left' }}  onClick={() => setArrowDown(index, false)} />
                                     
                                }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Accept ? <VscCheck size={18}color='green' style={{ marginRight: '5px'}} /> : null }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Reject ?   <VscClose size={20} color="red" style={{ marginRight: '5px'}} /> : null}
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.WaitingForVerification ?   <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}} /> : null }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.WaitingForAttach ?    <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}} />: null}
                                </div>
                            </td>
                            <td className="deliverable-doc">
                                <div className="deliverable-action">
                                    <span style={{ cursor: 'pointer'}}    onClick={() => uploadClick(item,index)}>
                                          <VscAdd size={18} color={Color.Blue} />
                                          <span style={{ fontSize: '12px',marginLeft:'2px'}} >Add Files</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}  onClick={() => confirmAddUrl(item,index)}>
                                          <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                          <span style={{ fontSize: '12px',marginLeft:'2px'}} >Add URL</span>
                                    </span>
                                    { item.projectDeliverableItemFiles.length > 0 ?
                                       <>
                                        { !appState.isDownloading[index] ? 
                                            <span style={{ cursor: 'pointer'}}   onClick={() => downloadZip(item,index)}>
                                                <VscCloudDownload size={18} color={Color.Blue} cursor="pointer"/>
                                                <span style={{ fontSize: '12px',marginLeft:'2px'}} >Download All</span>
                                            </span>
                                            :<span className="spinner-border spinner-border-sm" style={{color: Color.Blue}}  role="status" aria-hidden="true"></span>
                                        }
                                        </>:null
                                    }
                                </div>
                                <AiOutlineFolder size={25} style={{ paddingRight:'5px'}} /><span style={{ fontWeight:'bold'}} >{ item.deliverableItemName } ({item.projectDeliverableItemFiles.length})</span>
                                { appState.arrowDown[index] ?
                                    <>
                                    { !appState.isUploading[index] ?
                                    <>
                                        <table key={uniqid()} className="file-table" style={{ width: '95%'}} >
                                            <tr>
                                                <td colSpan={3} style={{textAlign: 'left',paddingLeft: '40px'}} >
                                                    <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}} >Files/Urls</div>
                                                </td>
                                            </tr>
                                            
                                            {item.projectDeliverableItemFiles && item.projectDeliverableItemFiles.map((file) => (
                                                <tr key={uniqid()}>
                                                    <td style={{ paddingLeft: '55px',width: '50px'}} >
                                                        { file.itemType === 'file' ? 
                                                            <span>
                                                                {   file.name.indexOf('.pdf') > -1 ? 
                                                                    <AiOutlineFilePdf size={18} color={'red'}/> : 
                                                                    file.name.indexOf('.xls') > -1 ||  file.name.indexOf('.xlsx') > -1 ? 
                                                                    <AiOutlineFileExcel size={18} color={'green'}/> :
                                                                    file.name.indexOf('.jpg') > -1 ||  file.name.indexOf('.jpeg') > -1 ||  file.name.indexOf('.png') > -1 ? 
                                                                    <AiOutlineFileImage size={18} color={Color.DarkBlue}/> :
                                                                    file.name.indexOf('.doc') > -1 ||  file.name.indexOf('.docx') > -1 ? 
                                                                    <AiOutlineFileWord size={18} color={Color.DarkBlue} /> :
                                                                    <AiOutlineFile size={18}/> 
                                                                }
                                                            </span> 
                                                            : 
                                                            <span style={{ cursor:'pointer'}}   onClick={() => redirectUrl(file.path)}><VscLink color={Color.Blue} size={18}/></span> 
                                                        }
                                                    </td>
                                                    <td style={{textAlign: 'left'}} >
                                                        { file.itemType === 'file' ? 
                                                            <span style={{marginRight: '10px'}} >{file.name}</span>
                                                            :
                                                            <span className="font-blue" style={{ cursor:'pointer'}}   onClick={() => redirectUrl(file.path)}>{file.name}</span>
                                                        }
                                                    </td>
                                                    <td style={{ minWidth:'80px',textAlign:'right'}} >
                                                        { file.itemType === 'file' ?
                                                        <>
                                                            <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" onClick={() => download(file)}/>
                                                            <RiDeleteBin6Line cursor="pointer" size={20} className="deliverable-delete" onClick={() => confirmDeleteFile(file.projectDeliverableItemFileId,index)} />
                                                        </>:
                                                        <>
                                                            <RiDeleteBin6Line cursor="pointer" size={20} className="deliverable-delete" onClick={() => confirmDeleteUrl(file.projectDeliverableItemFileId,index)} />
                                                        </>
                                                        }
                                                       
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                            
                                            
                                        </table>
                                        </>:<LoadingMini text="Uploading..."/>
                                    }
                                    </>:null
                                }
                            </td>
                            <td style={{textAlign: 'left'}} >
                                <span className="deliverable-desc" style={{ paddingTop: '32px'}} >{ item.recomendation }</span>
                            </td>
                            <td className="deliverable-doc">
                                <div className="deliverable-action" style={{ float: 'right' }} >
                                { !item.focalPointComment ?
                                    <span style={{ cursor: 'pointer' }}  onClick={() => commentShow(item,index)}>
                                        <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => commentShow(item,index)} />
                                        <span style={{ fontSize: '12px', marginLeft: '2px' }} >Comment</span>
                                    </span>
                                    :
                                    <span style={{ cursor: 'pointer' }}  onClick={() => commentShow(item,index)}>
                                         <AiOutlineEdit size={18} color={'#009FDA'} cursor="pointer" onClick={() => commentShow(item,index)} />
                                        <span style={{ fontSize: '12px', marginLeft: '2px' }} >Comment</span>
                                    </span>
                                }
                                </div>
                                <span className='deliverable-desc'  style={{ paddingTop: '0',fontSize:'13px'}} >{item.focalPointComment}</span>
                            </td>
                            <td className="deliverable-doc">
                                <div className="deliverable-action" style={{ float: 'right' }} >
                                        
                                </div>
                                <span className='deliverable-desc' style={{ wordBreak: 'break-word',paddingTop: '32px',fontSize:'13px'}} >{item.gatekeeperFeedback  }</span>
  
                            </td>
                            <td>
                                <span className="deliverable-desc" style={{ fontSize:'13px',fontWeight: 'bold',paddingTop: '32px'}} >{ item.deliverableTypeName}</span>
                            </td>
                       
                            <td>
                                <div className='deliverable-user'>
                                  <div >{item.verifyBy }</div>
                                  <div>{ DateHelper.getDate(item.verifyDateTime)}</div>
                                </div>
                            </td>
                        </tr>
                    </>
                    ))

                    }
                    </tbody>:<Loading text="Loading..."/>
                }
            
            </table>
        </div>

   
                            
     
        <input id="input-file" multiple type="file" style={{width: '0px'}}  onChange={handleFilesUpload}/>
            
        <Modal show={appState.isCommentShow} onHide={() => commentClose()} size="lg" style={{top:'20%'}} >
            <Modal.Header className="bg-dark-blue">
                <Modal.Title style={{ fontSize: '15px'}} >Focal Point Comment</Modal.Title>
                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => commentClose()}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}} >
                <div>
                    <textarea id="focalpoint-comment" defaultValue={appState.projectDeliverableDocument?.focalPointComment} className="form-control"></textarea>
                </div>
                <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => upDateComment()} >
                  <span style={{ marginLeft: '5px'}}  >Save</span>
                </button>
                <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => commentClose()} >
                    <span style={{ marginLeft: '5px'}}  >Cancel</span>
                </button>
            </Modal.Body>
        </Modal>

        <Modal show={appState.isDeleteUrl} onHide={() => onDeleteClose()} size="sm"  style={{top:'20%'}} >
            <Modal.Header className="bg-dark-blue">
                <Modal.Title style={{ fontSize: '15px'}} >Confirm Message</Modal.Title>
                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => onDeleteClose()}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}} >
                <div>
                    <RiDeleteBin6Line size={25} color={'red'} />
                    <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}} > Would you like to to delete?</span>
                </div>
              
                <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => deleteUrl()} >
                    <span style={{ marginLeft: '5px'}}  >Yes</span>
                </button>
                <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => onDeleteClose()} >
                    <span style={{ marginLeft: '5px'}}  >No</span>
                </button>
          </Modal.Body>
                                 
        </Modal>

        <Modal show={appState.isDeleteFile} onHide={() => onDeleteClose()} size="sm"  style={{top:'20%'}} >
            <Modal.Header className="bg-dark-blue" >
                <Modal.Title style={{ fontSize: '15px'}} >Confirm Message</Modal.Title>
                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => onDeleteClose()}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}} >
                <div>
                    <RiDeleteBin6Line size={25} color={'red'} />
                    <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}} > Would you like to to delete?</span>
                </div>
             
                <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => deleteFile()} >
                    <span style={{ marginLeft: '5px'}}  >Yes</span>
                </button>
                <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => onDeleteClose()} >
                    <span style={{ marginLeft: '5px'}}  >No</span>
                </button>
            </Modal.Body>
        </Modal>

        
        <Modal show={appState.isAddUrl} onHide={() => onAddUrlClose()}  style={{top:'20%'}} >
            <Modal.Header className="bg-dark-blue">
                <Modal.Title style={{ fontSize: '15px'}} >Add URL</Modal.Title>
                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => onAddUrlClose()}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}} >
                <div>
                    <label  className="form-label col-label font-blue" style={{ float:'left'}} >URL Name<span className='require'>*</span></label>
                    <input id="url-name" type="text" className="form-control form-control-sm"/>
                </div>
                <div>
                    <label  className="form-label col-label font-blue" style={{ float:'left'}} >URL Path<span className='require'>*</span></label>
                    <input id="url-path" type="text" className="form-control form-control-sm"/>
                </div>
                <div style={{marginTop:'15px'}} >
                <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => addUrl()} >
                    <span style={{ marginLeft: '5px'}}  >Save</span>
                </button>
                <button type="button" className="btn bg-grey" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => onAddUrlClose()} >
                    <span style={{ marginLeft: '5px'}}  >Cancel</span>
                </button>
               </div>
          </Modal.Body>
        </Modal>

        <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}} >
            <Modal.Header className="bg-dark-blue">
                <Modal.Title style={{ fontSize: '15px'}} >Error Message</Modal.Title>
                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setErrors([])}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}} >
                <div><RiErrorWarningLine color="red" size={25}/></div>
                <div style={{ fontSize: '14px'}} >
                {   errors.map((msg) =>(
                            <div style={{marginLeft:'10px',color:'red'}} >{msg}</div>
                        ))
                    }
                    
                </div>
                <div style={{marginTop:'15px'}} >
               
                <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}   onClick={() => setErrors([])} >
                    <span style={{ marginLeft: '5px'}}  >Close</span>
                </button>
               </div>
          </Modal.Body>
        </Modal>

             
        </div>
    
    );
  
}

export default FocalPointDocument