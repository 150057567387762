import React, { useEffect } from 'react';


const AccessDenied = () => {
 
 
  useEffect(() => {
     
  },[])

  
  return (
    <div>
        Permission access denied, please contact your administrator.
    </div>
  );
}

export default AccessDenied;