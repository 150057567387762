import axios from 'axios'
//import qs from 'qs'
import { PageDataResponse,DataArrayResponse,PageResponse } from '../models/PageResponse'
import { DeliverableDocument, DeliverableDocumentIndex ,DeleteData } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL
const version = 1

export default class DeliverableDocumentService
{
    protected headers = {}
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }

    async getByTemplateId(templateId: number){
        let options = {
                headers: this.headers,
                params: {
                    id: templateId,
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<DeliverableDocument>>(`${apiUrl}/v${version}/deliverabledocuments/template`, options )
        
        return data;
    }
    async Create(deliverableTemplate: DeliverableDocument){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let options = {
            headers: this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v${version}/deliverableDocuments/call`,JSON.stringify(deliverableTemplate),options )
        return data;
    }

    async Update(deliverableTemplate: DeliverableDocument){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v${version}/deliverableDocuments/call`,JSON.stringify(deliverableTemplate),options )
        return data;
    }

    async UpdateIndex(deliverabledocuments: DeliverableDocumentIndex[]){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v${version}/deliverabledocuments/index`,JSON.stringify(deliverabledocuments),options )
        return data;
    }

    async Delete(id: number,updateBy: string){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v${version}/deliverableDocuments/delete`,JSON.stringify(deleteData),options )
        return data;
    }

}