export default class NumHelper {
    
    static WithComma(value,digit=0) {
       
        if(value == "" || value == null) {
            return ""
        }
        if(!this.IsNumber(value)){
            return ""
        }
      
        let number = parseFloat(value.toString().replace(/,/g,"")).toFixed(digit)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    static ToInt(value) {
        if(!value) return 0
        return parseInt(value.toString().replace(/,/g,""))
    }

    static ToFloat(value) {
        if(!value) return 0
        return parseFloat(value.toString().replace(/,/g,""))
    }

    static ToFloat2(value) {
        if(value == ""){
            return -1
        }
        if(value == 0) 
        {
            return 0
        }

        return parseFloat(value.toString().replace(/,/g,""))
    }

    static Decimal(value,digit = 2) {
        if(!value) return 0
        return value.toFixed(digit)
    }

    static IsNumber(value){
        if(!value) return false
        return isNaN(value.toString().replace(/,/g,'')) ? false : true
    }

    /*
    static SplitColumn(arr, size){
        var index = 0;
        var arrayLength = arr.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += size) {
            var chunk = arr.slice(index, index + size);
            tempArray.push(chunk);
        }

        return tempArray;
    }
    */
}