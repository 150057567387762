import React,{useState, useEffect } from 'react';
import StatusChartPdf  from './StatusChartPdf'
import { useApiAuth } from '../../hooks/useApiAuth';
import { Phase ,ProjectPhase,ProjectPhaseRole,ProjectProgress ,AuthenUser,RoleProjectLevel} from '../../models'
import { FaCircle } from "react-icons/fa";
import { ProjectLevel } from '../../enum'
import uniqid from 'uniqid';
import { Color,SystemLevel } from '../../enum'
import Loading from '../Loading';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper, NumHelper}  from '../../helpers'

interface AppState {
    phaseId:number
    phaseNumber:number
    phase:Phase
    projectPhase:ProjectPhase
    projectProgresses:ProjectProgress[]
    tableProgresses:ProjectProgress[]
    projectManagers:string[]
    secretaries:string[]
    focalpoints:string[]
    progressDate:Date| null
    trackBudget:number
    trackProgress:number
    spi:number
    bpi:number
}

interface Props{
    projectId:number
    projectModeId:number
    phaseId: number 
    phases: Phase[]
    projectPhases:ProjectPhase[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectProgresses:ProjectProgress[]
    user: AuthenUser
    isTeamMember: boolean
    projectRole:RoleProjectLevel | null,
}

const StatusViewPdf = (props:Props) => {
    const { accessToken } = useApiAuth()
    const [appState,setAppState] = useState<AppState>()
    const [isLoading,setIsloading] = useState(true)
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const isSuperAdmin = props.user.roleSystemLevelId == SystemLevel.SuperAdmin ? true : false
    
    useEffect(() => {
        if(accessToken){
                let phase:Phase| undefined = props.phases.find(x => x.phaseId == props.phaseId)
                if(phase){
                    onPhaseChange(phase)
                }
        }
        
    },[accessToken])

    const onPhaseChange = async (item:Phase) => {
        
        setIsloading(true)
        let projectPhase = props.projectPhases.find(x => x.phaseId === item.phaseId)
        let projectProgresses = props.projectProgresses.filter(x => x.projectPhaseId == projectPhase?.projectPhaseId)
        let tableProgresses:ProjectProgress[] = []

        if(projectProgresses ){
            let tempTableProgress = projectProgresses.filter( x => x.activity && x.areaOfConcern)
            if( tempTableProgress.length <= 1){
                tableProgresses = Object.assign([],tempTableProgress)
            }
            else{
                tableProgresses = tempTableProgress.sort((a,b) => new Date(b.progressDate).getTime() - new Date(a.progressDate).getTime() )
            }
           
        }
        if(projectPhase){
           
            let state:AppState = {
                projectPhase:projectPhase,
                projectProgresses:projectProgresses,
                tableProgresses:tableProgresses,
                phaseId: item.phaseId,
                phaseNumber:item.phaseNumber,
                phase:item,
                projectManagers:[],
                focalpoints:[],
                secretaries:[],
                progressDate:null,
                trackBudget:1,
                trackProgress:1,
                spi: 0,
                bpi: 0
            }

            let projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => {return p.name })
            let focalpoints = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.FocalPoint && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => {return p.name })
            let secretaries = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.Secretary && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => {return p.name })
            state.trackBudget = projectPhase.trackBudget
            state.trackProgress = projectPhase.trackProgress

            state.projectManagers = projectManagers ? projectManagers : []
            state.focalpoints = focalpoints ? focalpoints: []
            state.secretaries = secretaries ? secretaries: []

            if(item.phaseNumber === 1 || item.phaseNumber === 5){
                state.trackBudget = 0
                state.trackProgress = 0
            }
            
           
            state.spi = 0;
            state.bpi = 0;
            if(projectProgresses && projectProgresses.length > 0){
                let projectProgress = projectProgresses[projectProgresses.length-1]
               if(projectProgress.actualProgress >= 0 &&  projectProgress.planProgress > 0){
                   state.spi = Math.round((projectProgress.actualProgress / projectProgress.planProgress)*100)/100
               }
                if(projectProgress.costCumulative >= 0 &&  projectProgress.costProjection > 0){
                    state.bpi = Math.round((projectProgress.costCumulative / projectProgress.costProjection) * 100) /100
                }
            }


            setAppState(state)
           
        }
       
        setIsloading(false)
    }

    return (
   <>
            
    { !isLoading && appState ?
    <div className='inform-section' style={{minHeight:'270mm'}} >
        { appState.phaseNumber === 1 ?
            <div className='project-label' style={{ marginBottom: '15px'}} >
                <span>Project Status Update</span>
            </div>
            :null
        }  
      
        <div className='row' style={{ marginBottom : '0'}} >
            <div className='col-md-4'>
                <button key={uniqid()}  className='tab-btn' style={{ width:'250px', color: 'white',backgroundColor: '#00338D'}} >
                    <span style={{ fontSize: '16px'}} >{appState.phase.phaseName}</span>
                </button>
            </div>
        </div>
                    {appState.projectPhase.waivePhase === 1 ?  

                        <div id={'status-info-' + appState.phase.phaseNumber } >
                            <div className="col-sm-12" style={{ padding: '10px' }} >
                                <div id="document-user-info">
                                    <div className='row'>
                                        <div className='col-md-12 inform-top'>
                                            <div className='topic-head'>Waive Resaon</div>
                                            <div style={{ wordBreak:'break-all'}} >
                                                {appState?.projectPhase.waivePhaseReason} 
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div id={'status-info-' + appState.phase.phaseNumber }  >
                                <div className="col-sm-12" style={{ padding: '10px' }} >


                                    <div id="document-user-info">
                                        <div className='row'>
                                            {/* <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>Document Pattern</div>
                                                <div>{appState?.projectPhase.deliverableTemplateName} </div>
                                            </div> */}
                                            <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>Focal Point</div>
                                                <div>{appState?.focalpoints.join(', ')}  </div>
                                            </div>
                                            <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>Project Manager</div>
                                                <div>{appState?.projectManagers.join(', ')}  </div>
                                            </div>
                                            <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>EDR Focal Point</div>
                                                <div>{appState?.secretaries.join(', ')}  </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                { (appState.phaseNumber > 1 && appState.phaseNumber < 5) && appState.projectProgresses.length > 0 ?
                                    <>
                                    <div id={'summary-label-' + appState.phaseNumber} className='summary-label' style={{ marginBottom: '15px' }} ></div>
                                    
                                    { appState.trackBudget && appState.trackProgress ?
                                        <div id="status-info-box-1" style={{ display:'flex',justifyContent:'space-between'}} >
                                            
                                           
                                               
                                                { appState.spi != 0 ?
                                                    <div className="info-box-3 bg-dark-blue" style={{ marginBottom: '5px',height:'45px' ,width: '300px',margin:'auto'}} >
                                                        <div className="icon"></div>
                                                        <div style={{ textAlign: 'center' }} >
                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.spi < 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='spi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >SPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.spi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                          

                                                        </div>
                                                    </div>:null
                                                }
                                                
                                            

                                                { appState.bpi != 0 ?
                                                    <div className="info-box-3 bg-blue" style={{ marginBottom: '5px',height:'45px' ,width: '300px',margin:'auto'}} >
                                                        <div className="icon">

                                                        </div>
                                                        <div style={{ textAlign: 'center' }} >

                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.bpi > 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='bpi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >BPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.bpi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                            
                                                        </div>
                                                    </div>:null
                                                }

                                                
                                           
                                        </div>:null
                                    }

                                    { appState.trackBudget && !appState.trackProgress && appState.bpi != 0?
                                        <div id="status-info-box-2" style={{ display:'flex',justifyContent:'center'}} >
                                           
                                               
                                                    <div className="info-box-3 bg-blue" style={{ marginBottom: '5px',height:'45px' ,width:'300px'}} >
                                                        <div className="icon">

                                                        </div>
                                                        <div style={{ textAlign: 'center' }} >

                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.bpi > 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='bpi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >BPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.bpi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                           
                                        </div>:null
                                    }

                                    { !appState.trackBudget && appState.trackProgress && appState.spi != 0?
                                        <div id="status-info-box-3"  style={{ display:'flex',justifyContent:'center'}} >
                                          
                                            <div className='row' style={{ marginBottom: '15px' }} >
                                               
                                                <div className="col-md-4">
                                               
                                                    <div className="info-box-3 bg-dark-blue" style={{ marginBottom: '5px',height:'45px',width:'300px' }} >
                                                        <div className="icon"></div>
                                                        <div style={{ textAlign: 'center' }} >
                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.spi < 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='spi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >SPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.spi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                        

                                                        </div>
                                                    </div>
                                    
                                                </div>
                                               
                                            </div>
                                        </div>:null
                                    }

                                        
                                        <div key={uniqid()} className="status-chart">
                                            <StatusChartPdf key={uniqid()} 
                                                planChartId={ 'plan-actual-chart-' + props.phaseId }
                                                costChartId={ 'cost-chart-' + props.phaseId }
                                                phaseId={appState.phaseNumber} 
                                                phaseNumber={appState.phaseNumber}
                                                progresses={appState.projectProgresses.sort((a,b) => new Date(a.progressDate).getTime() - new Date(b.progressDate).getTime() )} 
                                                trackBudget={appState.trackBudget} 
                                                trackProgress={appState.trackProgress} 
                                                isTeamMember={props.isTeamMember}
                                                isSuperAdmin={isSuperAdmin}
                                                spi={appState.spi}
                                                bpi={appState.bpi}
                                                user={user}
                                                projectRole={props.projectRole}
                                            />
                                        </div>
                                    </> : null
                                }

                            </div> 
                        </>
                        }
        
        { appState.projectPhase.waivePhase === 0 && appState.tableProgresses.length > 0 ?
            <>
                <div id="status-history" className='row' style={{ marginTop: '80px'}} >
                    <div className='col-md-12'>
                        <div style={{fontWeight: 'bold',marginBottom:'15px'}} >
                            <span>Monthly Activity Update</span>
                        </div>
                        <table id="table-progress" className="table portfolio-table table-hover">
                            <thead>
                                <tr>
                                    <th className='td-header' style={{ width: '110px'}} >
                                        <span>Month</span>
                                    </th>
                                    <th className='td-header' >
                                      <span>Activity</span>
                                     </th>
                                                        <th className='td-header'>
                                                            <span>Area Of Concern</span>
                                                        </th>

                                                        
                                                    </tr>
                            </thead>
                             <tbody>
                                                    { appState.tableProgresses.map(progress => 
                                                        <tr key={uniqid()}>
                                                            <td>
                                                                <span>{ DateHelper.getMonthYear(progress.progressDate) }</span>
                                                            </td>
                                                            
                                                            <td style={{ wordBreak: 'break-all',textAlign:'left'}} >
                                                                { progress.activity }
                                                            </td>
                                                            <td style={{ wordBreak: 'break-all',textAlign:'left'}} >
                                                                { progress.areaOfConcern }
                                                            </td>
                                                            
                                                        </tr>
                                                    )}
                        
                            </tbody>
                        </table>
                    </div>
                </div>
                            
            </>:null
        }
    </div>:<Loading text="Loading..."/>
    }   
    
    </>
    );

}

export default StatusViewPdf
