import axios from 'axios'
import { PageDataResponse, DataArrayResponse,DataArrayResponse2,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { Project,GatekeeperUpdate,CurrentUpdate,WaiveUpdate,DeleteData,
    PhaseUpdate,Parameter,Dashboard,ProjectLocation,ProjectSummary,ProjectDashboardSummary,ProjectNameItem
} from '../models'
import { config } from '../config'
import { CookieHelper, DateHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ProjectService
{
    protected headers = {}
    protected token = ""
    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        
    }
    async getAll(parameter:Parameter ){
        if(!this.token) return null

        let params = Object.assign({},parameter)
        let options = {
                headers:this.headers,
                params: params
            }
        
        const { data }  = await axios.get<PageDataResponse<Dashboard>>(`${apiUrl}/v1/projects/call`, options )
        
        return data;
    }

    async search(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<ProjectNameItem>>(`${apiUrl}/v1/projects/search`,options )
        return data;
    }

    async getSummaryProject(){
        if(!this.token) return null

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse2<ProjectSummary>>(`${apiUrl}/v1/projects/summary/project`, options )
        
        return data;
    }

    async getSummaryProjectCategory(projectModeId: number){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    projectModeId: projectModeId
                }
            }
        
        const { data }  = await axios.get<DataObjectResponse<ProjectDashboardSummary>>(`${apiUrl}/v1/projects/summary/projectcategory`, options )
        
        return data;
    }

    async checkProjectExist(projectName:string){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    projectName: encodeURI(projectName)
                }
            }
        
        const { data }  = await axios.get<DataObjectResponse<boolean>>(`${apiUrl}/v1/projects/exist`, options )
        
        return data;
    }

    async getSummaryPhase(){
        if(!this.token) return null

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<number[]>>(`${apiUrl}/v1/projects/summary/phase`, options )
        
        return data;
    }

    async getLocation(){
        if(!this.token) return null

        let options = {
                headers:this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<ProjectLocation>>(`${apiUrl}/v1/projects/location`, options )
        
        return data;
    }

    async getById(pid: number){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    pid: pid
                }
            }
        
        const { data }  = await axios.get<DataObjectResponse<Project>>(`${apiUrl}/v1/projects/id`, options )
        
        return data;
    }

    async exportExcel(parameter:Parameter )
    {
        let params:string[] = []
        for (const [key, value] of Object.entries(parameter)) {
            if(value != null){
                params.push(key + "=" + value)
            }
           
        }

        var url = `${apiUrl}/v1/exportexcel/dashboard?` + params.join('&')
        //console.log('https://localhost:3333' + url)
        var request = new XMLHttpRequest();
        request.onload = function(e) {
            if (this.status === 200) {
                if(this.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                    var blob = this.response;
                    
                        var downloadLink = window.document.createElement('a');
                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader? contentTypeHeader : "" }));
                        downloadLink.download = "project_portfolio_" + DateHelper.getDateFormat2() + ".xlsx";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    
                }
                
            }
            else{
                //console.log("File not found.")
              
            }
           
        };
        request.open('GET', url);
        request.setRequestHeader("Authorization", `Bearer ${this.token}`);
        request.responseType = 'blob';
        request.send();
    }

    async create(project: Project){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/projects/call`,JSON.stringify(project),options )
        return data;
    }

    async update(project: Project){
        if(!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
       
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projects/call`,JSON.stringify(project),options )
        return data;
    }

    async updateAll(project: Project){
        if(!this.token) return null
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
       
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projects/update/all`,JSON.stringify(project),options )
        return data;
    }

    async updateGatekeeper(project: GatekeeperUpdate){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v1/projects/gatekeepers`,JSON.stringify(project),options )
        return data;
    }

    async updateCurrent(currentData: CurrentUpdate){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projects/current`,JSON.stringify(currentData),options )
        return data;
    }

    async updatePhase(phaseData: PhaseUpdate){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projects/phase`,JSON.stringify(phaseData),options )
        return data;
    }

    async updateWaive(waiveData: WaiveUpdate){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projects/waive`,JSON.stringify(waiveData),options )
        return data;
    }

    async delete(id: number,updateBy: string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v1/projects/delete`,JSON.stringify(deleteData),options )
        return data;
    }
}