import React, { useState, useEffect } from 'react';
import { AiOutlineEdit,AiOutlineCheckCircle } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal'
import { EmailTemplateService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { EmailTemplate } from '../../models';
import { Color } from '../../enum';
import uniqid from 'uniqid';
import Loading from '../Loading';

interface AppState {
    emailTemplate:EmailTemplate|null
    emailTemplates:EmailTemplate[]
    isUpdate:boolean
    isSuccess:boolean
    isLoading:boolean
}

const AdminEmailTemplate = () => {
    const { accessToken } = useApiAuth()
    const [appState,setAppState] = useState<AppState>({
        emailTemplate:null,
        emailTemplates:[],
        isUpdate:false,
        isSuccess:false,
        isLoading:true
    })

    useEffect(() => {
        if(accessToken){
            new EmailTemplateService(accessToken).getAll().then((result) => {
                let state = Object.assign({},appState)
                //console.log(result)
                
                state.emailTemplates = result.data
                state.isLoading = false
                setAppState(state)
            })
        }
    },[accessToken])

   
    const openUpdate = (item) => {
        let state = Object.assign({},appState)
        state.emailTemplate = item
        state.isUpdate = true
        setAppState(state)
    }

    const updateClose = () => {
        let state = Object.assign({},appState)
        state.isUpdate = false
        setAppState(state)
    }

    const successClose = () => {
        let state = Object.assign({},appState)
        state.isSuccess = false
        setAppState(state)
    }

    const updateEmailContent = async () => {
        if(appState.emailTemplate){
            let emailTemplate = Object.assign({},appState.emailTemplate)
            let emailContent = document.getElementById('email-content') as HTMLTextAreaElement
            if(emailContent){
                emailTemplate.emailContent = emailContent.value
            }
            let result = await new EmailTemplateService(accessToken).update(emailTemplate)
            if(result && result.success){
                let state = Object.assign({},appState)
                let index = state.emailTemplates.findIndex( x => x.emailTemplateId == emailTemplate.emailTemplateId)
                if(index > -1) {
                    state.emailTemplates[index].emailContent = emailTemplate.emailContent
                    setAppState(state)
                    updateClose()
                }
            }
            else{
                alert(result?.message)
                updateClose()
            }
        }
        
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}} >
                <div className="body">
                
                    <div className='project-block-none' style={{ paddingTop: '5px'}} >
                        <div className="row" style={{ width: '80%'}} >
                            <div className="col-sm-6">
                                <span className='inform-create-header'>Email Template</span>
                            </div>
                        </div>
                    { !appState.isLoading ?
                        <div className="table-responsive table-responsive-sm">
                            <table className="table table-sm table-bordered table-hover" >
                                <thead>
                                    <tr>
                                        <th>
                                            <span>Email Type</span>
                                        </th>
                                        <th>
                                            <span>Email Template</span>
                                        </th>
                                        <th>
                                            <span>Edit</span>
                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                   
                            {   appState.emailTemplates.map((item) =>(
                                    <tr key={uniqid()}>
                                        <td>
                                            { item.emailTypeId == 1 ? <span>Status Change</span>:
                                                item.emailTypeId == 2 ? <span>Attached File</span>:
                                                item.emailTypeId == 3 ? <span>Stage Change</span>:
                                                item.emailTypeId == 4 ? <span>Phase Change</span>: null
                                            }
                                        </td>
                                        <td style={{ textAlign:'left',paddingLeft:'20px'}} >
                                            <span>{item.emailContent}</span>
                                        </td>
                                        <td>
                                            <AiOutlineEdit size={20} color={Color.Orange} cursor="pointer" onClick={() => openUpdate(item)}/>
                                        </td>
                                    </tr>
                                ))
                            }
                    
                        </tbody>
                    </table>
                </div>
                    :<Loading text="Loading.."/>
            }

                    </div>
         
                </div>
            </div>
            

            <Modal show={appState.isUpdate} onHide={() => updateClose()} size="lg" style={{top:'20%'}} >
            <Modal.Header className="bg-dark-blue" closeButton>
                <Modal.Title style={{ fontSize: '15px'}} >
                    { appState.emailTemplate?.emailTemplateName } Email Template
                    <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => updateClose()}>X</span>
                </Modal.Title>
               
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}} >
                <div>
                    <textarea id="email-content" defaultValue={appState.emailTemplate?.emailContent} className="form-control" style={{ height: '200px'}} ></textarea>
                </div>
                <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => updateEmailContent()} >
                  <span style={{ marginLeft: '5px'}}  >Save</span>
                </button>
                <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => updateClose()} >
                    <span style={{ marginLeft: '5px'}}  >Cancel</span>
                </button>
            </Modal.Body>
            </Modal>

            <Modal show={appState.isSuccess} onHide={() => successClose()} size="sm">
            <Modal.Body style={{ textAlign: 'center',padding: 0}} >
                <div className="alert alert-success" role="alert" style={{marginBottom: 0}} >
                    <AiOutlineCheckCircle color={Color.Green} size={25}/>
                    <span style={{marginLeft:'10px',color:Color.Green}} >Success</span>
                </div>
            </Modal.Body>
        </Modal>
        </div>

        
    );
  
}

export default AdminEmailTemplate