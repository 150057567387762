import React, { useEffect,useState,useMemo } from 'react';
import { Routes, Route  } from "react-router-dom"
import OverAll from '../components/OverAll';
import OverAllPdf from '../components/OverallPdf';
import Dashboard from '../components/Dashboard';
import ProjectView from '../components/Project/ProjectView';
import ProjectCreate from '../components/Project/ProjectCreate';
import PrepStateGate from '../components/Project/PrepStageGate';
import Deliverable from '../components/Project/Deliverable'
import PrintPdf from '../components/Project/PrintPdf';
import GateKeeperWorkList from '../components/Project/GateKeeperWorkList'
import Admin from '../components/Admin/Admin'
import AdminUser from '../components/Admin/AdminUser'
import AdminUserUpdate from '../components/Admin/AdminUserUpdate'
import AdminRole from '../components/Admin/AdminRole'
import AdminDeliverableTemplate from '../components/Admin/AdminDeliverableTemplate';
import AdminProjectType from '../components/Admin/AdminProjectType'
import AdminProjectDevelopment from '../components/Admin/AdminProjectDevelopment'
import AdminAssetType from '../components/Admin/AdminAssetType'
import AdminAsset from '../components/Admin/AdminAsset'
import AdminInvestmentType from '../components/Admin/AdminInvestmentType'
import AdminProjectCategory from '../components/Admin/AdminProjectCategory'
import AdminEmail from '../components/Admin/AdminEmail'
import AdminHistory from '../components/Admin/AdminHistory'
import AdminCountry from '../components/Admin/AdminCountry'
import AdminProjectMode from '../components/Admin/AdminProjectMode'
import AdminDeliverableItem from '../components/Admin/AdminDeliverableItem'
import AdminAssuranceActivity from '../components/Admin/AdminAssuranceActivity'
import AccessDenied from '../AccessDenied'
import Logout from '../components/Logout'
import { NavMenu } from '../components/NavMenu';
import { BrowserRouter } from "react-router-dom";
import { UserService } from '../services'
import { AuthenUser } from '../models'
import { useApiAuth } from '../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";

const Router = () => {
  const { account,accessToken } = useApiAuth();
  const userStore = secureLocalStorage.getItem('user')
  const [user,setUser] = useState<AuthenUser>((userStore) ? JSON.parse(userStore.toString()): null)
 const [isLoading,setIsLoding] = useState(true)

  useMemo(() => {
    process.env.NODE_ENV === 'development' && console.log(user)
    process.env.NODE_ENV === 'development' && console.log(accessToken)
    
    const controller = new AbortController();
    const signal = controller.signal;

    if(accessToken){
      const userService = new UserService(accessToken)
      const accountName = account ? account.username : ""
      
      //if(!user){
        userService.getByUsername(accountName,signal).then((result) => {
            if(result && result.success){
              if( result.data)
              {
                secureLocalStorage.setItem('user',JSON.stringify(result.data))
                setUser(result.data)
                setIsLoding(false)
              }
              else{
                setIsLoding(false)
              }
            }
        })
      //}
      //else{
       // setIsLoding(false)
    //  } 
    }


    return () => {
      //console.log('Child unmounted');
      controller.abort();
  };
}, [accessToken])



    return (
      <BrowserRouter>
      { !isLoading ?
        <>
          <NavMenu/>
          { user && user.authorization === 1 ?
            <Routes>
              <Route path='/' element={<OverAll/>}/>
              <Route path='/main-dashboard' element={<OverAll/>}/>
              <Route path='/overallpdf' element={<OverAllPdf/>}/>
              <Route path='/dashboard' element={<Dashboard/>}/>
              <Route path='/project-create' element={<ProjectCreate/>}/>
              <Route path='/project-view' element={<ProjectView/>}/>
              <Route path='/prep-stage-gate' element={<PrepStateGate/>}/>
              <Route path='/deliverable-document' element={<Deliverable/>}/>
              <Route path='/gateKeeper-workList' element={<GateKeeperWorkList/>}/>
              <Route path='/printpdf' element={<PrintPdf/>}/>
              <Route path='/admin' element={<Admin/>}/>
              <Route path='/admin-user' element={<AdminUser/>}/>
              <Route path='/admin-user-update' element={<AdminUserUpdate/>}/>
              <Route path='/admin-role' element={<AdminRole/>}/>
              <Route path='/admin-deliverable' element={<AdminDeliverableTemplate/>}/>
              <Route path='/admin-deliverable-item' element={<AdminDeliverableItem/>}/>
              <Route path='/admin-assurance-activity' element={<AdminAssuranceActivity/>}/>
              <Route path='/admin-project-type' element={<AdminProjectType/>}/>
              <Route path='/admin-project-development' element={<AdminProjectDevelopment/>}/>
              <Route path='/admin-asset-type' element={<AdminAssetType/>}/>
              <Route path='/admin-asset' element={<AdminAsset/>}/>
              <Route path='/admin-investment-type' element={<AdminInvestmentType/>}/>
              <Route path='/admin-project-category' element={<AdminProjectCategory/>}/>
              <Route path='/admin-email' element={<AdminEmail/>}/>
              <Route path='/admin-history' element={<AdminHistory/>}/>
              <Route path='/admin-country' element={<AdminCountry/>}/>
              <Route path='/admin-project-mode' element={<AdminProjectMode/>}/>
              <Route path='/logout' element={<Logout/>}/>
              <Route path='/access-denied' element={<AccessDenied/>}/>
            </Routes>:
            <div style={{marginLeft:'25px'}} >Permission access denied, please contact your administrator.</div>
          }
        </>: null
      }
      </BrowserRouter>
    );
  
}

export default Router