enum StatusDeliverable  {
    Pass = 1,
    Recycle = 2,
    Hold = 3,
    Stop = 4,
    InProgress = 5,
    WaitingForStart = 6,
    Waive= 7,
    WaivePhase = 8,
}

export default StatusDeliverable