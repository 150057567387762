import axios from 'axios'
import {  DataArrayResponse,PageResponse,PageDataResponse, DataArrayResponse2 } from '../models/PageResponse'
import {ProjectDeliverableDocument ,GatekeeperWorkList,UpdateData,ProjectNameItem} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL


export default class ProjectDeliverableDocumentService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }

    async getDocuments(projectPhaseId:number,stageId:number,assuranceActivityId:number ){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    projectPhaseId: projectPhaseId,
                    stageId: stageId,
                    assuranceActivityId: assuranceActivityId
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<ProjectDeliverableDocument>>(`${apiUrl}/v1/projectdeliverabledocuments/call`, options )
        
        return data;
    }
    
    async getGateKeeperWorklist(page:number,limit:number,username:string,projectId:number,statusId:number, key: string ="" ){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    page: page,
                    limit:limit,
                    username: username,
                    projectId: projectId,
                    statusId: statusId,
                    key:key ? encodeURI(key) : ""
                }
            }
        
        const { data }  = await axios.get<PageDataResponse<GatekeeperWorkList>>(`${apiUrl}/v1/projectdeliverabledocuments/gatekeeper/worklist`, options )
        
        return data;
    }

    async searchProjectName(username:string,key: string =""){
        let options = {
            headers:this.headers,
            params: {
                username: username,
                key: encodeURI(key)
            }
        }
    
        const { data }  = await axios.get<DataArrayResponse<ProjectNameItem>>(`${apiUrl}/v1/projectdeliverabledocuments/search/projectname`, options ) 
        return data;
    }

    async updateComment(projectDeliverableDocumentId: number,comment: string,updateBy:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken

        let sendData:UpdateData ={ id: projectDeliverableDocumentId,value: comment, number: 0 ,updateBy}
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projectdeliverabledocuments/comment`,JSON.stringify(sendData),options )
        return data;
    }

    async updateFeedback(projectDeliverableDocumentId: number,feedback: string,updateBy:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let sendData:UpdateData ={ id: projectDeliverableDocumentId,value: feedback, number: 0 ,updateBy}
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projectdeliverabledocuments/feedback`,JSON.stringify(sendData),options )
        return data;
    }

    async updateStatus(projectDeliverableDocumentId: number,statusId: number,updateBy:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let sendData:UpdateData ={ id: projectDeliverableDocumentId,value: "", number: statusId ,updateBy}
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<DataArrayResponse2<ProjectNameItem>>(`${apiUrl}/v1/projectdeliverabledocuments/status`,JSON.stringify(sendData),options )
        return data;
    }

}