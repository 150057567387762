import axios from 'axios'
import {DataObjectResponse,PageResponse,DataArrayResponse} from '../models/PageResponse'
import { Role, RoleName, RoleProjectLevel, RoleSystemLevel} from '../models/'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL
const version = 1

export default class RoleService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        
    }
    
    async getAll(){
            if(Object.keys(this.headers).length === 0 ) return null

            let options = {
                headers: this.headers
            }
        
            const response = await axios.get<DataObjectResponse<Role>>(`${apiUrl}/v${version}/roles/call?`, options )
            return response.data;
        
    }

    async getRoleSystemName(){
        if(Object.keys(this.headers).length === 0 ) return null

        let options = {
            headers: this.headers
        }
    
        const response = await axios.get<DataArrayResponse<RoleName>>(`${apiUrl}/v${version}/roles/system/name`, options )
        return response.data;
    
    }

    async UpdateRoleProject(roleProjectLevels: RoleProjectLevel[]){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/roles/project`,JSON.stringify(roleProjectLevels),options )
        return data;
    }

    async UpdateRoleSysytem(roleSystemLevels: RoleSystemLevel[]){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/roles/system`,JSON.stringify(roleSystemLevels),options )
        return data;
    }
}