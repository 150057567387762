import axios from 'axios'
import {  DataArrayResponse,PageResponse,PageDataResponse } from '../models/PageResponse'
import {EmailLog,EmailData, EmailReverse } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class EmailService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }

    async getAll(page: number,limit: number){
        if(!this.token) return null

        let options = {
                headers: this.headers,
                params: {
                    page: page,
                    limit: limit
                }
            }
                                                                                        
        const { data }  = await axios.get<PageDataResponse<EmailLog>>(`${apiUrl}/v1/emails/call`, options )
        
        return data;
    }

    async sendProjectCreate(projectId: number){
        if(!this.token) return null

        let emailData:EmailData = {
            projectId:projectId,
            projectName:"",
            deliverableName:"",
            projectDeliverableDocumentId:0,
            projectPhaseId:0,
            phaseId:0,
            oldStatus:"",
            newStatus:"",
            attachFiles:""
        }
        let options = {
                headers: this.headers
            }
                                                                                        
        const { data }  = await axios.post<PageDataResponse<EmailLog>>(`${apiUrl}/v1/emails/project/create`,JSON.stringify(emailData), options )
        
        return data;
    }
    
    async sendPhaseChange(projectId:number,phaseId:number, projectName:string,oldStatus:string,newStatus:string){
        if(!this.token) return null

        let emailData:EmailData = {
            projectId:projectId,
            projectName:projectName,
            projectDeliverableDocumentId:0,
            projectPhaseId:0,
            phaseId:phaseId,
            deliverableName:"",
            oldStatus:oldStatus,
            newStatus:newStatus,
            attachFiles:""
        }
        let options = {
                headers: this.headers
            }
                                                                                        
        const { data }  = await axios.post<PageDataResponse<EmailLog>>(`${apiUrl}/v1/emails/phasechange`,JSON.stringify(emailData), options )
        
        return data;
    }

    async sendStatusReverse(emailData:EmailReverse){
        if(!this.token) return null

        let options = {
                headers: this.headers
            }
                                                                                        
        const { data }  = await axios.post<PageDataResponse<EmailLog>>(`${apiUrl}/v1/emails/reverse`,JSON.stringify(emailData), options )
        
        return data;
    }
}