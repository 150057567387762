import React,{useState, useEffect } from 'react';
import StatusChart  from './StatusChart'
import StatusActivityUpdate from './StatusActivityUpdate';
import { useApiAuth } from '../../hooks/useApiAuth';
import PhaseSelection from '../ComponentTools/PhaseSelection';
import { Phase ,ProjectPhase,ProjectPhaseRole,ProjectProgress ,AuthenUser,RoleProjectLevel} from '../../models'
import { FaCircle } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import { ProjectLevel } from '../../enum'
import uniqid from 'uniqid';
import { Color,SystemLevel } from '../../enum'
import { spiImage,bpiImage } from '../../images';
import Loading from '../Loading';
import  secureLocalStorage  from  "react-secure-storage";

interface AppState {
    phaseId:number
    phaseNumber:number
    projectPhase:ProjectPhase
    projectProgresses:ProjectProgress[]
    projectManagers:string[]
    secretaries:string[]
    focalpoints:string[]
    progressDate:Date| null
    trackBudget:number
    trackProgress:number
    spi:number
    bpi:number
}

interface Props{
    projectId:number
    projectModeId:number
    phaseId: number 
    phases: Phase[]
    projectPhases:ProjectPhase[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectProgresses:ProjectProgress[]
    user: AuthenUser
    projectRole:RoleProjectLevel | null,
}

const StatusView = (props:Props) => {
    const { accessToken } = useApiAuth()
    const [appState,setAppState] = useState<AppState>()
    const [isLoading,setIsloading] = useState(true)
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    
    useEffect(() => {
        if(accessToken){
                let phase:Phase| undefined = props.phases.find(x => x.phaseId == props.phaseId)
                if(phase){
                    onPhaseChange(phase)
                }
        }
        
    },[accessToken])

    const onPhaseChange = async (item:Phase) => {
        
        setIsloading(true)
        let projectPhase = props.projectPhases.find(x => x.phaseId === item.phaseId)
        let projectProgresses = props.projectProgresses.filter(x => x.projectPhaseId == projectPhase?.projectPhaseId)
       // //console.log( props.projectProgresses)
        /* Remove because already sort when initial */
        /*
        if(projectProgresses && projectProgresses.length > 1){
            projectProgresses = projectProgresses.sort((a,b) => new Date(b.progressDate).getTime() - new Date(a.progressDate).getTime() )
        }*/
        if(projectPhase){
           
            let state:AppState = {
                projectPhase:projectPhase,
                projectProgresses:projectProgresses,
                phaseId: item.phaseId,
                phaseNumber:item.phaseNumber,
                projectManagers:[],
                focalpoints:[],
                secretaries:[],
                progressDate:null,
                trackBudget:1,
                trackProgress:1,
                spi: 0,
                bpi: 0
            }

            let projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => {return p.name })
            let focalpoints = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.FocalPoint && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => {return p.name })
            let secretaries = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.Secretary && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => {return p.name })
            state.trackBudget = projectPhase.trackBudget
            state.trackProgress = projectPhase.trackProgress

            state.projectManagers = projectManagers ? projectManagers : []
            state.focalpoints = focalpoints ? focalpoints: []
            state.secretaries = secretaries ? secretaries: []

            if(item.phaseNumber === 1 || item.phaseNumber === 5){
                state.trackBudget = 0
                state.trackProgress = 0
            }
            else{
                // if(props.user.sensitiveInformationView === 0 ){
                //     state.trackBudget = 0
                // }
                //console.log("isTem=" + props.isTeamMember)
                // if(props.isTeamMember){
                //     state.trackBudget = 1
                // }
                //console.log(state.trackBudget)
            }

           
            state.spi = 0;
            state.bpi = 0;
            if(projectProgresses && projectProgresses.length > 0){
                let projectProgress = projectProgresses[projectProgresses.length-1]
               if(projectProgress.actualProgress >= 0 &&  projectProgress.planProgress > 0){
                   state.spi = Math.round((projectProgress.actualProgress / projectProgress.planProgress)*100)/100
               }
                if(projectProgress.costCumulative >= 0 &&  projectProgress.costProjection > 0){
                    state.bpi = Math.round((projectProgress.costCumulative / projectProgress.costProjection) * 100) /100
                }
            }


            setAppState(state)
           
        }
       
        setIsloading(false)
    }

    return (
    <div className='inform-section'>
        <div className='project-label'>
            <span>Project Status Update</span>
        </div>
    { !isLoading && appState ?
    <> 
     
        <div className='row' style={{display:"none"}}  >
                <div className='col-md-2'></div>
                <div className='col-md-4 inform-top' style={{ textAlign: 'center'}} >
                    <span>Current Phase</span>
                </div>
                <div className='col-md-4 inform-top' style={{ textAlign: 'center'}} >
                    <span>Current PREP Stage</span>
                </div>
                <div className='col-md-2'></div>
         </div>
        <div className='row' style={{ display: "none" }}  >
                <div className='col-md-2 inform-bottom'></div>
                <div className='col-md-4 inform-bottom' style={{ textAlign: 'center'}} >
                    <button className="btn bg-green"  type="button" style={{ width: '200px'}} >
                        <span>{'PhaseName.Phase4'}</span>
                    </button>
                   
                </div>
                <div className='col-md-4 inform-bottom' style={{ textAlign: 'center'}} >
                    
                    <button className="btn bg-green"  type="button" style={{ width: '200px'}} >
                        <span>Value Assurance Check</span>
                    </button>
                </div>
                <div className='col-md-2 inform-bottom'></div>
        </div>
       
        <PhaseSelection phaseId={appState?.phaseId} projectModeId={props.projectModeId} isUpdate={false} phases={props.phases} onChange={onPhaseChange}/>
                    {appState.projectPhase.waivePhase === 1 ?  

                        <div className="status-info">
                            <div className="col-sm-12" style={{ padding: '10px' }} >
                                <div id="document-user-info">
                                    <div className='row'>
                                        <div className='col-md-12 inform-top'>
                                            <div className='topic-head'>Waive Resaon</div>
                                            <div style={{ wordBreak:'break-all'}} >
                                                {appState?.projectPhase.waivePhaseReason} 
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="status-info">
                                <div className="col-sm-12" style={{ padding: '10px' }} >


                                    <div id="document-user-info">
                                        <div className='row'>
                                            {/* <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>Document Pattern</div>
                                                <div>{appState?.projectPhase.deliverableTemplateName} </div>
                                            </div> */}
                                            <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>Focal Point</div>
                                                <div>{appState?.focalpoints.join(', ')}  </div>
                                            </div>
                                            <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>Project Manager</div>
                                                <div>{appState?.projectManagers.join(', ')}  </div>
                                            </div>
                                            <div className='col-md-4 inform-top'>
                                                <div className='topic-head'>EDR Focal Point</div>
                                                <div>{appState?.secretaries.join(', ')}  </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                { (appState.phaseNumber > 1 && appState.phaseNumber < 5) && appState.projectProgresses.length > 0 ?
                                    <>
                                    <div id="summary-label" className='summary-label' style={{ marginBottom: '30px', marginTop: '15px' }} ></div>
                                    
                                    { appState.trackBudget && appState.trackProgress ?
                                        <div id="status-info-box-1">
                                            
                                            <div className='row' style={{ marginBottom: '15px' }} >
                                               
                                                <div className="col-md-6">
                                                { appState.spi != 0 ?
                                                    <div className="info-box-3 bg-dark-blue spi-bpi-box">
                                                        <div className="icon"></div>
                                                        <div style={{ textAlign: 'center' }} >
                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.spi < 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='spi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >SPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.spi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                            <Tooltip id='spi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                                <img className='bpi-image' src={spiImage} />
                                                            </Tooltip>
                                                            

                                                        </div>
                                                    </div>:null
                                                }
                                                
                                                </div>

                                               
                                                <div className="col-md-6">
                                                { appState.bpi != 0 ?
                                                    <div className="info-box-3 bg-blue spi-bpi-box">
                                                        <div className="icon">

                                                        </div>
                                                        <div style={{ textAlign: 'center' }} >

                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.bpi > 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='bpi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >BPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.bpi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                            <Tooltip id='bpi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                                <img className='bpi-image' src={bpiImage} />
                                                            </Tooltip>

                                                        </div>
                                                    </div>:null
                                                }

                                                </div>
                                                
                                            </div>
                                        </div>:null
                                    }

                                    { appState.trackBudget && !appState.trackProgress && appState.bpi != 0?
                                        <div id="status-info-box-2">
                                         
                                            <div className='row' style={{ marginBottom: '15px' }} >
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                               
                                                    <div className="info-box-3 bg-blue" style={{ marginBottom: '5px',height:'45px' }} >
                                                        <div className="icon">

                                                        </div>
                                                        <div style={{ textAlign: 'center' }} >

                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.bpi > 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='bpi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >BPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.bpi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                            <Tooltip id='bpi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                                <img className='bpi-image' src={bpiImage} />
                                                            </Tooltip>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4"></div>
                                            </div>
                                        </div>:null
                                    }

                                    { !appState.trackBudget && appState.trackProgress && appState.spi != 0?
                                        <div id="status-info-box-3">
                                          
                                            <div className='row' style={{ marginBottom: '15px' }} >
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                               
                                                    <div className="info-box-3 bg-dark-blue" style={{ marginBottom: '5px',height:'45px' }} >
                                                        <div className="icon"></div>
                                                        <div style={{ textAlign: 'center' }} >
                                                            <div style={{ paddingTop: '8px' }} >
                                                                <FaCircle color={appState.spi < 1 ? Color.DarkRed : Color.LightGreen} size={25} style={{ marginTop: '-9px' }}  />
                                                                <a data-tooltip-id='spi-info' style={{ cursor: 'pointer' }} >
                                                                    <span style={{ fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }} >SPI:</span>
                                                                    <span className="" style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }} >{appState.spi.toFixed(2)}</span>
                                                                </a>

                                                            </div>
                                                            <Tooltip id='spi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                                <img className='bpi-image' src={spiImage} />
                                                            </Tooltip>
                                                            

                                                        </div>
                                                    </div>
                                    
                                                </div>
                                                <div className="col-md-4"></div>
                                            </div>
                                        </div>:null
                                    }


                                        <div id="status-chart">
                                            <StatusChart key={uniqid()} 
                                                phaseId={appState.phaseNumber} 
                                                progresses={appState.projectProgresses} 
                                                trackBudget={appState.trackBudget} 
                                                trackProgress={appState.trackProgress} 
                                                spi={appState.spi}
                                                bpi={appState.bpi}
                                                user={user}
                                                projectRole={props.projectRole}
                                            />
                                        </div>
                                    </> : null
                                }
                                <div id="status-activity">
                                    <div style={{ textAlign: "center", margin: "35px" }}  >
                                        <hr style={{ border: '1px solid'}}  />
                                    </div>
                                    <div className="row" style={{ marginTop: appState.phaseNumber > 1 ? '30px' : 0 }} >
                                        <div className="col-md-12">
                                            <StatusActivityUpdate 
                                                key={uniqid()} 
                                                projectId={props.projectId} 
                                                progresses={appState.projectProgresses} 
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div> 
                        </>
                        }
    </>:<Loading text="Loading..."/>
    }   
    </div>
 
    );

}

export default StatusView
