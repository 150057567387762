import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { ProjectType,AuthenUser,PageEvent,SelectItem} from '../../models';
import { ProjectTypeService,HistoryLogService } from '../../services';
import { AiOutlineSearch,AiOutlineCloseCircle,AiOutlineEdit,AiOutlineDelete} from "react-icons/ai";
import { useApiAuth } from '../../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper } from '../../helpers';
import { VscAdd } from "react-icons/vsc";
import { Color } from '../../enum'
import uniqid from 'uniqid';
import Loading from '../Loading'
import ReactPaginate from 'react-paginate';
import { RiRefreshLine,RiErrorWarningLine } from "react-icons/ri";
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    projectTypes:ProjectType[]
    projectIndex:number,
    projectActive:number
    isConfirm: boolean
    isLoading:boolean
}

const AdminProjectType = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        projectTypes:[],
        projectIndex: 0,
        projectActive: 0,
        isConfirm: false,
        isLoading:true
    })
    const [projectType, setProjectType] = useState<ProjectType>(
    {
            projectTypeId: 0,
            projectTypeName: "",
            updateDateTime: new Date(),
            updateBy: name,
            active: 1
    })
    const [modalShow, setModalShow] = useState(false);
    const [isConfirmActive, setIsConfirmActive] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [isError,setIsError] = useState(false)
    const [errorMessage,setErrorMessage] = useState("")
    const [selectItem,setSelectItem] = useState({projectTypeName: ""})
    const [searchStatus,setSearchStatus] = useState(false)

    useEffect(() => {
        if(accessToken){
            getProjectTypes(1,appState.limit);
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getProjectTypes(page,appState.limit);
        
    }

    const getProjectTypes =async (page: number, limitPage:number ,key:string = "") => {
        let token = await getToken()
        let result = await new ProjectTypeService(token).getAll(page,limitPage,key);
        let state = Object.assign({},appState)
        //console.log(result)
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state.limit = limit
            state.total = total
            state.page = page
            state.totalPgae = Math.ceil(total / limit)
            state.projectTypes = items
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const onSearchProjectType = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new ProjectTypeService(token).search(key);
        //console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectProjectType = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ projectTypeName: item.value})
          }
         
      }

    const searchProjectType = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({projectTypeName: inputSearch.value})
                key = inputSearch.value
            }
       
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)

        setSearchStatus(true)
        getProjectTypes(1,appState.limit,key)
    }

    const resetProjectType= () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectItem({projectTypeName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setSearchStatus(false)
        getProjectTypes(1,appState.limit)
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        
        let limit = parseInt(ev.target.value)
        getProjectTypes(1,limit,key)
    }

    const addItem = () => {
        let item:ProjectType = {
            projectTypeId: 0,
            projectTypeName: "",
            updateDateTime: new Date(),
            updateBy: name,
            active: 1
        }
        setProjectType(item)
        setModalShow(true)
    }

    const editItem = (item:ProjectType) => {
        setProjectType(item)
        setModalShow(true)
    }

    const confirmItem = (item:ProjectType) => {
        setProjectType(item)
        setIsConfirmDelete(true)
    }

    const saveProjectType = async () => {
        let result:any = null
        let _projectType = Object.assign({},projectType)
        let inputItem =document.getElementById('input-item') as HTMLInputElement
        if(!inputItem.value){
            return
        }
       
        try{
            _projectType.updateBy = name
            _projectType.projectTypeName = inputItem.value
            if(_projectType.projectTypeId == 0){
                let token = await getToken()
                result = await new ProjectTypeService(token).create(_projectType)
                new HistoryLogService(token).create(user.userId, null,"Admin Project Type",'Create Project Type "' + _projectType.projectTypeName + '"')
            }
            else{
                let token = await getToken()
                result = await new ProjectTypeService(token).update(_projectType)
                new HistoryLogService(token).create(user.userId, null,"Admin Project Type",'Update Project Type "' + _projectType.projectTypeName + '"')
            }
            if(result.success){
                setModalShow(false)
                getProjectTypes(1,appState.limit)
            }
            else{
                alert("Error!! " + result.message)
            }
           
        }
        catch(err){
            alert("Error!! " + err.message)
        }
    }

    const saveProjectTypeStatus = async() => {
        try{
            let _projectType = appState.projectTypes[appState.projectIndex]
            let activeStatus =  appState.projectActive === 1 ? 'Active' : 'Inactive'
            _projectType.active = appState.projectActive
            _projectType.updateBy = name
            let token = await getToken()
            let result = await new ProjectTypeService(token).updateStatus(_projectType)
              
            if(result && result.success){
                new HistoryLogService(token).create(user.userId, null,"Admin Project Type",'Set Active Status To  "' + activeStatus + '"')
                setIsConfirmActive(false)
                getProjectTypes(1,appState.limit)
            }
            else{
                alert("Error!! " + result.message)
            }
           
        }
        catch(err){
            alert("Error!! " + err.message)
        }
    }

    const deleteItem = async() => {
        try{
            let updateBy = name
            //console.log(assuranceActivity)
            
            let token = await getToken()
            let result = await new ProjectTypeService(token).delete(projectType.projectTypeId,updateBy)
            if(result && result.success){
                new HistoryLogService(token).create(user.userId, null,"Admin Project Type",'Delete project type "' + projectType.projectTypeName + '"')
                setIsConfirmDelete(false)
                getProjectTypes(1,appState.limit)
            }
            else{
                setIsConfirmDelete(false)
                if(result)
                setErrorMessage("Error!! " + result.message)
                
                setIsError(true)
            }
           
        }
        catch(err){
            setIsConfirmDelete(false)
            setErrorMessage("Error!! " + err.message)
            setIsError(true)
        }
        
    }

    const onProjectTypeChange = (index:number ,checked:boolean) => {
       
        let state = Object.assign({},appState)
        state.projectIndex = index
        state.projectActive = checked ? 1 : 0 
        setAppState(state)
        setIsConfirmActive(true)
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}} >
                <div className="body">
                
            <div className='project-block-none' style={{ paddingTop: '5px'}} >
            <div className="row">
                     <div className="col-sm-6">
                         <span className='project-label'>Project Type</span>
                     </div>
            </div>
            <div className='row' style={{ marginTop: '10px'}} >
                    <div className="col-md-4">
                        <div className="input-group">
                        <TextboxAutocomplete key={uniqid()}  valueKey="projectTypeName" onChange={onSearchProjectType} onSelect={onSelectProjectType} elementId='input-search' initialValue={selectItem.projectTypeName} hiddenArrow={true}/>
                        </div>
                    </div>
                    <div className='col-md-1'>
                            <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}}  onClick={() => searchProjectType()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}} />
                            </button>
                    </div>
                    <div className='col-md-1'>
                    { searchStatus ?
                                 <span className="input-group-append" style={{cursor:"pointer"}}  onClick={resetProjectType}>
                                     <RiRefreshLine size={20} color={Color.Blue}/>
                                     <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}} >Reset</span>
                                 </span>:null
                             } 
                    </div>
                     <div className='col-md-6' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}} >
                         <div className="admin-action">
                             <span className='app-active' style={{ cursor: 'pointer'}}  onClick={addItem}>
                                 <VscAdd size={20} color={Color.Blue} />
                                 <span style={{ fontSize: '14px',marginLeft:'2px'}} >Add Project Type</span>
                             </span>
                         </div> 
                     </div>
            </div>
            <div className='row' style={{ marginTop: '10px'}} >
                     <div className='col-md-10' style={{ paddingTop:'7px'}} >
                         <span style={{ marginLeft: '5px'}} >Search found {appState.total} record(s).</span>
                     </div>
                     <div className='col-md-2' style={{textAlign: 'right'}} >
                         <span style={{ marginRight: '5px',marginTop: '8px'}} >Show</span>
                         <select value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}} >
                             <option value={10}>10</option>
                             <option value={25}>25</option> 
                             <option value={50}>50</option>
                             <option value={100}>100</option>        
                         </select>
                     </div>
            </div>
        
        { !appState.isLoading ?
        <>
            <div className="table-responsive table-responsive-sm">
                <table className="table table-sm table-bordered table-hover" >
                    <thead>
                        <th style={{width: '80px'}} >No.</th>
                        <th>Project Type</th>
                        <th>Modified By</th>
                        <th>Modified Date</th>
                        {/* <th>Active Status</th> */}
                        <th>Edit</th>
                        <th>Delete</th>
                    </thead>
                    <tbody>
                    { appState.projectTypes.map((item,index) =>(
                            <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '#f3f3f3' : ''}} >
                                <td>
                                    {((appState.page -1) * appState.limit) + index + 1}
                                </td>
                                <td style={{textAlign:'left',paddingLeft:'20px'}} >
                                    <span>{ item.projectTypeName }</span>
                                </td>
                                <td style={{ textAlign: 'center' }} >
                                    <span>{ item.updateBy? item.updateBy : 'Admin' }</span>
                                </td>
                                <td style={{ textAlign: 'center' }} >
                                    <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                </td>
                                {/*
                                <td style={{ textAlign: 'center'}} >
                                    <div className="form-check form-switch" style={{margin: 'auto',width: '50px'}} >
                                        <input className="form-check-input" type="checkbox" role="switch" id={'project-mode-' + item.projectTypeId }  value={1} defaultChecked={item.active == 1} onChange={(ev) => onProjectTypeChange(index,ev.target.checked)}/>
                                    </div>
                                </td>
                                */}
                                <td className='text-center-both'>
                                    <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                </td>
                                <td>
                                    <AiOutlineDelete size={20} color={Color.Red2} cursor="pointer" onClick={ () => confirmItem(item)}/>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <div className='row' style={{ marginTop:'20px'}} >
                    <div className='col-md-4' style={{ display: 'flex'}} >
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}} >
                        <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                             forcePage={appState.page - 1}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                        </div>
                    </div>
                   
            </div>
        </>:<Loading text="Loading..."/>
        }
         </div>
         
                </div>
            </div>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                        <Modal.Title style={{ fontSize: '15px'}} >
                            { projectType.projectTypeId > 0 ? <span>Update Project Type</span> : <span> Add Project Type</span> }
                        </Modal.Title>
                        <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setModalShow(false)}>X</span>
                         
                     </Modal.Header>
                     <Modal.Body style={{ textAlign: 'center'}} >
                         <div className='row'>
                             <div className="col-sm-1 col-label">
                         
                             </div>
                             <div className="col-sm-10">
                                 <label  className="form-label col-label" style={{ float:'left'}} >Project Type</label>
                                 <input id="input-item" defaultValue={projectType.projectTypeName} type="text"  className="form-control form-control-sm " />
                             </div>
                         </div>
                        
                         <div className='row'>
                             <div className='col-sm-12 text-center-both'>
                                 <button type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}}  onClick={saveProjectType}>Save</button>
                             </div>
                         </div>
                     </Modal.Body>
                 </Modal>

                    <Modal show={isConfirmDelete} onHide={() => setIsConfirmDelete(false)} size="lg">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}} >Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer',fontWeight:'bold'}}  onClick={() => setIsConfirmDelete(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}} >
                         <div>
                             <RiErrorWarningLine size={25} color={'red'} />
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}} > Would you like to delete {projectType.projectTypeName}?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => deleteItem()} >
                             <span style={{ marginLeft: '5px'}}  >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => setIsConfirmDelete(false)} >
                             <span style={{ marginLeft: '5px'}}  >No</span>
                         </button>
                     </Modal.Body>
                 </Modal>

                 <Modal show={isError} onHide={() => setIsError(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}} >
                        <div className="alert alert-danger" role="alert" style={{marginBottom: 0}} >
                            <AiOutlineCloseCircle color="red" size={25}/>
                            <div style={{marginLeft:'10px',color:'red',marginBottom: '10px'}} >{errorMessage}</div>
                            <button type="button" className="btn btn-light" style={{"width": "100px", height: '32px'}}  onClick={() => setIsError(false)}>
                                <span style={{ fontSize:'14px',verticalAlign:'top'}} >OK</span>
                            </button>
                        </div>
                       
                    </Modal.Body>
                </Modal>
                <Modal show={isConfirmActive} onHide={() => setIsConfirmActive(false)} size="sm">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}} >Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer',fontWeight:'bold'}}  onClick={() => setIsConfirmActive(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}} >
                         <div>
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}} > Would you like to change active status?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => saveProjectTypeStatus()} >
                             <span style={{ marginLeft: '5px'}}  >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => setIsConfirmActive(false)} >
                             <span style={{ marginLeft: '5px'}}  >No</span>
                         </button>
                     </Modal.Body>
            </Modal>
        </div>
    );
  
}

export default AdminProjectType