import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { AiOutlineCheckCircle ,AiOutlineSave,AiOutlineInfoCircle} from "react-icons/ai";
import { EmailSettingService,HistoryLogService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { Emailsetting ,AuthenUser} from '../../models';
import Loading from '../Loading'
import { DateHelper,StringHelper } from '../../helpers';
import uniqid from 'uniqid';
import { Tooltip } from 'react-tooltip'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { RiErrorWarningLine } from "react-icons/ri";

interface AppState {
    emailsettings: Emailsetting[],
    historyLog:string[],
    modifyby:string,
    isLoading:boolean
}

const AdminEmail = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({ emailsettings:[],historyLog:[],modifyby:"",  isLoading:true})
    const [isSuccess, setIsSuccess] = useState(false)
    const [errors,setErrors] = useState<string[]>([])

    useEffect(() => {
        if(accessToken){
            new EmailSettingService(accessToken).getAll().then((result) => {
                //console.log(result)
                if(result && result.success){
                    const state = Object.assign({},appState)
                    state.emailsettings = result.data
                    state.isLoading =false
                    setAppState(state)
                    let element = document.getElementById('modify') as HTMLDivElement
                    element.innerHTML = "Modified by " + StringHelper.htmlEncode(result.data[0].updateBy) + " " + DateHelper.getDateTime(result.data[0].updateDateTime)
                }
            })
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const saveEmalSetting = async () => {
        let emailsettings = appState.emailsettings.map((item) => { item.updateBy = name; return item })
        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true

        let token = await getToken()
        let result = await new EmailSettingService(token).UpdateEmailSetting(emailsettings)
       
        if(result && result.success){
            saveBtn.disabled = false
            new HistoryLogService(token).create(user.userId, null,"Admin Email","Update Email Setting ("+ appState.historyLog.join(", ") +")")
           
            setIsSuccess(true)
            setTimeout(() => {
                let state = Object.assign({},appState)
                state.historyLog = []
                setAppState(state)
                setIsSuccess(false)
                if(result.message){
                    let element = document.getElementById('modify') as HTMLDivElement
                    element.innerHTML =  result.message
                }
               
            }, 500);
        }
        else{
            saveBtn.disabled = false
            if(result && result.message){
                setErrors([result.message])
            }
        }
    }

    const onEmalSettingChange = (item:Emailsetting,permissionName:string,permissionText:string,checked:boolean) => {
        let state = Object.assign({},appState)
        let index = appState.emailsettings.findIndex( x => x.emailSettingId === item.emailSettingId)
        if(index > -1){
            let checkStatus =  checked ? 'Checked' : 'Unchecked'
            state.emailsettings[index][permissionName] = checked ? 1 : 0
            state.emailsettings[index].updateBy = name
            state.historyLog.push(item.roleProjectLevelName + " set " + permissionText + ' = ' + checkStatus)
        }
        setAppState(state)
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}} >
                <div className="body">
                
             <div className='project-block-none' style={{ paddingTop: '5px'}} >
             <div className="row" style={{ width: '80%'}} >
                <div className="col-sm-6">
                    <span className='project-label'>Email</span>
                </div>
            </div>
            <div className="table-responsive table-responsive-sm">
            <table className="table table-sm table-bordered table-hover" >
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <span style={{ marginRight:'5px'}} >Create Project</span>
                            <a data-tooltip-id='info-create' style={{cursor: 'pointer'}} >
                                                <AiOutlineInfoCircle color='white' size={20}/>
                            </a>
                            <Tooltip id='info-create' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span>This email will be send when any project is created.</span>
                            </Tooltip>
                        </th>
                        
                        <th>
                            <span style={{ marginRight:'5px'}} >Deliverable Change</span>
                            <a data-tooltip-id='info-deliverable-change' style={{cursor: 'pointer'}} >
                                                <AiOutlineInfoCircle color='white' size={20}/>
                            </a>
                            <Tooltip id='info-deliverable-change' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span>This email will be send when deliverable status is changed.</span>
                            </Tooltip>
                        </th>
                        <th>
                            
                        <span style={{ marginRight:'5px'}} >Attached File</span>
                            <a data-tooltip-id='info-attached-file' style={{cursor: 'pointer'}} >
                                <AiOutlineInfoCircle color='white' size={20}/>
                            </a>
                            <Tooltip id='info-attached-file'  style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span>This email will be send when deliverable document is attached.</span>
                            </Tooltip>
                        </th>
                        <th>
                        <span style={{ marginRight:'5px'}} >Assurance Activity Change</span>
                            <a data-tooltip-id='assurance-activity-change' style={{cursor: 'pointer'}} >
                                <AiOutlineInfoCircle color='white' size={20}/>
                            </a>
                            <Tooltip id='assurance-activity-change' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span>This email will be send when assurance activity status is changed.</span>
                            </Tooltip>
                        </th>
                        <th>
                        <span style={{ marginRight:'5px'}} > Phase Change</span>
                            <a data-tooltip-id='phase-change' style={{cursor: 'pointer'}} >
                                <AiOutlineInfoCircle color='white' size={20}/>
                            </a>
                            <Tooltip id='phase-change' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span>This email will be send when project phase is changed.
                                </span>
                            </Tooltip>
                        </th>
                        <th>Active Status</th>
                        
                    </tr>
                </thead>
                <tbody>
                { !appState.isLoading ?
                    <>
                        { appState.emailsettings.map((item,index) => (
                            <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '' : '#f3f3f3'}} >
                                <td style={{textAlign:'left',paddingLeft:'10px'}} >
                                    {item.roleProjectLevelName}
                                </td>
                                <td className='text-center-both'>
                                    <input className="form-check-input" type="checkbox" name="status-check" value={1} defaultChecked={item.createProject == 1} disabled={item.createProject == 2}  onChange={(ev) => onEmalSettingChange(item,'createProject','Create Project',ev.target.checked)}/>
                                </td>
                                <td className='text-center-both'>
                                    <input className="form-check-input" type="checkbox" name="status-check" value={1} defaultChecked={item.deliverableChange == 1} disabled={item.deliverableChange == 2}  onChange={(ev) => onEmalSettingChange(item,'deliverableChange','Deliverable Change',ev.target.checked)}/>
                                </td>
                                <td className='text-center-both'>
                                    <input className="form-check-input" type="checkbox" name="attached-check"  value={1} defaultChecked={item.attachedFile == 1}  disabled={item.attachedFile == 2} onChange={(ev) => onEmalSettingChange(item,'attachedFile','Attached File',ev.target.checked)}/>
                                </td>
                                <td className='text-center-both'>
                                    <input className="form-check-input" type="checkbox" name="stage-check"  value={1} defaultChecked={item.assuranceActivityChange == 1} disabled={item.assuranceActivityChange == 2} onChange={(ev) => onEmalSettingChange(item,'assuranceActivityChange','Assurance ActivityChange',ev.target.checked)}/>
                                </td>
                                <td className='text-center-both'>
                                    <input className="form-check-input" type="checkbox" name="phase-check" value={1} defaultChecked={item.phaseChange == 1} disabled={item.phaseChange == 2} onChange={(ev) => onEmalSettingChange(item,'phaseChange','Phase Change',ev.target.checked)}/>
                                </td>
                                <td className='text-center-both'>
                                    <div className="form-check form-switch" style={{margin: 'auto',width: '50px'}} >
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={1} defaultChecked={item.active == 1}  onChange={(ev) => onEmalSettingChange(item,'active','Active',ev.target.checked)}/>
                                       
                                    </div>
                                </td>
                           
                            </tr>
                        ))

                        }
                    </>:<Loading text="Loading..."/>
                }
                

                </tbody>
            </table>
            </div>
                { !appState.isLoading ?
                            <div className='row'>
                                <div className='col-md-12' style={{ textAlign: 'right' }} >
                                <span id="modify" style={{fontStyle:'italic',color:'grey'}} ></span>
                                </div>
                            </div>:null
                }

                            <div className='row'>
                                <div className='col-md-12' style={{ textAlign: 'center' }} >
                                { user.administratorUpdate > 0 ?
                                    <button id="save-btn" type="button" className="btn btn-sm bg-dark-blue" style={{width:'200px'}}  onClick={saveEmalSetting} disabled={appState.historyLog.length === 0}>
                                        <AiOutlineSave size={20} />
                                        <span style={{ marginLeft: '5px'}} >Save</span>
                                    </button>:<></>
                                }
                                </div>
                            </div>
                            <div className='row' style={{ marginBottom: '100px'}} >
                            <div className='col-md-12' style={{ textAlign: 'center'}} >
                               
                            </div>
                         </div>
         </div>
         
                </div>
            </div>
            <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}} >
                        <div className="alert alert-success" role="alert" style={{marginBottom: 0}} >
                            <AiOutlineCheckCircle color="green" size={25}/>
                            <span style={{marginLeft:'10px',color:'green'}} >Success</span>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}} >
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}} >Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}} >
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}} >
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}} >{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}} >
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}   onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}}  >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
        </div>
    );
  
}

export default AdminEmail