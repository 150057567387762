import axios from 'axios'
import {DataObjectResponse} from '../models/PageResponse'
import { Property } from '../models/'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL
const version = 1

export default class PropertyService
{
    protected headers = {}
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        
    }
    
    async getProperties(propertyFlags: string[]){
            if(Object.keys(this.headers).length === 0 ) return null

            let params:string[] = []
            propertyFlags.forEach(key => {
                params.push(key + "=1")
            })
        
            let options = {
                headers: this.headers
            }
        
            const response = await axios.get<DataObjectResponse<Property>>(`${apiUrl}/v${version}/properties/call?`+ params.join('&'), options )
            return response.data;
        
    }

    async getProperties2(propertyFlags: string[]){
        if(Object.keys(this.headers).length === 0 ) return null

        let params:string[] = []
        propertyFlags.forEach(key => {
            params.push(key + "=2")
        })
    
        let options = {
            headers: this.headers
        }
    
        const response = await axios.get<DataObjectResponse<Property>>(`${apiUrl}/v${version}/properties/call?`+ params.join('&'), options )
        return response.data;
    
}


}