import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { apiRequest } from "../configurations/authConfig";
import ApiAzureService from "../services/ApiAzureService";
import  secureLocalStorage  from  "react-secure-storage";

export const useApiAuth = () => {
    const { instance, accounts } = useMsal();

    const account = accounts && accounts.length ? accounts[0] : null;
    const [accessToken, setAccessToken] = useState<string | null>(null);

    useEffect(() => {

        const run = async () => {
            let result =await new ApiAzureService().getClientId()
                // console.log(result);
            
                apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
            if (account) {
                const { accessToken: ac } = await instance.acquireTokenSilent({
                    account: account,
                    scopes: apiRequest.scopes
                });

                    setAccessToken(ac);

                }
        };

            run();

    }, [instance, account]);

    return {
        account,
        accessToken,
    };
};