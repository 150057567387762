import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { AssetType,AuthenUser,PageEvent,SelectItem} from '../../models';
import { AssetTypeService ,HistoryLogService } from '../../services';
import { AiOutlineSearch,AiOutlineCloseCircle,AiOutlineEdit,AiOutlineDelete} from "react-icons/ai";
import { useApiAuth } from '../../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper } from '../../helpers';
import { VscAdd } from "react-icons/vsc";
import { Color } from '../../enum'
import uniqid from 'uniqid';
import Loading from '../Loading'
import ReactPaginate from 'react-paginate';
import { RiRefreshLine,RiErrorWarningLine } from "react-icons/ri";
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    assetTypes:AssetType[]
    isConfirm: boolean
    isLoading:boolean
}

const AdminAssetType = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        assetTypes:[],
        isConfirm: false,
        isLoading:true
    })
    const [assetType, setAssetType] = useState<AssetType>(
    {
        assetTypeId: 0,
        assetTypeName: "",
        updateDateTime: new Date(),
        updateBy: name
    })
    const [modalShow, setModalShow] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [selectItem,setSelectItem] = useState({assetTypeName: ""})
    const [searchStatus,setSearchStatus] = useState(false)
    const [errors,setErrors] = useState<string[]>([])

    useEffect(() => {
        if(accessToken){
            getAssetTypes(1,appState.limit);
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getAssetTypes(page,appState.limit);
        
    }

    const getAssetTypes =async (page: number, limitPage:number ,key:string = "") => {
        let token = await getToken()
        let result = await new AssetTypeService(token).getAll(page,limitPage,key);
        let state = Object.assign({},appState)
       
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state.limit = limit
            state.total = total
            state.page = page
            state.totalPgae = Math.ceil(total / limit)
            state.assetTypes = items
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const onSearchAssetType = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new AssetTypeService(token).search(key);
        console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectAssetType = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ assetTypeName: item.value})
          }
         
      }

    const searchAssetType = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({assetTypeName: inputSearch.value})
                key = inputSearch.value
            }
       
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)

        setSearchStatus(true)
        getAssetTypes(1,appState.limit,key)
    }

    const resetAssetType = () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectItem({assetTypeName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setSearchStatus(false)
        getAssetTypes(1,appState.limit)
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        
        let limit = parseInt(ev.target.value)
        getAssetTypes(1,limit,key)
    }

    const addItem = () => {
        let item:AssetType = {
            assetTypeId: 0,
            assetTypeName: "",
            updateDateTime: new Date(),
            updateBy: name
        }
        setAssetType(item)
        setModalShow(true)
    }

    const editItem = (item:AssetType) => {
        setAssetType(item)
        setModalShow(true)
    }

    const confirmItem = (item:AssetType) => {
        setAssetType(item)
        setIsConfirmDelete(true)
    }

    const saveAssetType = async () => {
        let result:any = null
        let _assetType = Object.assign({},assetType)
        let inputItem =document.getElementById('input-item') as HTMLInputElement
        if(!inputItem.value){
            return
        }

        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true
       
        try{
            _assetType.updateBy = name
            _assetType.assetTypeName = inputItem.value
            let token = await getToken()
            if(_assetType.assetTypeId == 0){
                result = await new AssetTypeService(token).create(_assetType)
                new HistoryLogService(token).create(user.userId, null,"Admin Asset Type",'Create Asset Type "' + _assetType.assetTypeName + '"')
            }
            else{
                result = await new AssetTypeService(token).update(_assetType)
                new HistoryLogService(token).create(user.userId, null,"Admin Asset Type",'Update Asset Type "' + _assetType.assetTypeName + '"')
            }
            if(result.success){
                saveBtn.disabled = false
                setModalShow(false)
                getAssetTypes(1,appState.limit)
            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            saveBtn.disabled = false
            setErrors([err.message])
        }
    }

    const deleteItem = async() => {
        try{
            let updateBy = name
            //console.log(assuranceActivity)
            
            let token = await getToken()
            let result = await new AssetTypeService(token).delete(assetType.assetTypeId,updateBy)
            if(result && result.success){
                new HistoryLogService(token).create(user.userId, null,"Admin Asset Type",'Delete Asset Type "' + assetType.assetTypeName + '"')
                setIsConfirmDelete(false)
                getAssetTypes(1,appState.limit)
            }
            else{
                setIsConfirmDelete(false)
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            setIsConfirmDelete(false)
            setErrors([err.message])
        }
        
    }

    
    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}} >
                <div className="body">
                
            <div className='project-block-none' style={{ paddingTop: '5px'}} >
            <div className="row">
                     <div className="col-sm-6">
                         <span className='project-label'>Asset Type</span>
                     </div>
            </div>
            <div className='row' style={{ marginTop: '10px'}} >
                    <div className="col-md-4">
                        <div className="input-group">
                        <TextboxAutocomplete key={uniqid()}  valueKey="assetTypeName" onChange={onSearchAssetType} onSelect={onSelectAssetType} elementId='input-search' initialValue={selectItem.assetTypeName} hiddenArrow={true}/>
                        </div>
                    </div>
                    <div className='col-md-1'>
                            <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}}  onClick={() => searchAssetType()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}} />
                            </button>
                    </div>
                    <div className='col-md-1'>
                    { searchStatus ?
                                 <span className="input-group-append" style={{cursor:"pointer"}}  onClick={resetAssetType}>
                                     <RiRefreshLine size={20} color={Color.Blue}/>
                                     <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}} >Reset</span>
                                 </span>:null
                             } 
                    </div>
                     <div className='col-md-6' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}} >
                         {/* <div className="admin-action">
                             <span className='app-active' style={{ cursor: 'pointer'}}  onClick={addItem}>
                                 <VscAdd size={20} color={Color.Blue} />
                                 <span style={{ fontSize: '14px',marginLeft:'2px'}} >Add Asset Type</span>
                             </span>
                         </div>  */}
                     </div>
            </div>
            <div className='row' style={{ marginTop: '10px'}} >
                     <div className='col-md-10' style={{ paddingTop:'7px'}} >
                         <span style={{ marginLeft: '5px'}} >Search found {appState.total} record(s).</span>
                     </div>
                     <div className='col-md-2' style={{textAlign: 'right'}} >
                         <span style={{ marginRight: '5px',marginTop: '8px'}} >Show</span>
                         <select value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}} >
                             <option value={10}>10</option>
                             <option value={25}>25</option> 
                             <option value={50}>50</option>
                             <option value={100}>100</option>        
                         </select>
                     </div>
            </div>

            <div className="table-responsive table-responsive-sm">
                <table className="table table-sm table-bordered table-hover" >
                    <thead>
                        <th style={{width: '80px'}} >No.</th>
                        <th>Country</th>
                        <th>Modified By</th>
                        <th>Modified Date</th>
                        <th>Edit</th>
                       
                    </thead>
                    <tbody>
                    { appState.assetTypes.map((item,index) =>(
                            <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '#f3f3f3' : ''}} >
                                <td>
                                    {((appState.page -1) * appState.limit) + index + 1}
                                </td>
                                <td style={{textAlign:'left',paddingLeft:'20px'}} >
                                    <span>{ item.assetTypeName }</span>
                                </td>
                                <td style={{ textAlign: 'center' }} >
                                    <span>{ item.updateBy? item.updateBy: 'Admin' }</span>
                                </td>
                                <td style={{ textAlign: 'center' }} >
                                    <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                </td>
                                <td className='text-center-both'>
                                    { user.administratorUpdate > 0 ?
                                        <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                        :<></>
                                    }
                                </td>
                              
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <div className='row' style={{ marginTop:'20px'}} >
                    <div className='col-md-4' style={{ display: 'flex'}} >
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}} >
                        <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                             forcePage={appState.page - 1}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                        </div>
                    </div>
                   
            </div>
         </div>
         
                </div>
            </div>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                        <Modal.Title style={{ fontSize: '15px'}} >
                            { assetType.assetTypeId > 0 ? <span>Update Asset Type</span> : <span> Add Asset Type</span> }
                        </Modal.Title>
                        <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setModalShow(false)}>X</span>
                         
                     </Modal.Header>
                     <Modal.Body style={{ textAlign: 'center'}} >
                         <div className='row'>
                             <div className="col-sm-1 col-label">
                         
                             </div>
                             <div className="col-sm-10">
                                 <label  className="form-label col-label" style={{ float:'left'}} >Asset Type</label>
                                 <input id="input-item" defaultValue={assetType.assetTypeName} type="text"  className="form-control form-control-sm " />
                             </div>
                         </div>
                        
                         <div className='row'>
                             <div className='col-sm-12 text-center-both'>
                                 <button id="save-btn" type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}}  onClick={saveAssetType}>Save</button>
                             </div>
                         </div>
                     </Modal.Body>
                 </Modal>

                    <Modal show={isConfirmDelete} onHide={() => setIsConfirmDelete(false)} size="lg">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}} >Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer',fontWeight:'bold'}}  onClick={() => setIsConfirmDelete(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}} >
                         <div>
                             <RiErrorWarningLine size={25} color={'red'} />
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}} > Would you like to delete {assetType.assetTypeName}?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => deleteItem()} >
                             <span style={{ marginLeft: '5px'}}  >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => setIsConfirmDelete(false)} >
                             <span style={{ marginLeft: '5px'}}  >No</span>
                         </button>
                     </Modal.Body>
                 </Modal>

                 <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}} >
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}} >Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}} >
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}} >
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}} >{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}} >
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}   onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}}  >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
               
        </div>
    );
  
}

export default AdminAssetType