
import React,{useState ,useEffect} from 'react';
import { AiOutlineSearch } from "react-icons/ai";
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import { EmailService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { EmailLog ,PageEvent} from '../../models';
import Loading from '../Loading'
import { DateHelper } from '../../helpers';
import parse from 'html-react-parser';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    emails:EmailLog[]
    isLoading:boolean
}

const AdminEmailLog = () => {
    const { accessToken } = useApiAuth()
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 25,
        emails:[],
        isLoading:true
    })

    useEffect(() => {

        if(accessToken){
           getHistory(1)
        }
    },[accessToken])

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getHistory(page);
        
    }

    const getHistory = async (page) =>{
        let result =  await new EmailService(accessToken).getAll(page,appState.limit)
            let state = Object.assign({},appState)
            //console.log(result)
            if(result && result.success){
                let {page,limit,total,items} = result.data
                state.total = total
                state.page = page
                state.totalPgae = Math.ceil(total / limit)
                state.emails = items
            }
          
            state.isLoading = false
            setAppState(state)
    }
    const getHtml = (html:string) =>{
        return (
            <div>
                {html.replace(/\n/g,"<br/>")}
            </div>
        )
    }
    return (
        <div className="container-fluid">
          
            <div style={{ paddingBottom: '50px'}} >
                <div className="body">
              
                    <div className='project-block-none' style={{ paddingTop: '5px' }} >
                        <div className="row" style={{ width: '80%' }} >
                            <div className="col-sm-12">
                                <span className='inform-create-header'>History Logs</span>

                            </div>
                        </div>
                    <div className="row" style={{marginTop:'20px'}} >
                        <div className='col-md-4'>
                        <div className="input-group">
                                    <input className="form-control border-end-0 border" type="search" placeholder="search by project name,user" id="example-search-input" style={{ height: '30px', fontSize:"0.875rem" }}  />
                                <span className="input-group-append">
                                    <button className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ left:'-2px',height: '30px'}} >
                                        <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}} />
                                    </button>
                                </span>
                        </div>
                        </div>
                        <div className='col-md-1' style={{ textAlign: 'center',paddingTop: '6px'}} >
                            Start
                        </div>
                        <div className="col-md-2">
                            {/* <DatePicker
                                dateFormat="dd MMM yyyy"
                                selected={ startDate } 
                                onChange={ (date) => setStartDate(date) } 
                                isClearable 
                                placeholderText="Please select" 
                                className="form-control form-control-sm"
                             /> */}
                        </div>
                        <div className='col-md-1' style={{ textAlign: 'center',paddingTop: '6px'}} >
                            To
                        </div>
                        <div className='col-md-2'>
                            {/* <DatePicker
                                dateFormat="dd MMM yyyy"
                                selected={ startDate } 
                                onChange={ (date) => setStartDate(date) } 
                                isClearable 
                                placeholderText="Please select" 
                                className="form-control form-control-sm"
                            /> */}
                        </div>
                        <div className='col-md-2'>
                             <button type="button" className="btn btn-sm" style={{width : "100%",backgroundColor: 'rgb(0, 51, 141)'}} >
                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}} >Filter</span>
                            </button>
                        </div>
            </div>

            <div className='row' style={{ marginTop: '20px'}} >
                        <div className='col-md-10' style={{ paddingTop:'7px'}} >
                            <span style={{ marginLeft: '5px'}} >Search found {appState.total} record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}} >
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}} >Show</span>
                            
                            <select className="" style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}} >
                                <option>10</option>
                                <option>20</option> 
                                <option>50</option>
                                <option>100</option>        
                            </select>
                        </div>
            </div>
        <div className="table-responsive table-responsive-sm">
            <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th style={{width: '50px'}} >No.</th>
                        <th style={{ width:'150px'}} >Send To</th>
                        <th style={{ width:'150px'}} >Title</th>
                        <th>Description</th>
                        <th style={{ width:'125px'}} >Create Date</th>
                    
                                       
                    </tr>
                </thead>
                <tbody>
                { !appState.isLoading ?
                <>
                    { appState.emails.map((item,index) => (
                        <tr style={{ backgroundColor: index % 2 ? '#f3f3f3' : ""}} >
                            <td>{((appState.page -1) * appState.limit) + index + 1}</td>
                       
                                        <td style={{ textAlign: 'left',wordBreak:'break-word'}} >
                                            <span>{item.sendTo}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{item.title}</span>
                                        </td>
                                        <td style={{ textAlign: 'left',wordBreak:'break-word',paddingLeft:'15px'}} >
                                            <span>{parse(item.context)}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{DateHelper.getDateTime(item.createDateTime)}</span>
                                        </td>
                                       
                                        
                       
                        </tr>
                    ))
                }
                </>:<Loading text="Loading..."/>
                }
                </tbody>
            </table>
        
         </div>
         <div className='row'>
                     <div className='col-md-4' style={{ display: 'flex'}} >
                  
                     </div>
                     <div className='col-md-8'>
                         <div id='pagination' style={{ float: 'right'}} >
                         <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                            // renderOnZeroPageCount={null}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                         </div>
                     </div>     
                 </div>
        
         </div>
                </div>
            </div>
        </div>
    );
  
}

export default AdminEmailLog
