import React,{ useState } from "react";
import PrepStageGateStatus from './PrepStageGateStatus'
import GateKeeperWorkList from './GateKeeperWorkList'

const PrepStageGate = () => {
    const [tab,setTab] = useState(0)

    return (
        <div className="container-fluid">
            <nav className="nav-menu" aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-bottom">
                    <li className="breadcrumb-item active" aria-current="page">
                        PREP Stage Gate Status and Deliverable Document
                    </li>
                </ol>
            </nav>
            <div className="body">
                <div className="tabs">
                    <input type="radio" id="tab1" name="tab-control" defaultChecked onClick={() => setTab(0)}/>
                    <input type="radio" id="tab2" name="tab-control" onClick={() => setTab(1)}/>
                    <ul>
                        <li title="Information">
                            <label htmlFor="tab1" role="button">
                                <span style={{ marginLeft: '5px'}} >PREP Stage Gate Status</span>
                            </label>
                        </li>
                        <li title="Status">
                            <label htmlFor="tab2" role="button">
                                <span style={{ marginLeft: '5px'}} >Deliverable Document</span>
                            </label>
                        </li>    
                    </ul>
                    <div className="slider" style={{ width: '50%'}} >
                        <div className="indicator"></div>
                    </div>
                    <div className="content">
                        <section>
                            <PrepStageGateStatus/>            
                        </section>
                        <section>
                            <GateKeeperWorkList/>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrepStageGate