import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { ProjectMode,AuthenUser,PageEvent,SelectItem} from '../../models';
import { ProjectModeService,HistoryLogService } from '../../services';
import { AiOutlineSave,AiOutlineCheckCircle,AiOutlineSearch,AiOutlineEdit,AiOutlineDelete} from "react-icons/ai";
import { useApiAuth } from '../../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper } from '../../helpers';
import uniqid from 'uniqid';
import Loading from '../Loading'
import { VscAdd } from "react-icons/vsc";
import ReactPaginate from 'react-paginate';
import { RiRefreshLine,RiErrorWarningLine } from "react-icons/ri";
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import { Color } from '../../enum'
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    projectIndex: number
    projectActive:number
    projectModes:ProjectMode[]
    isConfirm: boolean
    isLoading:boolean
}
const AdminProjectMode = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState, setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        projectIndex: 0,
        projectActive: 0,
        projectModes: [],
        isConfirm: false,
        isLoading: true
    });
    const [projectMode, setProjectMode] = useState<ProjectMode>(
        {
            projectModeId: 0,
            projectModeName: "",
            updateBy: name,
            updateDateTime: new Date(),
            active:1
        })
    const [isConfirm, setIsConfirm] = useState(false)
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false)
    const [searchStatus,setSearchStatus] = useState(false)
    const [selectItem,setSelectItem] = useState({projectModeName: ""})
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if(accessToken){
            getProjectModes(1,appState.limit);
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getProjectModes(page,appState.limit);
        
    }

    const getProjectModes =async (page: number, limitPage:number ,key:string = "") => {
        let token = await getToken()
        let result = await new ProjectModeService(token).getAll(page,limitPage,key);
        let state = Object.assign({},appState)
        //console.log(result)
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state.limit = limit
            state.total = total
            state.page = page
            state.totalPgae = Math.ceil(total / limit)
            state.projectModes = items
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const onProjectModeChange = (index: number ,checked:boolean) => {
        let state = Object.assign({},appState)
        state.projectIndex = index
        state.projectActive = checked ? 1 : 0 
        setAppState(state)
        setIsConfirm(true)
    }

    const saveProjectMode = async () => {
        let result:any = null
        let _projectMode = Object.assign({},projectMode)
        let inputItem =document.getElementById('input-item') as HTMLInputElement
        if(!inputItem.value){
            return
        }
       
        try{
            _projectMode.updateBy = name
            _projectMode.projectModeName = inputItem.value
            if(_projectMode.projectModeId == 0){
                let token = await getToken()
                result = await new ProjectModeService(token).create(_projectMode)
                new HistoryLogService(token).create(user.userId, null,"Admin Project Type",'Create Project Type "' + _projectMode.projectModeName + '"')
            }
            else{
                let token = await getToken()
                result = await new ProjectModeService(token).update(_projectMode)
                new HistoryLogService(token).create(user.userId, null,"Admin Project Type",'Update Project Type "' + _projectMode.projectModeName + '"')
            }
            if(result.success){
                setModalShow(false)
                getProjectModes(1,appState.limit)
            }
            else{
                alert("Error!! " + result.message)
            }
           
        }
        catch(err){
            alert("Error!! " + err.message)
        }
    }

    const saveProjectModeStatus = async () => {
        let checkStatus =  appState.projectActive ? 'Active' : 'Inactive'
        let index = appState.projectIndex
        let projectMode = appState.projectModes[index]
        projectMode.active = appState.projectActive
        projectMode.updateBy = name
        let token = await getToken()
        let result = await new ProjectModeService(token).updateStatus(projectMode)
       
        if(result && result.success){
            new HistoryLogService(token).create(user.userId, null,"Admin Project Category","Update " + projectMode.projectModeName +", Set Active Status To "+ checkStatus)
           
            setTimeout(() => {
                let state = Object.assign({},appState)
                state.projectModes[index].active = appState.projectActive
                setAppState(state)
                setIsConfirm(false)
            }, 500);
        }
    }

    const onSearchProjectMode = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new ProjectModeService(token).search(key);
        //console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectProjectMode = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ projectModeName: item.value})
          }
         
      }
    const searchProjectMode = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({projectModeName: inputSearch.value})
                key = inputSearch.value
            }
       
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)

        setSearchStatus(true)
        getProjectModes(1,appState.limit,key)
    }

    const resetProjectMode = () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectItem({projectModeName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setSearchStatus(false)
        getProjectModes(1,appState.limit)
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        
        let limit = parseInt(ev.target.value)
        getProjectModes(1,limit,key)
    }

    const addItem = () => {
        let item:ProjectMode = {
            projectModeId: 0,
            projectModeName: "",
            updateBy: name,
            updateDateTime: new Date(),
            active:1
        }
        setProjectMode(item)
        setModalShow(true)
    }

    const editItem = (item:ProjectMode) => {
        setProjectMode(item)
        setModalShow(true)
    }

    const confirmItem = (item:ProjectMode) => {
        setProjectMode(item)
        setIsConfirmDelete(true)
    }
    return (
        <div className="container-fluid">
            <div style={{ "padding":"5px 15px"}} >
                <div className="body">
                    <div className='project-block-none' style={{ paddingTop: '5px'}} >
                        <div className="row">
                            <div className="col-sm-6">
                                <span className='project-label'>Project Category</span>
                            </div>
                        </div>
                        <div className="table-responsive table-responsive-sm">
                            <table className="table table-sm table-bordered table-hover" >
                                <thead>
                                    <tr>
                                        <th style={{width: '50px'}} >No.</th>
                                        <th style={{width: '45%'}} >Project Category</th>
                                        <th>Modified By</th>
                                        <th style={{ width: '150px'}} >Modified Date</th>
                                     
                                        <th>Edit</th>
                                       
                                    </tr>
                                </thead>
                                <tbody>
                                { !appState.isLoading ?
                                    <>
                                    {   appState.projectModes.map((item,index) =>(
                                            <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '' : '#f3f3f3'}} >
                                                <td>{ index + 1}</td>
                                                <td>{ item.projectModeName }</td>
                                                <td style={{ textAlign: 'center' }} >
                                                    <span>{ item.updateBy ? item.updateBy: 'Admin'}</span>
                                                </td>
                                                <td style={{ textAlign: 'center' }} >
                                                    <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                                </td>
                                        
                                                <td className='text-center-both'>
                                                    { user.administratorUpdate > 0 ?
                                                        <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                                        :<></>
                                                    }
                                                </td>
                                              
                                            </tr>
                                        ))
                                    }
                                    </>:<Loading text='Loading...'/>
                                }
                                </tbody>
                            </table>
                            
                        </div>
                {/* <div className='row' style={{ marginTop:'20px'}} >
                            <div className='col-md-4' style={{ display: 'flex'}} >
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}} >
                        <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                             forcePage={appState.page - 1}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                        </div>
                    </div>
                   
                </div> */}
                    </div>
         
                </div>
            </div>
            <Modal show={isConfirm} onHide={() => setIsConfirm(false)} size="sm">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}} >Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer',fontWeight:'bold'}}  onClick={() => setIsConfirm(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}} >
                         <div>
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}} > Would you like to change active status?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => saveProjectModeStatus()} >
                             <span style={{ marginLeft: '5px'}}  >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => setIsConfirm(false)} >
                             <span style={{ marginLeft: '5px'}}  >No</span>
                         </button>
                     </Modal.Body>
            </Modal>
            <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}} >
                        <div className="alert alert-success" role="alert" style={{marginBottom: 0}} >
                            <AiOutlineCheckCircle color="green" size={25}/>
                            <span style={{marginLeft:'10px',color:'green'}} >Success</span>
                        </div>
                    </Modal.Body>
            </Modal>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                        <Modal.Title style={{ fontSize: '15px'}} >
                            { projectMode.projectModeId > 0 ? <span>Update Project Category</span> : <span> Add Project Category</span> }
                        </Modal.Title>
                        <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setModalShow(false)}>X</span>
                         
                     </Modal.Header>
                     <Modal.Body style={{ textAlign: 'center'}} >
                         <div className='row'>
                             <div className="col-sm-1 col-label">
                         
                             </div>
                             <div className="col-sm-10">
                                 <label  className="form-label col-label" style={{ float:'left'}} >Project Category</label>
                                 <input id="input-item" defaultValue={projectMode.projectModeName} type="text"  className="form-control form-control-sm " />
                             </div>
                         </div>
                        
                         <div className='row'>
                             <div className='col-sm-12 text-center-both'>
                                 <button type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}}  onClick={saveProjectMode}>Save</button>
                             </div>
                         </div>
                     </Modal.Body>
                 </Modal>
        </div>
    );
}

export default AdminProjectMode