import axios from 'axios'
import { config } from '../config'
import secureLocalStorage from "react-secure-storage";
const apiUrl = config.API_URL

export default class CookieHelper{
    static async getCookie(token: string) {

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        let options = {
            headers:headers,
        }
        
        let key = 'csrfToken';
        let storageResult = secureLocalStorage.getItem(key)?.toString()
        if(storageResult !== undefined && storageResult !== null && storageResult.trim() !== '')
        {
            let jsonResult = JSON.parse(storageResult)
            let timeNow = new Date()
            if (timeNow.getTime() > jsonResult.expiryTime) {
                localStorage.removeItem(key);
            }
            else{
                return jsonResult.value; 
            }
        }
        let result = await axios.get(`${apiUrl}/v1/antiforgery/call`, options)
        let storeData = {
            value : result.data.data,
            expiryTime: new Date().getTime() + 180000
        }
        secureLocalStorage.setItem(key, JSON.stringify(storeData))
        return storeData.value
    }
}