import axios from 'axios'
import {  DataArrayResponse,PageResponse,PageDataResponse } from '../models/PageResponse'
import {HistoryLog ,HistoryData,Parameter} from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class HistoryLogService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }

    async getAll(parameter: Parameter){
        if(!this.token) return null

        let params = Object.assign({},parameter)
        let options = {
                headers: this.headers,
                params: params
            }
                                                                                        
        const { data }  = await axios.get<PageDataResponse<HistoryLog>>(`${apiUrl}/v1/historylogs/call`, options )
        
        return data;
    }

    async getByProjectId(id:number ){
        let options = {
                headers:this.headers,
                params: {
                    projectId: id
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<HistoryLog>>(`${apiUrl}/v1/historylogs/call`, options )
        
        return data;
    }

    async create(userId:number,projectId :number| null,functionName:string,action:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        
        let historyData:HistoryData = {
            userId:userId,
            projectId: projectId,
            functionName:functionName,
            action:action
        }

        let options = {
            headers: this.headers
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v1/historylogs/call`,JSON.stringify(historyData),options )
        return data;
    }

}