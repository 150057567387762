import React, { useEffect } from 'react';
import { footerPng } from './images';
import Router from './router'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import Authentication from './Authentication';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
//import { useApiAuth } from './hooks/useApiAuth';
import { UserService } from './services'
// import { AuthenUser } from './models'
// import  secureLocalStorage  from  "react-secure-storage";
import './App.css'

function ProtectedComponent() {
  const { instance, inProgress, accounts } = useMsal();
  //const userStore = localStorage.getItem('user')
  //const user = userStore ? JSON.parse(userStore): null

  useEffect(() => {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    };
    const controller = new AbortController();
    const signal = controller.signal;

    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          if (accessToken) {


          }
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.log(error);
        });
    }

    return () => {
      controller.abort();
    };
  }, [instance, accounts, inProgress]);

  return <span style={{ display: 'none', }}  >Authentication... </span>;
}


const App = () => {

  return (
    <div>
        <AuthenticatedTemplate>
          <ProtectedComponent />
          <div className="d-flex" id="wrapper" style={{ paddingBottom: "100px", paddingTop: "140px" }} >
            <div id="page-content-wrapper">
              <Router />
            </div>
          </div>
          <div id="footer-page" className="footer-page">
            <img src={footerPng} style={{ width: '100%', height: '60px' }}  />
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Authentication />
        </UnauthenticatedTemplate>
    </div>
  );
}

export default App;