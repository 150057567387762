import React, { useState,useEffect } from 'react';
import '../../assets/css/portfolio.css'
import { AiOutlineClockCircle, AiFillCloseCircle, AiOutlinePauseCircle, AiFillCheckCircle, AiOutlineTrademarkCircle } from "react-icons/ai";
import {  VscCircleSlash, VscDebugStepOver } from "react-icons/vsc";
import { Color, SystemLevel,StatusDeliverable } from '../../enum'
import { DateHelper } from '../../helpers';
import { Phase,AuthenUser,RoleProjectLevel,ProjectDeliverable, ProjectPhase,UserItem,ProjectPhaseRole} from '../../models'
import uniqid from 'uniqid';
import Loading from '../Loading';

interface Props {
    isUpdate: boolean 
    projectId:number
    projectName:string
    projectModeId:number 
    phaseId:number 
    phases:Phase[] 
    user:AuthenUser
    projectOwners:UserItem[]
    gatekeepers:UserItem[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectRole:RoleProjectLevel | null
    projectDeliverables:{ [key:string]:ProjectDeliverable[] }
    projectPhases:ProjectPhase[]
}

const PrepStatusPdf = (props:Props) => {
    const isCanDeliverableUpdate = (props.user.deliverableUpdate || props.projectRole?.deliverableUpdate ) ? true : false
    const isCanAssuranceActivityUpdate = (props.projectRole?.deliverableApprove  || props.projectRole?.assuranceActivityUpdate  ) ? true : false
    const isSuperAdmin = props.user.roleSystemLevelId == SystemLevel.SuperAdmin
    // const thBgColor = '#36a2eb'
    const iconSize = 20
    const blockHeight = 85
    const [isLoading,setIsloading] = useState(true)
    const [maxHeight,setMaxHeight] = useState(200)
    const [projectPhases,setProjectPhases] = useState<ProjectPhase[]>(props.projectPhases)
    const [projectDeliverables,setProjectdeliverables] = useState<{[key:string]:ProjectDeliverable[]}>(props.projectDeliverables)
    const [phaseStatus,setPhaseStatus] = useState<{[key:number]:{waivePhase:boolean,waivePhaseReason:string} }>({})

    useEffect(() => {
        let status = {}
        props.projectPhases.forEach((item) => {
            status[item.phaseId] = { 
                waivePhase: item.waivePhase == 1 ? true : false,
                waivePhaseReason: item.waivePhaseReason
            }
        })
       // let max = 200;
       // let h = blockHeight + 5
        let maxLenght = 0;
        for (const [key, value] of Object.entries(projectDeliverables)) {
            if(value.length > 0 && !status[value[0].phaseId].waivePhase){
                if(value.length > maxLenght){
                    maxLenght= value.length
                }

                // let maxHeight = ((value.length + 1) * h)
                // if(max < maxHeight){
                //     max = maxHeight + 20
                // }
            }
           
        }
        let page = Math.ceil(maxLenght/9)
        let hight = page ==1 ? 850 : 980
        let max = page * hight
        
         //console.log(status)
        setMaxHeight(max)
        setPhaseStatus(status)
        setIsloading(false)
       
    
    },[])

    return (
     <>
        <div id="prep-status-section" className='inform-section' style={{ minHeight:'270mm'}} >
                <div id="prep-status-label" className='row' style={{ marginBottom: '15px' }} >
                    <div  className='col-md-12'>
                        <div className='project-label' style={{ padding: '0',marginLeft: '0'}} >
                            <span>
                                PREP Stage Gate Status
                            </span>
                        </div>
                    </div>
                </div>
                
                <div className='row' style={{ marginBottom: "10px" }} >
                    <div id="prep-status-icon" className='col-md-12' style={{ display:'flex',justifyContent: 'end'}} >
                        <div>
                            <AiFillCheckCircle size={20} color={Color.Green} style={{ marginRight: '5px'}} />
                            <span id="prep-status-icon-pass" className='prep-status-icon-span' style={{fontSize:'11px'}} >Pass</span>
                        </div>
                        <div style={{ marginLeft: '10px' }} >
                            <AiOutlineTrademarkCircle size={20} color={Color.Green} style={{ marginRight: '5px' }}  />
                            <span id="prep-status-icon-recycle" className='prep-status-icon-span' style={{fontSize:'11px'}} >Recycle</span>
                        </div>
                        <div style={{ marginLeft: '10px' }} >
                            <AiOutlinePauseCircle size={20} color={Color.Orange} style={{ marginRight: '5px' }}  />
                            <span id="prep-status-icon-hold" className='prep-status-icon-span' style={{fontSize:'11px'}} >Hold</span>
                        </div>
                        <div style={{ marginLeft: '10px' }} >
                            <AiFillCloseCircle size={20} color={Color.Red} style={{ marginRight: '5px' }}  />
                            <span id="prep-status-icon-stop" className='prep-status-icon-span' style={{fontSize:'11px'}} >Stop</span>
                        </div>
                        <div style={{ marginLeft: '10px'}} >
                            <AiOutlineClockCircle size={20} color={Color.Yellow} style={{ marginRight: '5px'}} />
                            <span id="prep-status-icon-in-progress" className='prep-status-icon-span'  style={{fontSize:'11px'}} >In Progress</span>
                        </div>
                        <div style={{ marginLeft: '10px',wordBreak:'break-word'}} >
                            <AiOutlineClockCircle size={20} color={Color.Grey} style={{ marginRight: '5px'}} />
                            <span id="prep-status-icon-wait-for-start" className='prep-status-icon-span'  style={{fontSize:'11px'}} >Waiting for Start</span>
                        </div>
                        <div style={{ marginLeft: '10px'}} >
                            <VscCircleSlash size={19} color='black' style={{ marginRight: '5px'}} />
                            <span id="prep-status-icon-waive" className='prep-status-icon-span'  style={{fontSize:'11px'}} >Waive</span>
                        </div>
                        <div style={{ marginLeft: '10px'}} >
                            <VscDebugStepOver size={20} color='Grey' style={{ marginRight: '5px'}} />
                            <span id="prep-status-icon-waive-phase" className='prep-status-icon-span'  style={{fontSize:'11px'}} >Waive Phase</span>
                        </div>

                    </div>
                </div>
                <div style={{ height: maxHeight.toString() + 'px'}} >
                { !isLoading ?
                    <table key={uniqid()} className="table portfolio-table3">
                        <thead>
                            <tr style={{ height: '40px' }} >
                            { props.phases.map((phase) => (
                                <>
                                { projectPhases.filter(x=>x.phaseId === phase.phaseId && x.completeDateTime).length > 0 || phaseStatus[phase.phaseId].waivePhase ?
                                    <th key={uniqid()} style={{ backgroundColor : Color.Green ,width:'135px'}} >
                                        <div key={uniqid()} style={{color:'black'}} >{phase.phaseName}</div>
                                            <div key={uniqid()} style={{position:'relative',  zIndex: '3',color:'black',marginBottom:'10px'}} >Completed</div>
                                        
                                        <div key={uniqid()} className="block-down" >
                                            <div key={uniqid()}  style={{ backgroundColor : Color.Green }} >&nbsp;</div>
                                        </div>
                                    </th>
                                    :
                                    <th key={uniqid()} style={{ backgroundColor : projectDeliverables[phase.phaseId].length === 0 ? '#bbb' :'' ,width:'135px' }} >
                                        <span key={uniqid()} style={{position:'relative',zIndex: 3}} >{phase.phaseName}</span>
                                        <div key={uniqid()} style={{position:'relative',  zIndex: '3',color:'black',marginBottom:'10px'}} >&nbsp;</div>
                                        <div key={uniqid()} className="block-down" >
                                            <div key={uniqid()} style={{ backgroundColor : projectDeliverables[phase.phaseId].length === 0 ? '#bbb' :'' ,zIndex: 1}} >&nbsp;</div>
                                        </div>
                                    </th>
                                }
                                </>
                                ))
                            }
                            </tr>
                        </thead>
                        <tbody key={uniqid()} >
                            <tr key={uniqid()}  style={{ borderBottom: "0px none" }}  >
                                <td style={{ borderBottom: "0px none" }} ></td>
                                <td style={{ borderBottom: "0px none" }} ></td>
                                <td style={{ borderBottom: "0px none" }} ></td>
                                <td style={{ borderBottom: "0px none" }} ></td>
                                <td style={{ borderBottom: "0px none" }} ></td>
                            </tr>
                        { props.phases.length > 0 ?
                            <tr key={uniqid()} >
                            { props.phases.map((phase,phaseIndex) => (
                                <td key={uniqid()} style={{ textAlign: 'center' }} >
                                    <div key={uniqid()} style={{ maxWidth: "200px",margin:'auto'}} >
                                    { phaseStatus[phase.phaseId].waivePhase ?
                                    <>
                                        <div key={uniqid()}> 
                                        <VscDebugStepOver key={uniqid()} size={iconSize} color='Grey' style={{ marginRight: '5px'}} />
                                            <span className="portfolio-stage" style={{border:'3px solid #9999', backgroundColor:'rgb(187, 187, 187)',height: blockHeight + 'px'}} >
                                                <span style={{padding: 0,marginLeft:'-8px',wordBreak : 'break-all'}} >
                                                   {  phaseStatus[phase.phaseId].waivePhaseReason.length < 25 ?
                                                        phaseStatus[phase.phaseId].waivePhaseReason 
                                                    :
                                                    <>
                                                        <span style={{ cursor: 'pointer' }} >
                                                            { phaseStatus[phase.phaseId].waivePhaseReason.substring(0,25) + '...' }
                                            
                                                        </span>
                                                        
                                                    </>
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </>
                                    :
                                    <>
                                    { projectDeliverables[phase.phaseId].map((item,index) => (
                                        <>
                                            { index > 0 && index % 9 == 0 ? <div className="pagebreak" style={{ height: '1px'}} ></div> : null }
                                            <div key={uniqid()} style={{ cursor: (isSuperAdmin && props.isUpdate) || ((isCanDeliverableUpdate || isCanAssuranceActivityUpdate) && (props.isUpdate && props.projectRole)) ? 'pointer' : 'default' }}  >
                                            { item.deliverableStatusId === StatusDeliverable.Pass ? <AiFillCheckCircle key={uniqid()}  size={iconSize} color={Color.Green} style={{ marginRight: '5px'}} /> : null }
                                            { item.deliverableStatusId === StatusDeliverable.Recycle ? <AiOutlineTrademarkCircle key={uniqid()}  size={iconSize} color={Color.Green} style={{ marginRight: '5px' }}  />: null }
                                            { item.deliverableStatusId === StatusDeliverable.Hold ? <AiOutlinePauseCircle key={uniqid()}  size={iconSize} color={Color.Orange} style={{ marginRight: '5px' }}  />: null }
                                            { item.deliverableStatusId === StatusDeliverable.Stop ? <AiFillCloseCircle key={uniqid()} size={iconSize} color={Color.Red} style={{ marginRight: '5px' }}  />: null }
                                            { item.deliverableStatusId === StatusDeliverable.InProgress ? <AiOutlineClockCircle key={uniqid()}  size={iconSize} color={Color.Yellow} style={{ marginRight: '5px'}} />: null }
                                            { item.deliverableStatusId === StatusDeliverable.WaitingForStart ? <AiOutlineClockCircle key={uniqid()}  size={iconSize} color={Color.Grey} style={{ marginRight: '5px'}} />: null }
                                            { item.deliverableStatusId === StatusDeliverable.Waive ? <VscCircleSlash key={uniqid()}  size={iconSize} color='black' style={{ marginRight: '5px'}} />: null }
                                            { item.deliverableStatusId === StatusDeliverable.WaivePhase ? <VscDebugStepOver key={uniqid()}  size={iconSize} color='Grey' style={{ marginRight: '5px'}} />: null }
                                            
                                                <span key={uniqid()} className="portfolio-stage" style={{ border: '3px solid ' + item.color ,height: blockHeight + 'px'}} >
                                                    <span id={'stage-code-' + phaseIndex + '-' + index} className='prep-status-box prep-stage-code'>{ item.stageCode }</span>
                                                    <span className='prep-status-box'>{ item.assuranceActivityName ? '(' + item.assuranceActivityName + ')' : ""} </span>
                                                {  item.deliverableStatusId !== StatusDeliverable.InProgress && item.deliverableStatusId !== StatusDeliverable.WaitingForStart ?
                                                    <span className='prep-status-box' style={{ fontSize:'10px'}} >{ item.completeDateTime ? DateHelper.getDate(item.completeDateTime) : DateHelper.getDate(item.updateDateTime)}</span>
                                                    :null
                                                }   
                                                </span>
                                            
                                            </div>
                                        </>
                                        ))
                                    }
                                    </>
                                    }
                                    </div>
                                </td>
                            ))
                            }
                            </tr>
                            :
                            <tr key={uniqid()}>
                                <td key={uniqid()} style={{ textAlign: 'center' }} >
                                    <div key={uniqid()} style={{ maxWidth: "200px",margin:'auto' }} ></div>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    :<Loading text="Loading..."/>
                }
                </div>

            </div>
        </>
    );
}

export default PrepStatusPdf;