import axios from 'axios'
import qs from 'qs'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { User,AuthenUser } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class UserService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }

    async getAll(page: number,limit: number,key: string,sortBy:string,sortType:number,active:number,roleId:number ){
        if(!this.token) return null

        let options = {
                headers: this.headers,
                params: {
                    page: page,
                    limit: limit,
                    key: encodeURI(key),
                    sortBy:sortBy,
                    sortType:sortType,
                    active:active,
                    roleId:roleId
                }
            }
        
        const { data }  = await axios.get<PageDataResponse<User>>(`${apiUrl}/v1/users/call`, options )
        
        return data;
    }

    async getById(id: number){
        if(!this.token) return null
        
        let options = {
                headers: this.headers
            }
        
        const { data }  = await axios.get<DataObjectResponse<User>>(`${apiUrl}/v1/users/${id}`, options )
        
        return data;
    }

    async getByUsername(username:string,signal:any = null){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    username:  encodeURI(username)
                },
                signal:signal
            }
        
        const { data }  = await axios.get<DataObjectResponse<AuthenUser>>(`${apiUrl}/v1/users/user`, options )
        
        return data;
    }

    async getStorage(username:string){
        if(!this.token) return null

        let options = {
                headers:this.headers,
                params: {
                    username: username
                }
            }
        
        const { data }  = await axios.get<DataObjectResponse<AuthenUser>>(`${apiUrl}/v1/users/user`, options )
        let result = data
        let user:AuthenUser| null = null

        if(result.success){
            if(result && result.success){
                if( result.data){
                    user = result.data
                    localStorage.setItem('user', JSON.stringify(result.data))
                }
            }
        }
        return user
    }

    async Search(key:string){
        if(!this.token) return null

        let options = {
            headers: this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<User>>(`${apiUrl}/v1/users/search`,options )
        return data;
    }

    async Create(user: User){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/users/call`,JSON.stringify(user),options )
        return data;
    }

    async Update(user: User){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/users/call`,JSON.stringify(user),options )
        return data;
    }

    async UpdateStatus(userId: number,active:number,updateBy:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let sendData ={
            userId:userId,
            active:active,
            updateBy:updateBy
        }
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/users/status`,JSON.stringify(sendData),options )
        return data;
    }
}