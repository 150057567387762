import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { rigGreenIcon,rigGreyIcon,rigBlueIcon } from '../images';
import uniqid from 'uniqid';
import '../assets/css/overall.css'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AiOutlinePrinter} from "react-icons/ai";
import { PhaseName,CategorySize,ProjectModeNumber, Color } from '../enum'
import { ProjectService,PropertyService } from '../services'
import { useApiAuth } from '../hooks/useApiAuth';
import { ProjectLocation,ProjectSummary} from '../models'
import { ProjectMode } from '../models/Property'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Loading from './Loading';
import OverallTable from './OverallTable'
import { BsFillStopFill } from "react-icons/bs";
import { VscFilePdf } from "react-icons/vsc";
import { DateHelper} from '../helpers';

interface AppState {
  projectLocations:ProjectLocation[]
  projectSummaries:ProjectSummary[]
  projectModes:ProjectMode[]
  date:Date
  windowWidth:number
  isLoading:boolean
}

var map:google.maps.Map;
var markers:google.maps.Marker[] =[]
var groupMode = false
const center = { lat: 0.361475, lng: 23.113635 }

const OverAll = () => {
    const windowSize = 1189
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const [ appState ,setAppState ] = useState<AppState>({
        projectLocations: [],
        projectModes: [],
        projectSummaries: [],
        date:new Date(),
        windowWidth: 0,
        isLoading: true
    })
  
    useEffect(() => {
      if(accessToken){
          
          Promise.all([
            new ProjectService(accessToken).getLocation(),
            new ProjectService(accessToken).getSummaryProject(),
            new ProjectService(accessToken).getSummaryPhase(),
            new PropertyService(accessToken).getProperties(['projectMode'])
          ]).then((result) => {
           
            let state = Object.assign({},appState)
            
            if(result[0] && result[0].success){
              state.projectLocations =  result[0].data
            }

            if(result[1] && result[1].success){
              state.projectSummaries = result[1].data
              state.date = new Date(result[1].date)
            }

            if(result[3] && result[3].success){
              state.projectModes = result[3].data.projectModes
            }

            state.isLoading = false
            state.windowWidth = window.innerWidth
            setAppState(state)

            if(state.projectLocations.length > 0){
                initMap(state.projectLocations)
            }

            if(result[2] && result[2].success){
              let [epLargeData,epMediumData,beLargeData,beMediumData,decommData] = result[2].data

              if( epLargeData.reduce((a, b) => a + b, 0) > 0){
                  createDonutChart('ep-large-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Large, epLargeData, state.projectModes)
              }
              else{
                  createEmptyDonutChart('ep-large-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Large,state.projectModes)
              }

              if( epMediumData.reduce((a, b) => a + b, 0) > 0){
                  createDonutChart('ep-medium-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Medium, epMediumData, state.projectModes)
              }
              else{
                  createEmptyDonutChart('ep-medium-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Medium,state.projectModes)
              }

              if( beLargeData.reduce((a, b) => a + b, 0) > 0){
                createDonutChart('be-large-donut-chart',ProjectModeNumber.Beyond,CategorySize.Large, beLargeData ,state.projectModes)
              }
              else{
                createEmptyDonutChart('be-large-donut-chart',ProjectModeNumber.Beyond,CategorySize.Large,state.projectModes)
              }

              if( beMediumData.reduce((a, b) => a + b, 0) > 0){
                createDonutChart('be-medium-donut-chart',ProjectModeNumber.Beyond,CategorySize.Medium, beMediumData ,state.projectModes)
              }
              else{
                createEmptyDonutChart('be-medium-donut-chart',ProjectModeNumber.Beyond,CategorySize.Medium,state.projectModes)
              }

              if( decommData.reduce((a, b) => a + b, 0) > 0){
                createDonutChart('decomm-donut-chart',ProjectModeNumber.Decomm,0, decommData ,state.projectModes)
              }
              else{
                createEmptyDonutChart('decomm-donut-chart',ProjectModeNumber.Decomm,0,state.projectModes)
              }
            }

          })

        var windowResize = () => {
          
          if(window.innerWidth < windowSize){
            let overallMap = document.getElementById('overall-map') as HTMLDivElement
            if(overallMap){
              overallMap.classList.remove('col-md-7')
              overallMap.classList.add('col-md-12')
            }
           
            let overallTable = document.getElementById('overall-table') as HTMLDivElement
            if(overallTable){
              overallTable.style.marginTop = '45px'
              overallTable.classList.remove('col-md-5')
              overallTable.classList.add('col-md-12')
            }
            
          }
          else{
            let overallMap = document.getElementById('overall-map') as HTMLDivElement
            if(overallMap){
              overallMap.classList.remove('col-md-12')
              overallMap.classList.add('col-md-7')
            }
          
            //overallMap.style.width = '58.3333333333%'

            let overallTable = document.getElementById('overall-table') as HTMLDivElement
            if(overallTable){
              overallTable.style.marginTop = '0'
              overallTable.classList.remove('col-md-12')
              overallTable.classList.add('col-md-5')
            }
            
            let decomText = document.getElementById('decomm-text') as HTMLSpanElement
            if(decomText){
              decomText.innerHTML = "Decomm."
            }
          
          } 
        }  

        window.addEventListener('resize', function() {
          windowResize()
          
        });

        
      }
    },[accessToken])

    const containerStyle = {
      width: '100%',
      height: '100%'
    };
    
    const divStyle = {
      background: 'white',
      border: '1px solid #ccc',
      with: '200px',
      padding: 5
    }

    const trStyles = {
      odd: {
          background: '#ffffff',
          height: '50px'
      },
      event: {
          background: '#ffffff',
          height: '50px'
      }
  }

  
    /* Google Map Function */
    const initMap = (projectLocations: ProjectLocation[], zoom: number = 2, lat: number = center.lat, lng: number = center.lng, type: string | undefined = 'roadmap', default_display: boolean | undefined = true) => {
     
      map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: {lat:lat,lng:lng},
        zoom: zoom,
        mapTypeId: type
      });

      let mapInput = document.getElementById("pac-input") as HTMLInputElement;
      let mapSearchBox = new google.maps.places.SearchBox(mapInput);

      //map.controls[google.maps.ControlPosition.TOP_CENTER].push(mapInput);
      map.addListener("bounds_changed", () => {
          mapSearchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
      });
      
      markers = []
      var bounds = new google.maps.LatLngBounds();
      projectLocations.forEach((item) => {
          let rigIcon ="";
          if(item.projectModeId === 1){
              rigIcon = rigBlueIcon;
          }
          else if(item.projectModeId === 2){
              rigIcon = rigGreenIcon;
          }
          else{
              rigIcon = rigGreyIcon;
          }

          let projectName = (item.projectName.length <= 15 ) ? item.projectName : item.projectName.substring(0,15) + '...'
          let marker = new google.maps.Marker({
            position: { lat:  item.lat, lng: item.lng },
            map: map,
            icon: rigIcon,
            label: {
              text:  projectName,
              color:'white',
              className:"marker-label",
              fontSize:'11px',
              fontFamily:'tahoma'
            }
          });

          marker.addListener("click", () => {
            window.location.href = '/project-view?pid=' + item.projectId
          }); 
          
          markers.push(marker)
          bounds.extend( { lat:  item.lat, lng: item.lng });
      })
        if (default_display)
      map.fitBounds(bounds);

      if(groupMode){
        new MarkerClusterer({ markers, map });
      }
      
     
      //google.maps.event.addListener(map, "click",onMarkerMove)
      //marker.addListener('dragend', onMarkerDragEnd);
      
      mapSearchBox.addListener("places_changed", () => {
          const places = mapSearchBox.getPlaces();
          if(places){
            if (places.length == 0) {
              return;
            }

            const bounds = new google.maps.LatLngBounds();
      
            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
              }
              
             
              
              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          }
        
      
         
      });
      
     
    }

  
  /*
  const selectedRequestBy: IPickerItem<IEmployee> | null = searchValues.requestBy
      ? { key: searchValues.requestBy.id!, name: searchValues.requestBy.name!, data: searchValues.requestBy }
      : null;

*/
  /* End Google Map Function */
  
    
    const createDonutChart = (element:string,projectModeId:number,categorySizeId:number,data:number[],projectModes:ProjectMode[]) => {
      console.log(data)
      var title = ""
      var subTitle = ""
      if(categorySizeId === 0){
        if(projectModeId == 3){
          title = projectModes[2].projectModeName
        }
      }

      if(categorySizeId === 1){
          subTitle = "Large"
          if(projectModeId === 1){
            title = projectModes[0].projectModeName
          }
          if(projectModeId === 2){
            title = projectModes[1].projectModeName
          }
         
      }

      if(categorySizeId === 2){
        subTitle = "Medium"
      }

      if(categorySizeId === 3){
        subTitle = "Decomm"
      }

      var backgroundColor = [
        Color.Red,
        Color.DarkBlue,
        Color.Green,
        Color.Blue,
        Color.Grey
      ]

      if(title === 'Decommissioning'){
        backgroundColor[4] = Color.LightGrey
      }
      
      const centerText = {
        id:'centerText',
        afterDatasetsDraw(chart,args,pluginOption){
          const { ctx } = chart;
          ctx.save()
          const x = chart.getDatasetMeta(0).data[0].x
          const y = chart.getDatasetMeta(0).data[0].y
          let total = data.reduce((a, b) => a + b, 0)
          let totalX = x
          let totalY = y
          let length = total.toString().length
          if(window.innerWidth > 574){
            if(length === 1){
              totalX = x - 5
            }
            if(length === 2){
              totalX = x - 8
            }
            if(length === 3){
              totalX = x - 15
            }
            if(length === 4){
              totalX = x - 20
            }

            ctx.font = 'bold 15px Tahoma'
            ctx.fillText(total,totalX, totalY)

            if(subTitle == 'Large'){
              ctx.font = 'bold 9px Tahoma'
              ctx.fillText('Large Project',x - 30  ,y + 14)
            }
            if(subTitle == 'Medium'){
              ctx.font = 'bold 9px Tahoma'
              ctx.fillText('Medium Project',x - 35  ,y + 14)
            }
          }
          else{
            if(length === 1){
              totalX = x - 3
              totalY = y + 5
            }
            if(length === 2){
              totalX = x - 7
              totalY = y + 5
            }
            if(length === 3){
              totalX = x - 10
              totalY = y + 5
            }
            if(length === 4){
              totalX = x - 12
              totalY = y + 5
            }
            ctx.fillStyle = "grey";
            ctx.font = 'bold 10px Tahoma'
            ctx.fillText(total,totalX, totalY )

            if(subTitle == 'Large'){
              ctx.font = 'bold 9px Tahoma'
              ctx.fillText('Large Project',x-30 ,y + 65)
            }
            if(subTitle == 'Medium'){
             
              ctx.font = 'bold 9px Tahoma'
              ctx.fillText('Medium Project',x-40 ,y + 65)
            }
          }
        }
      }

      const canvasElement = document.getElementById(element)  as HTMLCanvasElement
      
      var myChart = new Chart(
        canvasElement,
        {
          type: 'doughnut',
          data:{
            labels: [],
            datasets: [{
              //label: 'Asset Type vs Phase vs Active Project',
             // title: "",
              data:  data ,
              backgroundColor: backgroundColor ,
              hoverOffset: 4
            }]
        },
        plugins: [ChartDataLabels,centerText],
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
              font:{
                size: 14
              }
            },
            tooltip: {
              callbacks: {
                footer: footer,
              }
            },
            datalabels: {
              color: 'white',
              font: {
                weight: 'bold'
              },
              padding: 6,
              formatter: function(value) {
                if(value >0 ){
                    return value;
                }else{
                    value = "";
                    return value;
                }
              }
            },
            legend: {
              position: 'chartArea',
              labels: {
                  font: {
                      size: 14
                  }
              },
              onClick: newLegendClickHandler
            },
            // subtitle: {
            //   display: true,
            //   text: subTitle,
            //   //color: '#00338D',
            //   font: {
            //     size: 14,
            //     family: 'tahoma',
            //     //weight: 'bold',
            //     //style: 'italic'
            //   },
            //   padding: {
            //     bottom: 10
            //   }
            // }
          },
          responsive: true,
          maintainAspectRatio: false,
            onHover: function (event, chartElement) {
                event.native.target.style.cursor = chartElement.length == 1 ? 'pointer' : 'default';
            },
        }
      })
      
      function clickHandler(evt) {
       
        const points = myChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

        if (points.length) {
            const firstPoint = points[0];
            window.location.href = '/dashboard?projectMode=' + projectModeId + '&categorySize=' + categorySizeId + '&phase=' + (firstPoint.index + 1) + '&projectStatus=1'
           // console.log(firstPoint);
            /*s
            const label = myChart.data.labels[firstPoint.index];
            const value = myChart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
            */
           //console.log( firstPoint)
        }
      }
      canvasElement.onclick = clickHandler

    }

    const createEmptyDonutChart = (element:string, projectModeId:number, categorySizeId:number, projectModes:ProjectMode[]) => {
      var title = ""
      var subTitle = ""
      if(categorySizeId === 0){
        if(projectModeId == 3){
          title = projectModes[2].projectModeName
        }
      }

      if(categorySizeId === 1){
          subTitle = "Large"
          if(projectModeId === 1){
            title = projectModes[0].projectModeName
          }
          if(projectModeId === 2){
            title = projectModes[1].projectModeName
          }
         
      }

      if(categorySizeId === 2){
        subTitle = "Medium"
      }

      
      const canvasElement = document.getElementById(element)  as HTMLCanvasElement
      
      new Chart(
        canvasElement,
        {
          type: 'doughnut',
          data:{
            labels:  [],
            datasets: [{
              data:  [1,0,0,0,,0] ,
              backgroundColor: "rgb(187, 187, 187)" ,
              hoverOffset: 4
            }]
        },
        //plugins: [centerText],
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
              font:{
                size: 14
              }
            },
            tooltip: {enabled: false},
            // subtitle: {
            //   display: true,
            //   text: subTitle,
            //   font: {
            //     size: 14,
            //     family: 'tahoma',
            //   },
            //   padding: {
            //     bottom: 10
            //   }
            // }
          },
          responsive: true,
          maintainAspectRatio: false,
          
        }
    })
     
    }

  
    const footer = (items) => {
      //console.log(items)
      let index = items[0]['dataIndex']
      let title = items[0].dataset.title
      if(index === 0) {
        return PhaseName.Phase1
      }
      if(index === 1) {
        return PhaseName.Phase2
      }
      if(index === 2) {
        return PhaseName.Phase3
      }
      if(index === 3) {
        return PhaseName.Phase4
      }
      if(index === 4) {
        if(title !== 'Decommissioning'){
          return PhaseName.Phase5
        }
        else{
          return PhaseName.Phase52
        }
         
      }
       
    };

    const onGroupModeChange = () =>{
      groupMode = !groupMode
      let mapModeSpan = document.getElementById('map-mode') as HTMLSpanElement
      mapModeSpan.innerHTML = groupMode ? 'Group View' : 'Single View'
      
      let length = markers.length
      for(let i = 0; i < length ; i++){
        markers[i].setMap(null)
      }
      let zoom = map.getZoom()
      let pos = map.getCenter()
      let lat = pos ? pos.lat() : center.lat
      let lng = pos ? pos.lng() : center.lng
      let type =  map.getMapTypeId() ? map.getMapTypeId() : 'roadmap'
      initMap(appState.projectLocations,zoom,lat,lng,type,false)
    }
    const newLegendClickHandler = () => {
      return false
    }

    const print = () => {
      let mode = groupMode ? 1 : 0
      let zoom = map.getZoom()
      let pos = map.getCenter()
      let lat = pos ? pos.lat() : center.lat
      let lng = pos ? pos.lng() : center.lng
      let type =  map.getMapTypeId() ? map.getMapTypeId() : 'roadmap'
      let url = '/overallpdf?groupmode=' + mode + '&zoom=' + zoom + '&lat=' + lat + '&lng=' + lng + '&type=' + type
      window.open(url,'overallprint','directories=no,titlebar=no,toolbar=no,location=no,status=yes,menubar=no,scrollbars=no,resizable=no')
    }


     return (
      <div className="container-fluid">
        
        <nav className="nav-menu" aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-bottom">
              
                <li className="breadcrumb-item" aria-current="page">
                    Main Dashboard
                </li>
               
            </ol>
            
        </nav>
  
        <div style={{ "padding" :"8px 15px"}} >
            <div className="body" style={{ margin: 'auto'}} >
              <div className="tabs" style={{ paddingTop:'5px'}} >
                  <input type="radio" id="tab1" name="tab-control" defaultChecked />
                  <div className="content" style={{ marginTop: 0}} >
                  <section>
                
                    <div id="overall-top" className='row' style={{ marginBottom: '0',textAlign:'right',marginRight:'-25px'}} >
                      <div className='col-md-12'>
                        {/* <button type="button" className="btn btn-sm bg-dark-blue" style={{ height: 'auto'}}  onClick={print}>
                            <VscFilePdf size={20}/>
                            <span style={{ marginLeft: '5px'}} >Export PDF</span>
                        </button> */}
                      <div  className='col-md-12'>
                        <AiOutlinePrinter className='overall-printer'  size={30} color={Color.DarkBlue} onClick={print}/>
                      </div>
                      </div>
                    </div>
                { !appState.isLoading ?
                    <div className='row' >
                        <div id="overall-map" className={(appState.windowWidth > windowSize ? 'col-md-7' : 'col-md-12') + ' table-shadow-inside overall-left'} style={{ margin: 0}} >
                          <div className='overall-legend'>
                            <img src={rigBlueIcon}/>
                            <span>{ appState.projectModes[0].projectModeName }</span>
                            <img src={rigGreenIcon}/>
                            <span>{ appState.projectModes[1].projectModeName }</span>
                            <img src={rigGreyIcon}/>
                            <span>{ appState.projectModes[2].projectModeName }</span>
                          </div>
                          <input id="pac-input" className='controls' type="text" placeholder="Search Location"/>
                          <div id="map-switch" className="form-check-map form-switch map-switch">
                            <input className="form-check-input-map" type="checkbox" role="switch" id="flexSwitchCheckDefault"  onClick={() => onGroupModeChange()}/>
                            <span id="map-mode" className='map-mode' style={{ color:"#888888",fontWeight:"unset"}} >Single View</span>
                          </div>
                          <div id="map" style={{ width: '100%', height: '100%', margin: 0, padding: 0, minHeight:'500px' }} ></div>
                         
                      </div>
                      <div id="overall-table" className={(appState.windowWidth > windowSize ? 'col-md-5' : 'col-md-12') + ' overall-right'} style={{padding: '0',marginTop : appState.windowWidth < windowSize ? '45px' : 0}} >
                                         {/* <div className='row' style={{marginBottom: 0}} >
                                             <div className='col-md-12' style={{ textAlign:"center"}}  > */}
                                             
                                                <>
                                                  <div className='overall-topic-chart' >
                                                    <div>Project Summary</div>
                                                  </div>
                                                  <OverallTable 
                                                    projectSummaries={appState.projectSummaries} 
                                                    projectModes={appState.projectModes} 
                                                    date={appState.date}
                                                    noteTable='inline'
                                                  />
                                                </>
                                                
                                             {/* </div>
                                         </div> */}
                                         
                            <div id="overall-chart" className='overall-chart'>
                              <div className='overall-topic-chart' style={{ paddingTop: '5px',marginBottom: '5px'}} >
                                <div>Number of Active Project by Phase</div>
                                
                              </div>
                              <div className='row' style={{ marginBottom: 0 }} >
                                  <div className='col-md-4 canvas-chart-div' style={{width: '33.3333333333%',  borderRight: '1px solid #ddd'}} >

                                    <canvas key={uniqid()} id="ep-large-donut-chart" className='canvas-chart'></canvas>
                                    {/* <div className="donut-center-text">Large<br/>Project</div> */}
                                  </div>
                                  <div className='col-md-4 canvas-chart-div' style={{width: '33.3333333333%', borderRight: '1px solid #ddd'}} >
                                    <canvas key={uniqid()} id="be-large-donut-chart" className='canvas-chart'></canvas>
                                    {/* <div className="donut-center-text">Large<br/>Project</div> */}
                                    
                                  </div>
                                  <div className='col-md-4 canvas-chart-div' style={{width: '33.3333333333%'}} >
                                    <canvas key={uniqid()} id="decomm-donut-chart" className='canvas-chart'></canvas>
                                  </div>
                              </div>
                              <div className='row'>
                                  <div className='col-md-4 canvas-chart-div' style={{width: '33.3333333333%',borderRight: '1px solid #ddd'}} >
                                    <canvas key={uniqid()} id="ep-medium-donut-chart" className='canvas-chart'></canvas>
                                    {/* <div className="donut-center-text">Medium<br/>Project</div> */}
                          
                                  </div>
                                  <div className='col-md-4 canvas-chart-div' style={{width: '33.3333333333%',borderRight: '1px solid #ddd'}} >
                                  <canvas key={uniqid()} id="be-medium-donut-chart" className='canvas-chart'></canvas>
                                  {/* <div className="donut-center-text">Medium<br/>Project</div> */}
                                
                                  </div>
                                  <div className='col-md-4 canvas-chart-div' style={{width: '33.3333333333%',paddingTop: '60px',textAlign: 'left',fontSize:'13px'}} >
                                    {/* <img src={phaseImage} style={{ width: '170px',border: 'none'}} /> */}
                                    <div className='row'>
                                      <div className='col-md-2'></div>
                                      <div className='col-md-10'>
                                      <div>
                                        <BsFillStopFill color={Color.Red} size={20} />
                                        <span style={{ color:'grey'}}  className='chart-phase-text'>Phase1-Assess </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.DarkBlue} size={20} />
                                        <span style={{ color:'grey'}}  className='chart-phase-text'>Phase2-Select </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.Green} size={20} />
                                        <span style={{ color:'grey'}}  className='chart-phase-text'>Phase3-Define </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.Blue} size={20} />
                                        <span style={{ color:'grey'}}  className='chart-phase-text'>Phase4-Execute </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.Grey} size={20} />
                                        <span style={{ color:'grey'}}  className='chart-phase-text'>Phase5-Operate </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.LightGrey} size={20} />
                                        <span style={{ color:'grey'}}  className='chart-phase-text'>Phase5-Post Decomm. </span>
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                               
                              </div>
                              <div className='row' style={{ marginTop: '15px'}} >
                                <div className='col-md-12' style={{textAlign:'right'}} >
                                <div id="note-chart" className='note-chart'><b>Note:</b> Click on each summary number to see project information.</div>
                                <div className='note-chart' style={{ marginRight:'5px',marginTop:'0'}} ><b>Medium:</b> { '0-200 MMUSD'} <b>Large:</b>{'> 200 MMUSD'}</div>
                                </div>
                              </div>
                            </div>
                                                                     
                        
                    </div>


                    </div>:<Loading text="Loading..."/>
                }
              
              <div className="divFooter">
          <div>{account?.name}</div>
          <div style={{float: 'right'}} >{DateHelper.getDateTime(new Date())}</div>
        </div>
                </section>
              </div>
              </div>
            </div>
        </div>
        
      </div>
 
    );

}

export default OverAll