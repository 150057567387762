import axios from 'axios'
import qs from 'qs'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { ProjectType,DeleteData}  from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ProjectTypeService
{
    protected headers = {}
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    
    async getAll(page: number,limit: number,key:string ){
        let options = {
                headers: this.headers,
                params: {
                    page: page,
                    limit: limit,
                    key: key
                }
            }
        
        const { data }  = await axios.get<PageDataResponse<ProjectType>>(`${apiUrl}/v1/projecttypes/call`, options )
        
        return data;
    }

    async getById(id: number){
        let options = {
                headers:this.headers,
            }
        
        const { data }  = await axios.get<DataObjectResponse<ProjectType>>(`${apiUrl}/v1/projecttypes/call/${id}`, options )
        
        return data;
    }

    async search(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<ProjectType>>(`${apiUrl}/v1/projecttypes/search`,options )
        return data;
    }

    async create(projectType: ProjectType){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/projecttypes/call`,JSON.stringify(projectType),options )
        return data;
    }

    async update(projectType: ProjectType){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projecttypes/call`,JSON.stringify(projectType),options )
        return data;
    }

    async updateStatus(projectType: ProjectType){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projecttypes/status`,JSON.stringify(projectType),options )
        return data;
    }

    async delete(id: number,updateBy: string){
        if(!this.token) return null
        
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v1/projecttypes/delete`,JSON.stringify(deleteData),options )
        return data;
    }

}