import React, { useState, useEffect } from "react";
import { AiOutlineFile } from "react-icons/ai";
import { BsChevronLeft, BsChevronBarLeft, BsChevronRight, BsChevronBarRight } from "react-icons/bs";
import { ProjectProgress } from '../../models'
import {  FileManageService} from '../../services'
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip } from 'react-tooltip'
import DatePicker from "react-datepicker";
import { DateHelper } from "../../helpers";
import { useApiAuth } from '../../hooks/useApiAuth';

interface AppState {
    index:number
    date: Date
    firstDate: string
    lastDate: string
    activity: string
    areaOfConcern: string
    attachedFile: string
}
interface Props{
    projectId:number
    progresses:ProjectProgress[]
}
const StatusActivityUpdate = (props:Props) => {
    const {account, accessToken } = useApiAuth();
    const progresses:ProjectProgress[] = Object.assign(props.progresses)
    const [appState,setAppState] = useState<AppState>()
    const [show,setShow] = useState(false)

    useEffect(() => {
        if(accessToken){
            if(progresses.length > 0){
                let lastIndex = progresses.length - 1
                let state:AppState = {
                    index:lastIndex,
                    date: new Date(progresses[lastIndex].progressDate),
                    firstDate: progresses[0].progressDate.toString(),
                    lastDate: progresses[lastIndex].progressDate.toString(),
                    activity: progresses[lastIndex].activity,
                    areaOfConcern: progresses[lastIndex].areaOfConcern,
                    attachedFile: progresses[lastIndex].attachedFile
               }
                setAppState(state)
               setShow(true)
            }
        }
       
       
    }, [accessToken])

    const onDateChange = (date) => {
        let index = progresses.findIndex(x => DateHelper.getYearMonth(x.progressDate) == DateHelper.getYearMonth(date))
        if(index > -1){
            getProgressData(index)
        }
    }

    const onDatePreviousChange = () => {
        if(appState){
            let index = appState.index - 1
            if(index >= 0 ){
                getProgressData(index)
            }
        }
        
    }

    const onDateNextChange = () => {
        if(appState){
            let index = appState.index + 1
            let length = progresses.length
            if(index < length ){
                getProgressData(index)
            }
        }
    }

    const onFirstDateClick = () => {
        getProgressData(0)
    }

    const onLastdateClick = () => {
        let index = progresses.length -1
        getProgressData(index)
    }

    const getProgressData = (index) => {
        let state = Object.assign({},appState)
        let progress = Object.assign({}, progresses[index])
           state.index = index
           state.date = new Date(progress.progressDate)
           state.activity = progress.activity
           state.areaOfConcern = progress.areaOfConcern
           state.attachedFile = progress.attachedFile
           setAppState(state)
    }
    
    const download = () => {
        let state = Object.assign({},appState)
        let progress:ProjectProgress = Object.assign({}, progresses[state.index])
        let path = "p" + props.projectId.toString() + "/prg" + progress.projectProgressId.toString()
        new FileManageService(accessToken).downloadFile(path,progress.attachedFile);
    }
    return (
        <div className="card">
       
            <div className="card-header bg-blue">
                Monthly Activity Update
            </div>
            <div className="card-body" style={{ minHeight: '100px'}} >
            { appState && show ?
            <>
                <div className='row' style={{ marginBottom:"0px" }}  >

                    <div className='col-md-12' style={{ textAlign: 'center' }} >
                        <h6 style={{ fontWeight: 'bold' }} >Month/Year</h6>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12' style={{ textAlign: 'center' }} >
                        <div className="status-activity-main-date">
                   
                            <a data-tooltip-id='start-date-info' style={{cursor: 'pointer'}}  onClick={onFirstDateClick}>
                                <BsChevronBarLeft cursor="pointer" fontSize={20} style={{ marginTop: '5px' }}  />                   
                            </a>
                            <Tooltip id='start-date-info' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span> { DateHelper.getMonthYear(appState?.firstDate) }</span>
                            </Tooltip>
                        
                            <BsChevronLeft cursor="pointer" fontSize={20} style={{ marginRight: '8px', marginTop: '5px' }}  onClick={onDatePreviousChange}/>
                                <div className="date-picker">
                                    <DatePicker
                                        dateFormat="MMM yyyy"
                                        showMonthYearPicker
                                        selected={ appState?.date } 
                                        onChange={ (date) => onDateChange(date) }  
                                        placeholderText="Please select" 
                                        className="form-control form-control-sm"
                                    />
                                </div>
                             <BsChevronRight cursor="pointer" fontSize={20} style={{ marginLeft: '8px', marginTop: '5px' }}  onClick={onDateNextChange}/>
                            <a data-tooltip-id='end-date-info' style={{cursor: 'pointer'}}  onClick={onLastdateClick}>
                                <BsChevronBarRight cursor="pointer" fontSize={20} style={{ marginTop: '5px' }}  />                  
                            </a>
                            <Tooltip id='end-date-info' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                <span>{ DateHelper.getMonthYear(appState?.lastDate)}</span>
                            </Tooltip>
                        
                        </div>
                    </div>
                </div>

                <div className='row' style={{ textAlign : "left"}}  >
                        <div className='col-md-6' style={{ paddingLeft: "25px" ,background:'rgb(250 250 253)',minHeight: '150px',borderRight:'4px solid white',borderRadius:'5px'}} >
                            <div className='font-blue font-bold' style={{ marginTop:'10px'}} >Activity</div>
                            <div>{appState?.activity}</div>
                        </div>
                        <div className='col-md-6' style={{ paddingLeft: "25px",background:'rgb(250 250 253)',minHeight: '150px',borderRadius:'5px' }} >
                            <div className='font-blue font-bold' style={{ marginTop:'10px'}} >Area Of Concern</div>
                            <div>{appState?.areaOfConcern}</div>
                        </div>
            
                </div>
                {/* <div className='row' style={{ textAlign: "left" }} >
                    <div className='col-md-6' style={{ paddingLeft: "70px", paddingRight: "70px"}}  >{appState?.activity}</div>
                    <div className='col-md-6' style={{ paddingLeft: "70px", paddingRight: "70px" }} >{appState?.areaOfConcern}</div>
            
                </div> */}
                <div className='row' style={{ textAlign: "right", marginTop: "5px", marginBottom: "0px", fontStyle: "italic", fontSize: "12px"}} >
                    <div className='col-md-12'>
                        { appState.attachedFile ?
                        <>
                            <span className='font-blue'>Attched File : </span>
                            <span onClick={() => download()} style={{ cursor: 'pointer'}} >
                                <AiOutlineFile size={18} />
                                <span>{appState.attachedFile}</span>
                             </span>
                        </>:null
                        }
                    </div>
                </div>
      
            </>:<span>No Data.</span>
            }
            </div>
    
        </div>
    )
}

export default StatusActivityUpdate