import axios from 'axios'
//import qs from 'qs'
import { PageDataResponse,PageResponse, DataArrayResponse } from '../models/PageResponse'
import { DeliverableTemplate,DeleteData  } from '../models'
import { config } from '../config'
import { CookieHelper,DateHelper } from '../helpers'

const apiUrl = config.API_URL
const version = 1

export default class DeliverableTemplateService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    async getAll(page: number,limit: number, projectModeId: number,phaseId: number, key: string = ""){
        let options = {
                headers: this.headers,
                params: {
                    page: page,
                    limit: limit,
                    projectModeId: projectModeId,
                    phaseId: phaseId,
                    key: encodeURI(key)
                }
            }
        //console.log()
        const { data }  = await axios.get<PageDataResponse<DeliverableTemplate>>(`${apiUrl}/v${version}/deliverableTemplates/call`, options )
        
        return data;
    }

    async getByProjectMode(projectModeId: number){
        let options = {
                headers: this.headers,
                params: {
                    projectModeId: projectModeId
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<DeliverableTemplate>>(`${apiUrl}/v${version}/deliverabletemplates/projectmode`, options )
        
        return data;
    }

    async getByPhase(projectModeId: number,phaseId: number){
        let options = {
                headers: this.headers,
                params: {
                    projectModeId: projectModeId,
                    phaseId: phaseId
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<DeliverableTemplate>>(`${apiUrl}/v${version}/deliverabletemplates/phase`, options )
        
        return data;
    }

    async Search(key:string,projectModeId:number,phaseId:number){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key),
                projectModeId:projectModeId,
                phaseId:phaseId
            }
        }
        const { data }   = await axios.get<DataArrayResponse<DeliverableTemplate>>(`${apiUrl}/v1/deliverabletemplates/search`,options )
        return data;
    }

    async exportExcel(projectModeName:string, phaseName:string,templateName:string,templateId:number)
    {
        var url = `${apiUrl}/v1/exportexcel/admin/template?projectModeName=` + encodeURI(projectModeName.replace('&','@$')) +  '&phaseName=' + encodeURI(phaseName) + '&templateName=' + encodeURI(templateName) + "&templateId=" + templateId
        var request = new XMLHttpRequest();
        request.onload = function(e) {
            if (this.status === 200) {
                if(this.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                    var blob = this.response;
                    
                        var downloadLink = window.document.createElement('a');
                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader? contentTypeHeader : "" }));
                        downloadLink.download = "deliverable_" + templateName + "_" + DateHelper.getDateFormat2() + ".xlsx";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    
                }
                
            }
            else{
                //console.log("File not found.")
              
            }
           
        };
        request.open('GET', url);
        request.setRequestHeader("Authorization", `Bearer ${this.token}`);
        request.responseType = 'blob';
        request.send();
    }

    async create(deliverableTemplate: DeliverableTemplate){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v${version}/deliverableTemplates/call`,JSON.stringify(deliverableTemplate),options )
        return data;
    }

    async update(deliverableTemplate: DeliverableTemplate){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v${version}/deliverableTemplates/call`,JSON.stringify(deliverableTemplate),options )
        return data;
    }

    async delete(id: number,updateBy: string){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v${version}/deliverableTemplates/delete`,JSON.stringify(deleteData),options )
        return data;
    }

}