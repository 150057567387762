import axios from 'axios'
import { DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { CategorySizeThreshold }  from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class CategorySizeService
{
    protected headers = {}
    protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    
    async getAll( ){
        let options = {
                headers: this.headers,
            }
        
        const { data }  = await axios.get<DataArrayResponse<CategorySizeThreshold>>(`${apiUrl}/v1/categorysizes/call`, options )
        
        return data;
    }

    async getById(id: number){
        let options = {
                headers:this.headers,
            }
        
        const { data }  = await axios.get<DataObjectResponse<CategorySizeThreshold>>(`${apiUrl}/v1/categorysizes/call/${id}`, options )
        
        return data;
    }

    async update(categorySize: CategorySizeThreshold){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/categorysizes/call`,JSON.stringify(categorySize),options )
        return data;
    }

}