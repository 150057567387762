import React, { useEffect, useState } from 'react';
import InformationPdf from './InformationPdf';
import { PropertyService,ProjectService,ProjectDeliverableService,
    ProjectPhaseService,ProjectPhaseRoleService,
    DeliverableTemplateService,
    ProjectProgressService
} from '../../services'
import { useApiAuth } from '../../hooks/useApiAuth';
import StatusViewPdf from './StatusViewPdf';
import Loading from '../Loading'
import { Property, Project,Phase,AuthenUser,RoleProjectLevel,
    ProjectDeliverable,ProjectPhase,ProjectPhaseRole,DeliverableTemplate,
    ProjectProgress} from '../../models'
import PrepStatusPdf from './PrepStatusPdf'
import { AiOutlineCloseSquare,AiOutlinePrinter } from "react-icons/ai";
import uniqid from 'uniqid';
import '../../assets/css/tab.css'
import { ProjectLevel } from '../../enum';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper} from '../../helpers';

interface AppState {
    property:Property| null
    project:Project| null
    phases:Phase[]
    projectRole:RoleProjectLevel | null
    projectdeliverables:{ [key:string]:ProjectDeliverable[] }
    projectPhases:ProjectPhase[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectProgresses:ProjectProgress[]
    deliverableTemplates:DeliverableTemplate[]
    currentPhaseId:number
    isTeamMember: boolean
    currentdate: Date
    isLoading:boolean
    isCanUpdate:boolean
    isUpdate:boolean
}
const propertyFlag =  ['projectMode','phase','projectType','developmentScenario','resourceType',
'asset','assetType','categorySize','country','productType','projectStatus','stage','roleProjectLevel','date'
]

const PrintPdf = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('pid')
    const projectId:number =  (id) ? parseInt(id) : 0
    const {account, accessToken } = useApiAuth();
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        property: null ,
        project: null ,
        phases:[],
        projectRole: null,
        projectdeliverables: {},
        projectPhases:[],
        projectPhaseRoles:[],
        projectProgresses:[],
        currentPhaseId: 0,
        isTeamMember: false,
        deliverableTemplates:[],
        currentdate: new Date(),
        isCanUpdate: false,
        isLoading:true,
        isUpdate: false 
    })
    
    useEffect(()=> {
       
        if(accessToken){
            Promise.all([
                new PropertyService(accessToken).getProperties(propertyFlag), 
                new ProjectService(accessToken).getById(projectId),
                new ProjectDeliverableService(accessToken).getByProjectId(projectId),
                new ProjectPhaseService(accessToken).getByProjectId(projectId),
                new ProjectPhaseRoleService(accessToken).getByProjectId(projectId),
                new ProjectProgressService(accessToken).getByProjectId(projectId),
            ]).then( async (result) => {
                    //console.log(result)
                    let [propertyResult,projectResult,projectDeliverableResult,projectPhaseResult,projectPhaseRoleResult,projectProgressResult] = result
                    let state = Object.assign({},appState)
                
                    if(propertyResult && propertyResult.success){
                        state.property = propertyResult.data
                    }

                    if(projectResult && projectResult.success){
                        let project = projectResult.data
                        project.registerDateTime = new Date(project.registerDateTime)
                        state.project = project
                        state.currentPhaseId = project.currentPhaseId
                        let deliverableTemplateResult = await new DeliverableTemplateService(accessToken).getByProjectMode(project.projectModeId)
                        if(deliverableTemplateResult && deliverableTemplateResult.success){
                            state.deliverableTemplates = deliverableTemplateResult.data
                        }
                        
                        if(state.property){
                            let projectRole = getProjectRole(project,state.property.roleProjectLevels)
                            state.projectRole = (projectRole) ? projectRole : null
                           
                        }   
                   
                    }
                    if(projectDeliverableResult && projectDeliverableResult.success){
                        state.projectdeliverables = projectDeliverableResult.data
                    }

                    if(projectPhaseResult && projectPhaseResult.success){
                        state.projectPhases = projectPhaseResult.data
                    }

                    if(projectPhaseRoleResult && projectPhaseRoleResult.success){
                        state.projectPhaseRoles = projectPhaseRoleResult.data
                    }

                    if(projectProgressResult && projectProgressResult.success){
                        state.projectProgresses = projectProgressResult.data
                    }

                    if(state.property && state.project){
                        state.phases = state.property.phases.filter( x => x.projectModeId == state.project?.projectModeId)
                    }

                    if(user.informationUpdate  || user.statusUpdate || user.deliverableUpdate || 
                        state.projectRole?.waiveUpdate || state.projectRole?.statusUpdate ||
                        state.projectRole?.deliverableUpdate || state.projectRole?.deliverableApprove)
                    {
                        state.isCanUpdate = true
                    }
                   
                    state.isLoading = false
                    setAppState(state)
                })
                
                const wrapper = document.getElementById("wrapper") as HTMLDivElement
                wrapper.style.paddingTop = '0'
                wrapper.style.paddingBottom = '0'
                const navbar = document.getElementById("navbar") as HTMLElement
                navbar.style.display = 'none'
                const footerPage = document.getElementById("footer-page") as HTMLElement
                footerPage.style.display = 'none'
               
        }
        
        var beforePrint = function() {
            let printer = document.getElementById('printer') as HTMLDivElement
            printer.style.display = 'none'
            let printerClose = document.getElementById('printer-close') as HTMLDivElement
            printerClose.style.display = 'none'
        
        };
      
        var afterPrint = function() {
            let printer = document.getElementById('printer') as HTMLDivElement
            printer.style.display = 'inline'
            let printerClose = document.getElementById('printer-close') as HTMLDivElement
            printerClose.style.display = 'inline'

            //window.location.reload()
        };
      
          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
      
            mediaQueryList.addListener(function (mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
          }

          window.resizeTo( window.screen.availWidth /1.5, window.screen.availHeight);
         
    },[accessToken])

    const getProjectRole = (project:Project,roleProjectLevels:RoleProjectLevel[]) => {
        if(project.focalPoints.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.FocalPoint)
        }
        if(project.projectManagers.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.ProjectManager)
        }
        if(project.projectOwners.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.ProjectOwner)
        }
        if(project.gateKeepers.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.GateKeeper)
        }
        if(project.teamMembers.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.TeamMember)
        }
        if(project.secretaries.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.Secretary)
        }
        return null;
    }
    

    const print = () => {
        window.print()
    }
    return (
        <div className="container-fluid">
            
            <div style={{ "padding":"5px 15px"}} >
                <div className="body">
                
                <div className="tabs">
                        <input type="radio" id="tab1" name="tab-control" defaultChecked />
                        <ul></ul>
  
                        <div className="content" style={{ marginBottom: '30px',marginTop: '0',minHeight:'200mm'}} >
                            <section id="view-data">
                               
                                {appState && appState.property && appState.project && !appState.isLoading ? 
                                    <>
                                        <div id="printer" className='row' style={{ minHeight:'35px',marginBottom:'15px'}} >
                                            <div className='col-md-12' style={{ textAlign: 'center'}} >
                                                <button   type="button" className="btn btn-sm btn-print"  onClick={() => print()}>
                                                    <AiOutlinePrinter size={30} />
                                                    <span style={{ marginLeft:'5px'}} >Print</span>
                                                </button>
                                                <AiOutlineCloseSquare id="printer-close"  size={30} color='red' style={{ float:'right',marginTop:'-25px',cursor:'pointer'}}  onClick={() => { window.close() }}/>
                                            </div>
                                        </div>
                                        <div id="information">
                                            <InformationPdf 
                                                key={uniqid()} 
                                                user={user} 
                                                projectData={appState.project} 
                                                projectRole={appState.projectRole}
                                                minimize={false}
                                                hiddenText={true}
                                            />
                                        </div>
                                        <div className="pagebreak"> </div>

                                        <div id="prep-status">
                                            <PrepStatusPdf  
                                                    key={uniqid()} 
                                                    isUpdate={appState.isUpdate} 
                                                    projectId={appState.project.projectId} 
                                                    projectName={appState.project.currentProjectName}
                                                    projectModeId={appState.project.projectModeId} 
                                                    phaseId={appState.project.currentPhaseId} 
                                                    phases={appState.phases} 
                                                    projectPhases={appState.projectPhases}
                                                    user={user} 
                                                    projectOwners={appState.project.projectOwners}
                                                    gatekeepers={appState.project.gateKeepers}
                                                    projectPhaseRoles={appState.projectPhaseRoles}
                                                    projectRole={appState.projectRole ? appState.projectRole : null}  
                                                    projectDeliverables={appState.projectdeliverables}
                                              />
                                        </div>
                                 
                                        <div className="status-view-pdf" style={{ display: 'inline'}} >
                                        { 
                                        <>
                                            {
                                            appState.projectPhases.map((projectPhase) => (
                                                <>
                                                    <div className="pagebreak"> </div>
                                                    <StatusViewPdf
                                                        key={uniqid()} 
                                                        projectId={appState.project.projectId} 
                                                        projectModeId={appState.project.projectModeId} 
                                                        phaseId={projectPhase.phaseId} 
                                                        phases={appState.phases} 
                                                        isTeamMember={appState.isTeamMember}
                                                        projectPhases={appState.projectPhases}
                                                        projectPhaseRoles={appState.projectPhaseRoles}
                                                        projectProgresses={appState.projectProgresses}
                                                        user={user}
                                                        projectRole={appState.projectRole}
                                                    /> 
                                                </>
                                                
                                            ))
                                            }
                                        </>
                                        }
                            </div>
                            
                            <div className="divFooter">
                                <div>{account?.name}</div>
                                <div style={{float: 'right'}} >{DateHelper.getDateTime(new Date())}</div>
                            </div>
                            </>: <Loading text="Loading..."/>
                                } 
                            </section>
                            
                           
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
  
}

export default PrintPdf
