import React,{ useState, useEffect ,useRef} from 'react';
import { DeliverableTemplateService,DeliverableItemService,DeliverableDocumentService, PropertyService ,AssuranceActivityService,HistoryLogService}  from '../../services';
import { DeliverableTemplate,DeliverableDocument,DeliverableDocumentIndex, Phase,Stage,DeliverableType, Pagination ,PageEvent,SelectItem,AuthenUser} from '../../models'
import { useApiAuth } from '../../hooks/useApiAuth';
import { ProjectMode } from '../../models/Property'
import uniqid from 'uniqid';
import '../../assets/css/deliverable.css'
import { AiOutlineEdit, AiOutlineDelete,AiOutlineCloseCircle,AiOutlineSave,AiOutlineInfoCircle,AiOutlineSearch,AiOutlineSetting,AiOutlineCheckCircle} from "react-icons/ai";
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseFill, RiErrorWarningLine, RiRefreshLine, RiFileExcel2Line } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal'
import { Color } from '../../enum'
import { DateHelper,StringHelper } from '../../helpers';
//import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import ReactPaginate from 'react-paginate';
import { VscAdd,VscClose } from "react-icons/vsc";
import { Tooltip } from 'react-tooltip'
import Loading from '../Loading';
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { config } from '../../config';

interface Item{
    key: string,
    value: number
}

const initialDocument:DeliverableDocument = {
    deliverableTemplateDocumentId:0,
    deliverableDocumentId: 0,
    deliverableTemplateId: 0,
    deliverableItemId: 0,
    deliverableItemName: "",
    stageId: 0,
    stageName: "",
    assuranceActivityId: 0,
    assuranceActivityName:"",
    deliverableTypeId: 0,
    deliverableTypeCode: "",
    remark: "",
    documentIndex: 0,
    createBy: "",
    updateBy: ""
}

const AdminDeliverableTemplate = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const deliverableItemService = new DeliverableItemService(accessToken)
    const deliverableTemplateService = new DeliverableTemplateService(accessToken)
    const deliverableDocumentService = new DeliverableDocumentService(accessToken)
    const propertyService = new PropertyService(accessToken)
    const assuranceActivityService = new AssuranceActivityService(accessToken)
    const [projectModes, setProjetModes] = useState<ProjectMode[]>()
    const [projectModeId, setProjectModeId]= useState(0)
    const [phase, setPhase]= useState<Phase>({phaseId: 0,phaseName: "",projectModeId: 0, phaseNumber: 0})
    const [phases,setPhases] = useState<Phase[]>([])
    const [projectPhases,setProjectPhases] = useState<Phase[]>([])
    const [stages,setStages] = useState<Stage[]>([])
    const [deliverableTypes,setDeliverableTypes] = useState<DeliverableType[]>([])
    const [isSetting, setIsSetting]= useState(false)
    const [deliverableTemplate,setDeliverableTemplate] = useState<DeliverableTemplate>({ deliverableTemplateId : 0, deliverableTemplateName: "",projectModeId: 0,phaseId: 0, documents:0, updateBy: "",updateDateTime: new Date()})
    const [pagination, setPagination] = useState<Pagination<DeliverableTemplate>>({page: 1,total: 0,limit: 10,items: [] })
    const [isLoading,setIsLoading] = useState(true)
    const [isSearch,setIsSearch] = useState(false)
    const [isModalShow, setIsModalShow] = useState(false);
    const [isFlowShow, setIsFlowShow] = useState(false);
    const [flowNumber, setFlowNumber] = useState(0);
    const [isConfirmTemplate, setIsConfirmTemplate] = useState(false);
    const searchKey = useRef<HTMLInputElement>(null)
    const selectProjectMode = useRef<HTMLSelectElement>(null)
    const selectPerPage =  useRef<HTMLSelectElement>(null)
    const [errors,setErrors] = useState<string[]>([])

    const [deliverableDocuments,setDeliverableDocuments] = useState<DeliverableDocument[]>([])
    const [deliverableDocumentIndexs,setDeliverableDocumentIndexs] = useState<DeliverableDocumentIndex[]>([])
    const [deliverableDocument,setDeliverableDocument] = useState<DeliverableDocument>(initialDocument)
    const [recomendation,setRecomendation] = useState("")
    const [isDocumentLoading,setIsDocumentLoading] = useState(false)
    const [isAddDocument, setIsAddDocument] = useState(false);
    const [editDocumentId, setEditDocumentId] = useState(0);
    const [isConfirmDocument, setIsConfirmDocument] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false)
    
    const [noVac,setNoVac] = useState(false)
    const [noAp,setNoAp] = useState(false)
    const [noDg,setNoDg] = useState(false)
    const [selectItem,setSelectItem] = useState({deliverableTemplateName: ""})

    var timeout:NodeJS.Timeout | null = null

    const btnStyle = {
        b0:{
            backgroundColor: '',
            color: '',
            width: '220px'
        },
        b1:{
            backgroundColor: Color.DarkBlue,
            color: 'white',
            width: '220px'
        }
    }

    useEffect(() => {
        if(accessToken){
            onInitialLoad()
        }
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const onInitialLoad = async () => {
        
        let result = await propertyService.getProperties(['projectMode','phase','stage','deliverableType'])
        if(result && result.success){
            let _projectModes = result.data.projectModes
            let _phases = result.data.phases
            let _stages = result.data.stages
            let _deliverableTypes = result.data.deliverableTypes
            let _projectModeId = _projectModes[0].projectModeId

            if(_projectModes){
                setProjetModes(_projectModes)
                setProjectModeId(_projectModeId)
            }
            if(_phases){
                let _projectModePhases = _phases.filter(x => x.projectModeId == _projectModeId)
                setPhases(_phases)
                setProjectPhases(_projectModePhases)
                setPhase(_phases[0])
            }
            if(_stages){
                setStages(_stages)
            }
            if(_deliverableTypes){
                setDeliverableTypes(_deliverableTypes)
            }

           getTeamplates(1,pagination.limit,_projectModeId,_phases[0].phaseId)

        }
        
    }

    const handlePageClick = (event: PageEvent) => {
        let currentPage = event.selected + 1
        getTeamplates(currentPage, pagination.limit,projectModeId,phase.phaseId);
        
    }

    const onProjectModeChange = (_projectModeId:number) =>{
        let _projectModePhases = phases.filter(x => x.projectModeId == _projectModeId)
        if(_projectModePhases){
            let _phase = _projectModePhases[0]
            setPhase(_phase)
            setProjectPhases(_projectModePhases)  
            setProjectModeId(_projectModeId) 
            getTeamplates(1,pagination.limit,_projectModeId,_phase.phaseId)
        }
    }

    const onPhaseChange = (_phase:Phase) => {
        if(timeout){
            clearTimeout(timeout)
        }
       // timeout = setTimeout(() => {
            setPhase(_phase)
            getTeamplates(1,pagination.limit,projectModeId,_phase.phaseId)
        //}, 500);
       
    }

    const getTeamplates = async(currentPage: number,perpage:number,_projectModeId:number,phaseId:number,  key:string = "") => {
        setIsLoading(true)
        let response = await deliverableTemplateService.getAll(currentPage,perpage,_projectModeId,phaseId, key);
        //console.log(response)
        setIsLoading(false)
        
        if(response.success){
            let { page ,limit, total, items} = response.data
            setPagination({ page: page,limit: limit ,total: total ,items: items })
        }
    }

    const onSearchTemplate = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new DeliverableTemplateService(token).Search(key,projectModeId,phase.phaseId);
        //console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectTemplate = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ deliverableTemplateName: item.value})
          }
         
      }


      const searchTemplate = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({deliverableTemplateName: inputSearch.value})
                key = inputSearch.value
            }
       
        setIsLoading(true)
        setIsSearch(true)
        getTeamplates(1,pagination.limit,projectModeId,phase.phaseId,key)
    }

    const resetTemplate= () => {
        setSelectItem({deliverableTemplateName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setIsLoading(true)
        setIsSearch(false)
        getTeamplates(1,pagination.limit,projectModeId,phase.phaseId)
    }

    const onPageLimitChange = (ev:React.ChangeEvent<HTMLSelectElement>) => {
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({deliverableTemplateName: inputSearch.value})
                key = inputSearch.value
            }
        let limit = parseInt(ev.target.value)
        getTeamplates(1,limit,projectModeId,phase.phaseId,key)
    }

    const addTemplate = () => {
        let _deliverableTemplate:DeliverableTemplate = {
            deliverableTemplateId: 0,
            deliverableTemplateName: "",
            projectModeId: projectModeId,
            phaseId: phase.phaseId,
            documents: 0,
            updateDateTime: new Date(),
            updateBy:  account && account.name ? account.name : ""
        }

        setDeliverableTemplate(_deliverableTemplate)
        setIsModalShow(true)
    }

    const editTemplate = (_deliverableTemplate:DeliverableTemplate) => {
        setDeliverableTemplate(_deliverableTemplate)
        setIsModalShow(true)
    }

    const confirmTemplate = (_deliverableTemplate:DeliverableTemplate) => {
        setDeliverableTemplate(_deliverableTemplate)
        setIsConfirmTemplate(true)
    }

    const saveTemplate = async () => {
        let result:any = null
        let _deliverableTemplate = Object.assign({},deliverableTemplate)
        let inputItem =document.getElementById('input-item') as HTMLInputElement

        if(!inputItem.value){
            return
        }

        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true

        try{
            _deliverableTemplate.deliverableTemplateName = inputItem.value
          
            if(_deliverableTemplate.deliverableTemplateId == 0){
                result = await deliverableTemplateService.create(_deliverableTemplate)
                
            }
            else{
                result = await deliverableTemplateService.update(_deliverableTemplate)
                
            }
            if(result.success)
            {
                saveBtn.disabled = false
                if(_deliverableTemplate.deliverableTemplateId == 0){
                    let token = await getToken()
                    new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Create template "' + _deliverableTemplate.deliverableTemplateName + '"')
                }
                else{
                    let token = await getToken()
                    new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Update template "' + _deliverableTemplate.deliverableTemplateName + '"')
                }
                setIsModalShow(false)
                getTeamplates(1,pagination.limit,projectModeId,phase.phaseId)
            }
            else{
                saveBtn.disabled = false
               if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            saveBtn.disabled = false
            setErrors([err.message])
            
        }
    }

    const deleteTemplate = async() => {
        try{
            let updateBy =  account && account.name ? account.name : ""
            //console.log(deliverableTemplate)
            let result = await deliverableTemplateService.delete(deliverableTemplate.deliverableTemplateId,updateBy)
            //console.log(result)
            
            if(result.success){
                let token = await getToken()
                new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Delete template "' + deliverableTemplate.deliverableTemplateName + '"')
                setIsConfirmTemplate(false)
                getTeamplates(1,pagination.limit,projectModeId,phase.phaseId)
            }
            else{
                setIsConfirmTemplate(false)
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            setErrors([err.message])
        }
    }

    const settingDocument = async(_deliverableTemplate:DeliverableTemplate) => {
        setIsSetting(true)
        setDeliverableTemplate(_deliverableTemplate)
        getDocuments(_deliverableTemplate.deliverableTemplateId)
    }

    const getDocuments = async(deliverableTemplateId: number) => {
        setIsDocumentLoading(true)
        let response = await deliverableDocumentService.getByTemplateId(deliverableTemplateId);
        //console.log(response)
        if(response.success){
            let _deliverableDocuments = response.data.sort((a,b) => a.documentIndex - b.documentIndex)
            setDeliverableDocuments(_deliverableDocuments)
        }
        setIsDocumentLoading(false)
    }

    const addEditDocument = (_deliverableDocument:DeliverableDocument | null) => {
        if(!_deliverableDocument){
             _deliverableDocument = {
                deliverableTemplateDocumentId: 0,
                deliverableDocumentId: 0,
                deliverableTemplateId: deliverableTemplate.deliverableTemplateId,
                deliverableItemId: 0,
                deliverableItemName: "",
                stageId: 0,
                stageName: "",
                assuranceActivityId:0,
                assuranceActivityName:"",
                deliverableTypeId: 0,
                deliverableTypeCode: "",
                remark: "",
                documentIndex: (deliverableDocuments) ? deliverableDocuments.length : 1,
                createBy: account && account.name ? account.name : "",
                updateBy:""
            }
        }

        setDeliverableDocument(_deliverableDocument)
        if(_deliverableDocument.deliverableDocumentId == 0){
            setIsAddDocument(true)
            setEditDocumentId(0)
        }
        else{
            setIsAddDocument(false)
            setEditDocumentId(_deliverableDocument.deliverableDocumentId)
        }
       
    }

    const onDocumentChange = (item:Item) =>{
        let _deliverableDocument = Object.assign({},deliverableDocument)
        _deliverableDocument[item.key] = item.value
        _deliverableDocument.remark = getRecomendation()
        setDeliverableDocument(_deliverableDocument)
    }

    const onDocumentChangeAssuranceActivity = (item:SelectItem) =>{
        //console.log(item)
        let _deliverableDocument = Object.assign({},deliverableDocument)
        _deliverableDocument.assuranceActivityId = item.id 
        _deliverableDocument.assuranceActivityName = item.value ? item.value : ""
        _deliverableDocument.remark = getRecomendation()
        setDeliverableDocument(_deliverableDocument)
        
    }

    const onDocumentChangeDeliverableItemId = (item:SelectItem) =>{
        let _deliverableDocument = Object.assign({},deliverableDocument)
        _deliverableDocument.deliverableItemId = item.id
        _deliverableDocument.deliverableItemName = item.value ? item.value : ""
        _deliverableDocument.remark = getRecomendation()
        setDeliverableDocument(_deliverableDocument)
       
    }

    const getRecomendation = () =>{
        let inputRecomendation = document.getElementById('input-recomendation') as HTMLTextAreaElement
        return  (inputRecomendation) ? inputRecomendation.value : ""
    }

    const closeAddEditDocument = () => {
        setIsAddDocument(false)
        setEditDocumentId(0)
    }

    const confirmDeleteDocument = (_deliverableDocument:DeliverableDocument) => {
        setDeliverableDocument(_deliverableDocument)
        setIsConfirmDocument(true)
    }

    const updateDocumentIndex = async (_deliverableDocuments:DeliverableDocumentIndex[]) => {
       // setIsSuccess(true)
        let result = await deliverableDocumentService.UpdateIndex(_deliverableDocuments)
        //console.log(result)
        if(result.success){
            setIsSuccess(true)
            let element = document.getElementById('last-update') as HTMLDivElement
            if(result.message){
                element.innerHTML =  result.message
                element.style.display = 'none'
            }
            setTimeout(() => {
                setIsSuccess(false)
                element.style.display = 'inline'
            }, 300);
           
        }
    }

    const saveDocument = async () => {
        let result:any = null
        let inputRecomendation = document.getElementById('input-recomendation') as HTMLTextAreaElement
        let _deliverableDocument = Object.assign({},deliverableDocument)
        _deliverableDocument.remark =  (inputRecomendation) ? inputRecomendation.value : ""
    
        let saveBtn =document.getElementById('save-doc-btn') as HTMLButtonElement
        saveBtn.disabled = true

        if(_deliverableDocument.stageId === 0){
            //alert('Please select Stage.')
            setErrors(['Please select Stage.'])
            saveBtn.disabled = false
            return false
        }
    //     if(_deliverableDocument.assuranceActivityId === 0){
    //        alert('Please select assurance activity.')
    //        return false
    //    }

        let inputAss = document.getElementById('assurance-activity') as HTMLInputElement
        if(inputAss){
            if(inputAss.value == ""){
                _deliverableDocument.assuranceActivityId = 0
            }
        }

        if(_deliverableDocument.deliverableItemId === 0){
            setErrors(['Please select deliverable item.'])
            saveBtn.disabled = false
            return false
        }

        if(_deliverableDocument.deliverableTypeId === 0){
            setErrors(['Please select deliverable type.'])
            saveBtn.disabled = false
            return false
        }
        
        //console.log(_deliverableDocument)
        if(_deliverableDocument.deliverableDocumentId == 0){
            let token = await getToken()
            new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Action Before Create Item "' + deliverableTemplate.deliverableTemplateName + '/' + _deliverableDocument.stageName + "/" + _deliverableDocument.assuranceActivityName + "/" +_deliverableDocument.deliverableItemName + '"')
        }
        else{
            let token = await getToken()
            new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Action Before Update item "' + deliverableTemplate.deliverableTemplateName + '/' + _deliverableDocument.stageName + "/" + _deliverableDocument.assuranceActivityName + "/" +_deliverableDocument.deliverableItemName + '"')
        }

        try{

            if(_deliverableDocument.deliverableDocumentId == 0){
                result = await deliverableDocumentService.Create(_deliverableDocument)
              
            }
            else{
                result = await deliverableDocumentService.Update(_deliverableDocument)
              
            }
            if(result.success){
                saveBtn.disabled = false
                if(_deliverableDocument.deliverableDocumentId == 0){
                    let token = await getToken()
                    new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Create Item Success"' + deliverableTemplate.deliverableTemplateName + '/' + _deliverableDocument.stageName + "/" + _deliverableDocument.assuranceActivityName + "/" +_deliverableDocument.deliverableItemName + '"')
                }
                else{
                    let token = await getToken()
                    new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Update item Success"' + deliverableTemplate.deliverableTemplateName + '/' + _deliverableDocument.stageName + "/" + _deliverableDocument.assuranceActivityName + "/" +_deliverableDocument.deliverableItemName + '"')
                }
                setIsSuccess(true)
                let element = document.getElementById('last-update') as HTMLDivElement
                element.style.display = 'none'
                setTimeout(() => {
                    setIsSuccess(false)
                    closeAddEditDocument()
                    getDocuments(deliverableTemplate.deliverableTemplateId)
                    if(result.message){
                        element.innerHTML =  result.message
                        element.style.display = 'inline'
                    }
                }, 500);
                
            }
            else{
                saveBtn.disabled = false
                setErrors([result.message])

            }
           
        }
        catch(err){
            saveBtn.disabled = false
            setErrors([err.message])
           
        }
    }

    const deleteDocument = async() => {
        try{
            
            let updateBy =  account && account.name ? account.name : ""
            let result = await deliverableDocumentService.Delete(deliverableDocument.deliverableTemplateDocumentId,updateBy)
            //console.log(result)
            
            if(result.success){
                let token = await getToken()
                new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Template",'Delete item "' + deliverableTemplate.deliverableTemplateName + '/'  + deliverableDocument.stageName + "/" + deliverableDocument.assuranceActivityName + "/" +deliverableDocument.deliverableItemName + '"')
                setIsConfirmDocument(false)
                getDocuments(deliverableTemplate.deliverableTemplateId)
                if(result.message){
                    let element = document.getElementById('last-update') as HTMLDivElement
                    element.innerHTML =  result.message
                    element.style.display = 'none'
                    setTimeout(() => {
                        element.style.display = 'inline'
                    }, 500)
                }
            }
            else{
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            
                setErrors([err.message])
            
        }
    }

    const searchAssuranceActivity = async(key:string) => {
        let result = await assuranceActivityService.Search(key)
        return result ? result.data : null
    }

    const getAssuranceActivity = async() => {
        let result = await assuranceActivityService.getLimit()
        return result ? result.data : null
    }

    const searchDeliverableItem = async(key:string) => {
        let result = await deliverableItemService.Search(key)
        return result.data
    }

    const getDeliverableItem = async() => {
        let result = await deliverableItemService.getLimit()
        //console.log(result)
        return result.data
    }

    const imageFlowShow = (_flowNumber:number) => {
        setFlowNumber(_flowNumber)
        setIsFlowShow(true)
    }

    const addDocumentBlock = () => {
        
            return (
                <tr key={uniqid()} style={{background: '#c9e9c9'}} >
                    <td style={{ verticalAlign : 'top'}} >
                        <select className="form-select form-select-sm" value={deliverableDocument.stageId}  onChange={ev => onDocumentChange({ key: 'stageId', value: parseInt(ev.target.value) })}>
                            <option value={0}>Select Stage</option>
                        { stages?.map(s => <option key={uniqid()} value={s.stageId}>{ s.stageName}</option>) }  
                    </select>
                    </td>
                    <td style={{ verticalAlign : 'top'}} >
                        <TextboxAutocomplete  elementId={'assurance-activity'} idKey="assuranceActivityId" valueKey="assuranceActivityName" onClick={getAssuranceActivity} onChange={searchAssuranceActivity} onSelect={onDocumentChangeAssuranceActivity} initialId={deliverableDocument.assuranceActivityId} initialValue={deliverableDocument.assuranceActivityName} />
                    </td>
                    <td style={{ verticalAlign : 'top'}} >
                        <TextboxAutocomplete elementId={'deliverable-item'} idKey="deliverableItemId" valueKey="deliverableItemName" onClick={getDeliverableItem} onChange={searchDeliverableItem} onSelect={onDocumentChangeDeliverableItemId} initialId={deliverableDocument.deliverableItemId} initialValue={deliverableDocument.deliverableItemName}/>
                    </td>
                    <td style={{ verticalAlign : 'top'}} >
                        <textarea id="input-recomendation" defaultValue={deliverableDocument.remark} placeholder='Remark' className="form-control form-control-sm " style={{ height: '60px'}} ></textarea>
                    </td>
                    <td style={{ verticalAlign : 'top'}} >
                        <select id="deliverable-type" value={deliverableDocument.deliverableTypeId}  className="form-select form-select-sm" onChange={ev => onDocumentChange({ key: 'deliverableTypeId', value: parseInt(ev.target.value) })}>
                            <option value={0}>Select Type</option>
                            { deliverableTypes?.map(d => <option key={uniqid()} value={d.deliverableTypeId}>{ d.deliverableTypeCode }</option>) } 
                        </select>
                    </td>
                    <td style={{ border: 'none'}} ></td>
                    <td style={{ border: 'none'}} ></td>
                    <td style={{ border: 'none',verticalAlign : 'top'}}  >
                        <button id="save-doc-btn" type="button" className="btn" style={{marginTop: '-7px'}}  onClick={saveDocument}>
                            <AiOutlineSave size={25} className="icon-save"/>
                        </button>
                    </td>
                    <td style={{ border: 'none',verticalAlign : 'top'}} >
                        <RiCloseFill size={25} className="icon-delete" cursor="pointer" onClick={closeAddEditDocument}/>
                    </td>
                </tr>
            )
        
    }

    const documentIndexUp = (index) =>{
        if(index === 0){
            return false
        }

        let _deliverableDocuments:DeliverableDocument[] = Object.assign([],deliverableDocuments)
        let _deliverableDocumentIndexs:DeliverableDocumentIndex[] = []

        let previousIndex = index - 1
        let previousDocument = _deliverableDocuments[previousIndex]
        let currentDocument = _deliverableDocuments[index]

        previousDocument.documentIndex = index
        currentDocument.documentIndex = previousIndex

        _deliverableDocuments[previousIndex] = currentDocument
        _deliverableDocuments[index] = previousDocument

        _deliverableDocumentIndexs.push({ 
            deliverableTemplateDocumentId: previousDocument.deliverableTemplateDocumentId, 
            documentIndex: previousDocument.documentIndex,
            updateBy:  account && account.name ? account.name : ""
        })
        _deliverableDocumentIndexs.push({ 
            deliverableTemplateDocumentId: currentDocument.deliverableTemplateDocumentId, 
            documentIndex: currentDocument.documentIndex,
            updateBy:  account && account.name ? account.name : ""
        })

        setDeliverableDocuments(_deliverableDocuments)
        //console.log(_deliverableDocuments)
        if(timeout){
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            updateDocumentIndex(_deliverableDocumentIndexs)
        }, 500);
       
    }

    const documentIndexDown = (index) =>{

        if(index === (deliverableDocuments.length - 1)){
            return false
        }
    
        let nextIndex = index + 1
        let _deliverableDocuments:DeliverableDocument[] = Object.assign([],deliverableDocuments)
        let _deliverableDocumentIndexs:DeliverableDocumentIndex[] = []

        let nextDocument = _deliverableDocuments[nextIndex]
        let currentDocument = _deliverableDocuments[index]
        
        nextDocument.documentIndex = index
        currentDocument.documentIndex = nextIndex
        _deliverableDocuments[nextIndex] = currentDocument
        _deliverableDocuments[index] = nextDocument

        _deliverableDocumentIndexs.push({ 
            deliverableTemplateDocumentId: nextDocument.deliverableTemplateDocumentId, 
            documentIndex: nextDocument.documentIndex,
            updateBy:  account && account.name ? account.name : ""
        })
        _deliverableDocumentIndexs.push({ 
            deliverableTemplateDocumentId: currentDocument.deliverableTemplateDocumentId, 
            documentIndex: currentDocument.documentIndex,
            updateBy:  account && account.name ? account.name : ""
        })

        setDeliverableDocuments(_deliverableDocuments)
        //console.log(_deliverableDocuments)
        if(timeout){
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            updateDocumentIndex(_deliverableDocumentIndexs)
        }, 500);
    }

    const exportExcel = async() =>{
        let token = await getToken()
        let projectMode = projectModes?.find(x => x.projectModeId === projectModeId)
        let projectModeName = ""
        if(projectMode){
            projectModeName = projectMode.projectModeName
        }
       new DeliverableTemplateService(token).exportExcel(projectModeName, phase.phaseName,deliverableTemplate.deliverableTemplateName,deliverableTemplate.deliverableTemplateId)
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px"}} >
                <div className="body">
                    <div className="row">
                        <div className="col-sm-6">
                            <span className='project-label'>Deliverable Template</span>
                        </div>
                    </div>
                { isLoading ? <Loading text="Loading..."/> :
                    <>
                    <div className='row' style={{ marginTop: '15px'}} >
                        <div className='col-sm-2 col-label'>Project Category</div>
                        <div className='col-sm-2'>
                            <select ref={selectProjectMode} id="project-mode" value={projectModeId}  className="form-select form-select-sm" onChange={ ev => onProjectModeChange(parseInt(ev.target.value))}>
                            { projectModes?.map(m => <option key={uniqid()} value={m.projectModeId}>{ m.projectModeName}</option>) }  
                            </select>
                        </div>
                    </div>
                    <div id="phases-selection" className='row' style={{ marginBottom : '0'}} >
                        <div style={{ marginTop : '10px'}} >
                            { 
                                projectPhases.map((_phase) => (
                                    <button key={uniqid()} className="tab-btn" style={ (phase.phaseId === _phase.phaseId) ? btnStyle.b1 : btnStyle.b0 } onClick={ () => onPhaseChange(_phase) } disabled={isSetting && phase.phaseId !== _phase.phaseId  }>
                                        <span>{_phase.phaseName}</span>
                                        <AiOutlineInfoCircle size={20} cursor="pointer" onClick={() => imageFlowShow(_phase.phaseNumber)} style={{ marginLeft: '5px' }}  />
                                    </button>
                                ))
                            }
                        </div>
                        <div id={ 'phase-'+ phase.phaseName}></div> 
                    </div>
                   
                    { (!isSetting) ?
                        <>
                        <div className='row' style={{ marginTop: '20px',marginBottom: '0px'}} >
                            <div className='col-md-4'>
                                <div className="input-group">
                                <TextboxAutocomplete key={uniqid()}  valueKey="deliverableTemplateName" onChange={onSearchTemplate} onSelect={onSelectTemplate} elementId='input-search' initialValue={selectItem.deliverableTemplateName} hiddenArrow={true}/>
                                </div>
                            </div>
                            <div className='col-md-1'>
                                <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}}  onClick={() => searchTemplate()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}} />
                                </button>
                            </div>
                            <div className='col-md-1' style={{ paddingTop: '3px'}} >
                                { isSearch ?
                                    <span className="input-group-append" style={{cursor:"pointer"}}  onClick={resetTemplate}>
                                        <RiRefreshLine size={20} color={Color.Blue}/>
                                        <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}} >Reset</span>
                                    </span>:null
                                }    
                            </div>
                            <div className='col-md-6' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}} >
                                { user.administratorUpdate > 0 ?
                                 <div className="admin-action" style={{ cursor: 'pointer'}}  onClick={addTemplate}>
                                    <span className='app-active' >
                                        <VscAdd size="20" color={Color.Blue}  style={{ marginLeft:'5px', marginTop: '-7px'}}  />
                                        <span style={{ fontSize: '14px',marginLeft:'2px'}} >Add Template</span>
                                    </span>
                                </div> 
                                :<></>
                                }
                            </div>
                        </div>
                    
                        <div className='row' style={{ marginTop: '10px'}} >
                            <div className='col-md-10' style={{ paddingTop:'7px'}} >
                                <span style={{ marginLeft: '5px'}} >Search found {pagination.total} record(s).</span>
                            </div>
                            <div className='col-md-2' style={{textAlign: 'right'}} >
                                <span style={{ marginRight: '5px',marginTop: '8px'}} >Show</span>
                                <select value={pagination.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}} >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option> 
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>        
                                </select>
                            </div>
                        </div>
                        <div className="table-responsive table-responsive-sm">
                                <table className="table table-sm table-bordered table-hover" style={{ marginTop: '5px'}} >
                                    <thead>
                                        <tr>
                                            <th style={{width: '50px'}} >No.</th>
                                            <th>{phase.phaseName}</th>
                                            <th >Modified By</th>
                                            <th >Modified Date</th>
                                            <th style={{width: '100px'}} >Edit</th>
                                            <th style={{width: '100px'}} >Document Setting</th>
                                            <th style={{width: '100px'}} >Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        pagination.items.map((item,index) =>(
                                            <tr key={uniqid()}  style={{height: '45px',textAlign:'left',paddingLeft:'20px', backgroundColor: (index%2) ? '#f3f3f3' : ''}} >
                                                <td>
                                                {((pagination.page -1) * pagination.limit) + index + 1}
                                                </td>   
                                                <td style={{textAlign:'left',paddingLeft:'20px'}} >
                                                 <span>{ item.deliverableTemplateName }</span>
                                                </td>
                                               
                                                <td style={{ textAlign: 'center' }} >
                                                    <span>{ item.updateBy }</span>
                                                </td>
                                                <td style={{ textAlign: 'center' }} >
                                                    <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                                </td>
                                                <td className='text-center-both'>
                                                    { user.administratorUpdate > 0 ?
                                                        <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editTemplate(item)}/>
                                                        :<></>
                                                    }
                                                    </td>
                                                <td className='text-center-both'>
                                                    { user.administratorUpdate > 0 ?
                                                        <AiOutlineSetting  size={20} color={Color.Blue} cursor="pointer" onClick={ () => settingDocument(item)}/>
                                                        :<></>
                                                    }
                                                </td>
                                                <td>
                                                    { user.administratorUpdate > 0 ?
                                                        <AiOutlineDelete size={20} color={Color.Red2} cursor="pointer" onClick={ () => confirmTemplate(item)}/>
                                                        :<></>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                        </div>
                        <div className='row'>
                            <div className='col-md-4' style={{ display: 'flex'}} ></div>
                            <div className='col-md-8'>
                                <div id='pagination' style={{ float: 'right'}} >
                                    <ReactPaginate
                                        pageRangeDisplayed={5}
                                        pageCount={Math.ceil(pagination.total/pagination.limit)}
                                        breakLabel="..."
                                        previousLabel="<<"
                                        nextLabel=">>"
                                        onPageChange={handlePageClick}
                                        forcePage={pagination.page - 1}
                                        marginPagesDisplayed={2}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active-gradient"
                                    />
                                </div>
                            </div>
                        </div>
                        </>:
                            <div id="deliverable-setting" style={{ "padding":"5px"}} >
                                <div className="body">
                                    <div className='project-block-none' style={{ paddingTop : '5px'}} >
                                        <div style={{ borderStyle: 'solid', borderColor: '#cccccc', padding: '15px', borderRadius: '10px'}} >
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <span className='font-bold'>Document Setting</span>
                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'right'}} >
                                                    <VscClose size={30} className="icon-delete"  cursor="pointer"  onClick={() => setIsSetting(false)}/>
                   
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <span className='font-bold'>Template Name: </span>
                                                        <span style={{ marginLeft: '10px' }} >{deliverableTemplate.deliverableTemplateName}</span>
                                                    </div>

                                                    <div className='col-md-6' style={{ textAlign: 'right' }} >

                                                        <button type="button" className="btn btn-sm bg-dark-blue" style={{ width: "150px" }}  onClick={exportExcel} disabled={deliverableDocuments.length == 0}>
                                                            <RiFileExcel2Line size="20" color="white" />
                                                            <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }} >Export</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div style={{ display:"none"}}  >
                                                    <span style={{marginRight: '10px'}} >Stage Information</span>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="no-vac" id="no-vac" value={1} onChange={ev => setNoVac(ev.target.checked)}/>
                                                        <label className="form-check-label label-deliverable-setting" htmlFor="no-vac">No Value Assurance Check</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="no-ap" id="no-ap" value={1} onChange={ev => setNoAp(ev.target.checked)}/>
                                                        <label className="form-check-label label-deliverable-setting" htmlFor="no-ap">No Appropriate Committee</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" name="no-dg" id="no-dg" value={1} onChange={ev => setNoDg(ev.target.checked)}/>
                                                        <label className="form-check-label label-deliverable-setting" htmlFor="no-dg">No Decision Gate</label>
                                                    </div>
                                                </div>
                                            <div className='row' style={{ marginTop: '15px'}} >
                                                <div className="col-sm-6">
                                                    { user.administratorUpdate > 0 ?
                                                    <div className="admin-action" style={{ cursor: 'pointer'}}  onClick={() => addEditDocument(null)}>
                                                        <span className='app-active' >
                                                            <VscAdd size="20" color={Color.Blue}  style={{ marginLeft:'5px', marginTop: '-7px'}}  />
                                                            <span style={{ fontSize: '14px',marginLeft:'2px'}} >Add Document Item</span>
                                                        </span>
                                                    </div> 
                                                    :<></>
                                                    }
                                                </div>
                                                <div className="col-sm-6" style={{ textAlign:'right',color:Color.DarkBlue}} >
                                                    <div id="last-update"></div> 
                                                </div>
                                    </div>
                                    <div className="table-responsive" style={{ paddingBottom: '100px'}} >
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Stage</th>
                                                    <th style={{ width: '150px'}} >Assurance Activity</th>
                                                    <th style={{ minWidth: '300px'}} >Deliverable Item</th>
                                                    <th>Remark</th>
                                                    <th style={{ minWidth: '155px'}} >
                                                        Deliverable Type
                                                        <a data-tooltip-id='deliverable-type-info' style={{cursor: 'pointer'}} >
                                                            <AiOutlineInfoCircle color="white" size={20} style={{ verticalAlign: 'middle',marginLeft: '2px'}} />
                                                        </a>
                                                        <Tooltip id='deliverable-type-info' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                                            <div>M = Mandatory</div>
                                                            <div>C = Conditional</div>
                                                        </Tooltip>
                                                    </th>
                                                    <th colSpan={4}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { isAddDocument ? addDocumentBlock() : null }
                                                { 
                                                    deliverableDocuments ? deliverableDocuments.map((item,index) => (
                                                    editDocumentId == item.deliverableDocumentId ? addDocumentBlock() :
                                                        <tr key={uniqid()} style={{background: index % 2 ? 'f3f3f3' :'#ddd'}} >
                                                            <td className='text-center-both'>
                                                                <span>{ item.stageName } </span>
                                                            </td>
                                                            <td className='text-center-both'>
                                                                <span>{ item.assuranceActivityName }</span>
                                                            </td>
                                                            <td style={{ textAlign: 'left'}} >
                                                                <span>{ item.deliverableItemName }</span>
                                                            </td>
                                                            <td className='text-center-both'>
                                                            { StringHelper.getLength(item.remark) > 10 ?
                                                                <>
                                                                <a data-tooltip-id={'remark-info-' + index} style={{ cursor: 'pointer' }} >
                                                                    { StringHelper.substr(item.remark,0,10)}
                                                                </a>
                                                                <Tooltip id={'remark-info-' + index} className='message-tooltip' style={{ backgroundColor: 'white', color: 'black' }} border='1px solid #ccc' place='top' offset={8}>
                                                                <div style={{minWidth: '200px'}} >
                                                                    {item.remark}
                                                                    </div>
                                                                </Tooltip>
                                                                </>
                                                                    :
                                                                    <span>{item.remark}</span>
                                                            }
                                                            </td>
                                                            <td className='text-center-both'>
                                                                <span>{ item.deliverableTypeCode}</span>
                                                            </td>
                                                            <td className='text-center-both'>
                                                            <   AiOutlineEdit size={20} className="icon-edit" cursor="pointer" onClick={() => addEditDocument(item)}/>
                                                            </td>
                                                            <td className='text-center-both'>
                                                                <RiArrowUpSLine size={25} className="icon-grey" cursor="pointer" onClick={() => documentIndexUp(index)}/>
                                                            </td>
                                                            <td className='text-center-both'>
                                                                <RiArrowDownSLine size={25} className="icon-grey" cursor="pointer" onClick={() => documentIndexDown(index)}/>
                                                            </td>
                                                            <td className='text-center-both'>
                                                                <AiOutlineDelete size={20} className="icon-delete"  cursor="pointer" onClick={() => confirmDeleteDocument(item)}/>
                                                            </td>
                                                        </tr>
                                                    )) : <tr key={uniqid()} style={{ background: '#ddd' }} ></tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div> 
                    } 
                    </>
                }
        </div>
            </div>
            <div className='row' style={{ marginTop: '30px'}} >
                <div className='col-md-12'></div>
            </div>
            <Modal show={isModalShow} onHide={() => setIsModalShow(false)} size="lg">
                <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                            
                                { deliverableTemplate.deliverableTemplateId ? <span>Update Deliverable Template</span> : <span> Add Deliverable Template</span> }
                                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setIsModalShow(false)}>X</span>
                            
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center'}} >
                            <div className='row'>
                                <div className="col-sm-1 col-label">
                            
                                </div>
                                <div className="col-sm-10">
                            <label className="form-label col-label" style={{ float: 'left' }} >Deliverable Template Name:</label>
                                    <input id="input-item" defaultValue={deliverableTemplate.deliverableTemplateName} type="text"  className="form-control form-control-sm " />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 text-center-both'>
                                    
                                    <button id="save-btn" type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={saveTemplate} >
                                        <span style={{ marginLeft: '5px'}}  >Save</span>
                                    </button>
                                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setIsModalShow(false)} >
                                        <span style={{ marginLeft: '5px'}}  >Cancel</span>
                                    </button>
                                </div>
                            </div>
                            
                        </Modal.Body>
            </Modal>

            <Modal show={isFlowShow} onHide={() => setIsFlowShow(false)} size="xl">
            <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                    &nbsp;
                <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setIsFlowShow(false)}>X</span>
                </Modal.Header>
                <Modal.Body>
                    { 
                      <img src={config.API_URL + '/v1/images/phase/' + projectModeId.toString() + "/" + flowNumber.toString()} style={{ width: '100%'}} />
                    }
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            
            <Modal show={isConfirmTemplate} onHide={() => setIsConfirmTemplate(false)} size="lg">
                 <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                    Confirm Message
                    <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setIsConfirmTemplate(false)}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}} >
                    <div>
                        <RiErrorWarningLine size={25} color={'red'} />
                        <span style={{ marginLeft: '2px', fontWeight: 'bold', color: 'red' }} > Would you like to delete {deliverableTemplate.deliverableTemplateName}?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => deleteTemplate()} >
                        <span style={{ marginLeft: '5px'}}  >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => setIsConfirmTemplate(false)} >
                        <span style={{ marginLeft: '5px' }}  >No</span>
                    </button>
                </Modal.Body>
            </Modal>

            <Modal show={isConfirmDocument} onHide={() => setIsConfirmDocument(false)} size="lg">
                <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}} >
                    Confirm Message
                    <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setIsConfirmDocument(false)}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}} >
                    <div>
                        <RiErrorWarningLine size={25} color={'red'} />
                        <span style={{ marginLeft: '2px', fontWeight: 'bold', color: 'red' }} > Would you like to delete {deliverableDocument?.deliverableItemName} ?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }}  onClick={() => deleteDocument()} >
                        <span style={{ marginLeft: '5px'}}  >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }}  onClick={() => setIsConfirmDocument(false)} >
                        <span style={{ marginLeft: '5px' }}  >No</span>
                    </button>
                </Modal.Body>
            </Modal>
            <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}} >
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}} >Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}}  onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}} >
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}} >
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}} >{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}} >
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}   onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}}  >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

            <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
            <Modal.Body style={{ textAlign: 'center',padding: 0}} >
                <div className="alert alert-success" role="alert" style={{marginBottom: 0}} >
                    <AiOutlineCheckCircle color="green" size={25}/>
                    <span style={{marginLeft:'10px',color:'green'}} >Success</span>
                </div>
            </Modal.Body>
        </Modal>
     
        </div>
    );
  
}

export default AdminDeliverableTemplate
