import React from 'react';
import {Color } from '../enum'
interface Props{
    text:string
}
const LoadingMini = (props:Props) => {
    return(
        <div style={{textAlign: 'center',margin:'auto'}} >
            <button className="btn btn-primary" type="button" style={{margin:'auto',backgroundColor: 'white',border: 'none',color: Color.Blue}}  disabled>
                <span className="spinner-border" role="status" aria-hidden="true" style={{ width : '2rem',height: '2rem',borderWidth:'0.4rem'}} ></span>
                <span style={{ fontSize: '14px',fontWeight: 'bold'}} >{props.text}</span>
            </button>
        </div>
    )
}

export default LoadingMini