export default class StringHelper {
    
    static substr(value,start,length) {
       
        if(value == "" || value == null) {
            return ""
        }
        
        return value.length < length ? value : value.substr(0,length) + "..."
    }

    static getLength(value) {
       
        if(value == "" || value == null) {
            return 0
        }
        
        return value.length
    }

    static htmlEncode(str) {
       
            return String(str).replace(/[^\w. ]/gi, function(c){
                return '&#'+c.charCodeAt(0)+';';
            });
        
    }
}