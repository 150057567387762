import axios from 'axios'
import qs from 'qs'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { User }  from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class GraphApiService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }

    async getByUername(username:string){
        let options = {
            headers:this.headers,
            params: {
                username: username
            }
        }
        const { data }   = await axios.get<DataObjectResponse<User>>(`${apiUrl}/v1/graphapi/user`,options )
        return data;
    }

    async Search(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<User>>(`${apiUrl}/v1/graphapi/search`,options )
        return data;
    }

    async SearchDb(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<User>>(`${apiUrl}/v1/graphapi/search/db`,options )
        return data;
    }

    async SearchRole(key:string,roleSystemLevelId:number){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key),
                roleSystemLevelId:roleSystemLevelId
            }
        }
        const { data }   = await axios.get<DataArrayResponse<User>>(`${apiUrl}/v1/graphapi/search/role`,options )
        return data;
    }

    async SearchOther(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<User>>(`${apiUrl}/v1/graphapi/search/other`,options )
        return data;
    }

    async SearchUsername(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<User>>(`${apiUrl}/v1/graphapi/search/username`,options )
        return data;
    }
}