import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import { ProjectProgress,AuthenUser,RoleProjectLevel } from '../../models'
import { DateHelper,StringHelper } from '../../helpers';
import { Color ,SystemLevel} from '../../enum';
import uniqid from 'uniqid';

interface Props{
  phaseId: number
  progresses: ProjectProgress[]
  trackBudget: number
  trackProgress: number
  spi:number
  bpi:number
  user:AuthenUser
  projectRole:RoleProjectLevel | null,
}

const StatusChart = (props:Props) => {
  const isSuperAdmin = props.user.roleSystemLevelId == SystemLevel.SuperAdmin ? 1 : 0
  const progresseData:ProjectProgress[] = props.progresses
  var costChart:Chart
  var planActualChart:Chart
  
  useEffect(() => {
    createChart()
  });

  const createChart = () => {
      
      let progressPlan:any[] = []
      let progressActual:any[] = []
      let spending:any[] = []
      let budget:any[] = []
      let planCost:any[] =[]
      let actualCost:any[] = []
      let labels: string[] = []
      
      if(progresseData.length > 0 && (props.trackBudget || props.trackProgress)){
          var summaryDate = DateHelper.getDate(progresseData[progresseData.length -1 ].progressDate)
          var summaryElement = document.getElementById('summary-label') as HTMLDivElement
          if(summaryElement){
            summaryElement.innerHTML = StringHelper.htmlEncode("Summary as of  ") + summaryDate
          }
          
  
      }
   
      progresseData.forEach(item => {
          let planProgressValue = item.planProgress >= 0 ? item.planProgress : null
          let progressActualValue = item.actualProgress >=0 ? item.actualProgress : null
          let budgetValue = item.costProjection >=0 ? item.costProjection : null
          let spendingValue = item.costCumulative >=0 ? item.costCumulative : null
          let planCostValue = item.planCost >=0 ? item.planCost : null
          let actualCostValue = item.actualCost >=0 ? item.actualCost : null

          progressPlan.push(planProgressValue)
          progressActual.push(progressActualValue)
          spending.push(spendingValue)
          budget.push(budgetValue)
          planCost.push(planCostValue)
          actualCost.push(actualCostValue)
          labels.push(DateHelper.getMonthYear(item.progressDate))
      
      })

      if(props.trackProgress)
      {
        let element = document.getElementById('plan-actual-chart') as HTMLCanvasElement
        element.style.display = 'inline'
          createPlanActualChart(progressPlan,progressActual,labels)
      }
      if(props.trackBudget)
      {
        if(isSuperAdmin || (props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1)){
          let element = document.getElementById('cost-chart') as HTMLCanvasElement
          element.style.display = 'inline'
          createCostChart(spending,budget,actualCost,planCost,labels)
        }
      }
        
    
}

  const createPlanActualChart = (progressPlan:number[],progressActual:number[],labels:string[]) => {
    if(planActualChart){
      planActualChart.destroy()
    }

    let canvasElement = document.getElementById('plan-actual-chart') as HTMLCanvasElement
   
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Progress Plan',
          backgroundColor: Color.Blue,
          borderColor: Color.Blue,
          data:progressPlan,
          tension: 0.8
        },
        {
          label: 'Progress Actual',
          backgroundColor: Color.Red,
          borderColor: Color.Red,
          data: progressActual,
          tension: 0.8
        }
      ]
    };

    planActualChart = new Chart(
      canvasElement,
      {
        type: 'line',
        data: data,
        options: {
          scales: {
            y: {
              title:{
                display: true,
                text: '%',
                color: 'grey',
                font: {
                  size: window.innerWidth > 428 ? 12 : 8,
                  family: 'tahoma'
                },
              },
              min: 0,
              ticks:{
                font:function(context) {
                  var width = context.chart.width;
                  var size = 12
                  if(width > 200 && width <= 290){
                    size = 9
                  }
                  if(width <= 200){
                    size = 6
                  }
                  return {
                      size: size
                  };
                },
              }
            },
            x: {
              ticks:{
                font:function(context) {
                  var width = context.chart.width;
                  var size = 12
                  if(width > 200 && width <= 290){
                    size = 9
                  }
                  if(width <= 200){
                    size = 6
                  }
                  return {
                      size: size
                  };
                },
              }
            },
          },
          plugins:{
            title: {
              display: true,
              text: "Project Progress",
              font:{
                size: 14
              }
            },
            subtitle: {
              display: false,
              text: 'Summary as of Mar 03,2022',
              color: '#00338D',
              font: {
                size: 12,
                family: 'tahoma',
                weight: 'bold',
                style: 'italic'
              },
              padding: {
                bottom: 10
              }
            },
            legend: {
              labels: {
                font:function(context) {
                  var width = context.chart.width;
                  var size = 12
                  if(width > 200 && width <= 250){
                    size = 9
                  }
                  if(width <= 200){
                    size = 6
                  }
                  return {
                      size: size
                  };
                },
              }
            },
          },
          responsive: true ,
          maintainAspectRatio: false,
        }
      }
    );
    //console.log(myChart)
    //myChart.canvas.parentNode.style.height = '150px';
    //myChart.canvas.parentNode.style.width = '100%';
  }

  const createCostChart = (spending:any[],budget:any[],actualCost:any[],planCost:any[],labels:any[]) => {
    if(costChart){
      costChart.destroy()
    }

    let canvasElement = document.getElementById('cost-chart') as HTMLCanvasElement
    let budgetSet = {
      label: 'Current Approved Budget',
      backgroundColor: Color.Red,
      borderColor: Color.Red,
      data: budget,
      tension: 0.8
    }
    let spendingSet = {
      label: 'Estimated Spending at Completion',
      backgroundColor: Color.DarkBlue,
      borderColor: Color.DarkBlue,
      data: spending,
      tension: 0.8
    }
    let actualCostSet = {
      label: 'Actual Cost',
      backgroundColor: Color.Blue,
      borderColor: Color.Blue,
      data: actualCost,
      tension: 0.8
    }
    let planCostSet =  {
      label: 'Plan Cost',
      backgroundColor: Color.Green,
      borderColor: Color.Green,
      data: planCost,
      tension: 0.8
    }

    let dataSets = [spendingSet,budgetSet,actualCostSet,planCostSet]
    // if(props.phaseId === 4) {
    //     dataSets.push(projectionSet)
    //     dataSets.push(cumulativeSet)
    // }

    
    const data = {
      labels: labels,
      datasets: dataSets
    };

  
    costChart = new Chart(
      canvasElement,
      {
        type: 'line',
        data: data,
        options: {
          scales: {
            y: {
              ticks:{
                font:function(context) {
                  var width = context.chart.width;
                  var size = 12
                  if(width > 200 && width <= 290){
                    size = 9
                  }
                  if(width <= 200){
                    size = 6
                  }
                  return {
                      size: size
                  };
                },
              },
              title:{
                display: true,
                text: 'MMUSD',
                color: 'grey',
                font: {
                  size: window.innerWidth > 428 ? 12 : 6,
                  family: 'tahoma'
                },
              },
              min: 0
            },
            x: {
              ticks:{
                font:function(context) {
                  var width = context.chart.width;
                  var size = 12
                  if(width > 200 && width <= 290){
                    size = 9
                  }
                  if(width <= 200){
                    size = 6
                  }
                  return {
                      size: size
                  };
                },
              }
            },
          },
          plugins:{
            title: {
              display: true,
              text: "Project Cost",
              font:{
                size: 14
              }
            },
            subtitle: {
              display: false,
              text: 'Summary as of Mar 03,2022',
              color: '#00338D',
              font: {
                size: 12,
                family: 'tahoma',
                weight: 'bold',
                style: 'italic'
              },
              padding: {
                bottom: 10
              }
            },
            legend: {
                  labels: {
                    font:function(context) {
                      var width = context.chart.width;
                      var size = 12
                      if(width > 200 && width <= 250){
                        size = 9
                      }
                      if(width <= 200){
                        size = 6
                      }
                      return {
                          size: size
                      };
                    },
                  }
                },
          },
          responsive: true ,
          maintainAspectRatio: false,
        }
      }
    );
    
    //console.log(myChart)
    //myChart.canvas.parentNode.style.height = '150px';
    //myChart.canvas.parentNode.style.width = '100%';
  }

    return (
      <>
        { (props.trackBudget && (isSuperAdmin || props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1)) && props.trackProgress ?
          <div className='row'>
              <div className='col-md-6'>
                  
                  <div  className="chart-container chart-container-status"> 
                      <canvas key={uniqid()} id="plan-actual-chart"></canvas> 
                  </div> 
              </div>
              <div className='col-md-6'>
                    <div className="pagebreak"> </div>
                    <div  className="chart-container chart-container-status"> 
                        <canvas key={uniqid()}  id="cost-chart"></canvas> 
                    </div> 
              </div>
              
          </div>: 
          <div className='row'>
               <div className='col-md-3'></div>
              <div className='col-md-6'>
                <div  className="chart-container chart-container-status"> 
                  <canvas key={uniqid()}  id="plan-actual-chart" style={{ display: 'none'}} ></canvas> 
                  <canvas key={uniqid()}  id="cost-chart" style={{ display: 'none'}} ></canvas> 
                </div> 
              </div>
              <div className='col-md-3'></div>
          </div>
        }
      </>
    );
  
}

export default StatusChart