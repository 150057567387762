import React,{useState,useEffect} from 'react';
import '../../assets/css/admin.css'
import AdminUser from './AdminUser'
import AdminRole from './AdminRole'
import AdminDeliverableTemplate from './AdminDeliverableTemplate';
import AdminProjectType from './AdminProjectType'
import AdminAssetType from './AdminAssetType'
import AdminAsset from './AdminAsset'
import AdminProjectCategory from './AdminProjectCategory'
import AdminEmailTemplate from './AdminEmailTemplate'
import AdminEmailLog from './AdminEmailLog'
import AdminEmail from './AdminEmail'
import AdminHistory from './AdminHistory'
import AdminCountry from './AdminCountry'
import AdminLocation from './AdminLocation'
import AdminProjectMode from './AdminProjectMode'
import AdminDeliverableItem from './AdminDeliverableItem'
import AdminAssuranceActivity from './AdminAssuranceActivity'
import AdminUpload from './AdminUpload'
import AdminManual from './AdminManual'
import { Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import AdminPartner from './AdminPartner';
import { AuthenUser} from '../../models';
import  secureLocalStorage  from  "react-secure-storage";

initializeIcons()

const Admin = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const [menu,setMenu] = useState('user')
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const navStyles: Partial<INavStyles> = {
        root: {
          boxSizing: 'border-box',
          border: '1px solid #eee',
          overflowY: 'auto',
        },
      };
      const navLinkGroups: INavLinkGroup[] = [
        {
          links: [
            { name: 'Role And Permission', url: '', key: 'role', icon: "Completed"},
            { name: 'User Management', url: '', key: 'user', icon: "Contact"  },
            { name: 'Project Category', url: '', key: 'project-mode', icon: 'WebAppBuilderModule' },
            // { name: 'Project Type', url: '', key: 'project-type', icon: 'MapLayers' },
            { name: 'Project Category Size', url: '', key: 'category', icon: 'LineSpacing' },
            { name: 'Assurance Activity', url: '', key: 'assurance-activity',icon:'ProductList' },
            { name: 'Deliverable Item', url: '', key: 'deliverable-item', icon: 'DependencyAdd'},
            { name: 'Deliverable Template', url: '', key: 'deliverable-template',icon: 'WebAppBuilderFragment' },
            { name: 'Country', url: '', key: 'country', icon: 'Video360Generic' },
            { name: 'Asset', url: '', key: 'asset', icon: 'Tag' },
            { name: 'Asset Type', url: '', key: 'asset-type', icon: 'Nav2DMapView' },
            { name: 'Location', url: '', key: 'location', icon: 'MapPin' },
            { name: 'Partner', url: '', key: 'partner', icon: "PieSingle"  },
            { name: 'Email', url: '', key: 'email', icon: 'Mail' },
            // { name: 'EmailTemplate', url: '', key: 'emailTemplate', icon: 'Mail' },
           // { name: 'EmailLog', url: '', key: 'emailLog', icon: 'Mail' },
            { name: 'Upload File', url: '', key: 'upload', icon: 'CloudUpload' },
            { name: 'History Logs', url: '', key: 'history', icon: 'PageData' },
            { name: 'Admin Manual', url: '', key: 'manual', icon: 'Pdf' },
          ],
        },
        
      ];
      const [isLoading,setIsLoading] = useState(true)

    useEffect(() => {
        if(user.administratorView > 0 || user.administratorUpdate > 0){
          let _menu = urlParams.get('m')
          if(_menu){
            setMenu(_menu)
          }
          setIsLoading(false)
        }
        else{
          window.location.href = '/dashboard?projectMode=1'
        }
    },[])
      
    const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
       
        if(item && item.key){
            setMenu(item.key)
        }
         
      }
    return (
        <div className="container-fluid">
          { !isLoading ?
            <>
            <nav className="nav-menu" aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-bottom">
                 
                   
                    <li className="breadcrumb-item" aria-current="page">
                        Admin
                    </li>
                </ol>
                
            </nav>
            <div className='row'>
                
                <Nav
                    onLinkClick={onLinkClick}
                    selectedKey={menu}
                    ariaLabel="Nav basic example"
                    styles={navStyles}
                    groups={navLinkGroups}
                    className='admin-side-menu'
                />
                
                <div className='admin-content'>
                    { menu === 'user' ? <AdminUser/>: null }
                    { menu === 'role' ? <AdminRole/>: null }
                    { menu === 'deliverable-template' ? <AdminDeliverableTemplate/>: null }
                    { menu === 'deliverable-item' ? <AdminDeliverableItem/>: null }
                    { menu === 'assurance-activity' ? <AdminAssuranceActivity/> : null}
                    { menu === 'project-type' ? <AdminProjectType/>: null }
                    { menu === 'asset-type' ? <AdminAssetType/>: null }
                    { menu === 'asset' ? <AdminAsset/>: null }
                    { menu === 'project-mode' ? <AdminProjectMode/>: null }
                    { menu === 'country' ? <AdminCountry/>: null }
                    { menu === 'location' ? <AdminLocation/>: null }
                    { menu === 'category' ? <AdminProjectCategory/>: null }
                    { menu === 'email' ? <AdminEmail/>: null }
                    { menu === 'emailTemplate' ? <AdminEmailTemplate/>: null }
                    { menu === 'emailLog' ? <AdminEmailLog/>: null }
                    { menu === 'partner' ? <AdminPartner/>: null }
                    { menu === 'history' ? <AdminHistory/>: null }
                    { menu === 'upload' ? <AdminUpload/>: null }
                    { menu === 'manual' ? <AdminManual/>: null }
                </div>
            </div>
            </>
            :<></>
          }
        </div>
    )
}
export default Admin