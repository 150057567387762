import axios from 'axios'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import {ProjectPhase } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ProjectPhaseService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    async getByProjectId(id:number ){
        let options = {
                headers: this.headers,
                params: {
                    projectId: id
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<ProjectPhase>>(`${apiUrl}/v1/projectphases/project`, options )
        
        return data;
    }

    

   
}