import axios from 'axios'
import {  DataObjectArrayResponse,PageResponse ,PageResponseWithDate,DataArrayResponse} from '../models/PageResponse'
import {ProjectDeliverable } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

export default class ProjectDeliverableService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    async getByProjectId(id:number ){
        let options = {
                headers:this.headers,
                params: {
                    projectId: id
                }
            }
        
        const { data }  = await axios.get<DataObjectArrayResponse<ProjectDeliverable>>(`${apiUrl}/v1/projectdeliverables/call`, options )
        
        return data;
    }

    async getFiles(id:number ){
        let options = {
                headers:this.headers,
                params: {
                    projectId: id
                }
            }
        
        const { data }  = await axios.get<DataArrayResponse<string>>(`${apiUrl}/v1/projectdeliverables/files`, options )
        
        return data;
    }

    async updateRemark(projectDeliverable: ProjectDeliverable){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/projectdeliverables/remark`,JSON.stringify(projectDeliverable),options )
        return data;
    }

    async updateStatus(projectDeliverable: ProjectDeliverable){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponseWithDate>(`${apiUrl}/v1/projectdeliverables/status`,JSON.stringify(projectDeliverable),options )
        return data;
    }

}