import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import InformationForm from './InformationForm';
import { PropertyService} from '../../services'
import { useApiAuth } from '../../hooks/useApiAuth';
import { Project, Property,AuthenUser } from '../../models'
import Loading from '../Loading';
import  secureLocalStorage  from  "react-secure-storage";

const ProjectCreate = () => {
    const { account, accessToken } = useApiAuth();
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [property, setProperty ] = useState<Property>()
    const [isLoading,setIsLoading] = useState(true)
    const projectData: Project ={
        projectId: 0,
        projectModeId: 0,
        projectModeName: "",
        startPhaseId: 0,
        startPhaseName: "",
        registerDateTime: new Date(),
        projectNo: "",
        assetId: 0,
        assetName:"",
        assetCode: "",
        lat: 0,
        lng: 0,
        locationId: 0,
        locationName: "",
        assetTypeId: 0,
        assetTypeName: "",
        resourceTypeId: 0,
        resourceTypeName: "",
        categorySizeId: 0,
        categorySizeName: "",
        projectTypeId: 0,
        projectTypeName: "",
        developmentScenarioId: 0,
        developmentScenarioName:"",
        initialProjectName: "",
        currentProjectName: "",
        projectValue: 0,
        changeInDevelopmentConcept: -1,
        changeInTermAndCondition: -1,
        concessions: "",
        productTypeId: 0,
        productTypeName: "",
        countryName: "",
        projectDescription: "",
        projectStatusId: 1,
        projectStatusName: "",
        currentPhaseId: 0,
        currentStageId: 0,
        remark: "",
        note: "",
        projectOwners: [],
        projectManagers: [],
        focalPoints: [{ username: user ? user.username : "", name: account && account.name ? account.name : ""}],
        gateKeepers: [],
        teamMembers: [],
        secretaries:[],
        projectBlocks:[],
        active: 1,
        createBy: "",
        updateBy:"",
        categorySizeThreshold: 0
    }
    useEffect(()=> {
        let propertyFlag = ['projectMode','phase','projectType','developmentScenario','resourceType','asset','assetType','categorySize','country','productType','projectStatus','date']
        Promise.all([new PropertyService(accessToken).getProperties(propertyFlag)]).then(result => {
            //console.log(result)
            setIsLoading(false)

            if(result[0] && result[0].success){
                setProperty(result[0].data)
            }
          
        })

    },[accessToken])
  
    return (
        
        <div className="container-fluid">
             
            <nav className="nav-menu" aria-label="breadcrumb">
                
                <ol className="breadcrumb breadcrumb-bottom">
                    
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link id="dashboard-link" to="/dashboard">
                        <span>Portfolio Dashboard</span>
                        </Link>
                    </li>

                    <li className="breadcrumb-item" aria-current="page">
                        <span>Create Project</span>
                    </li>
                </ol>
            </nav>
            <div style={{ "padding":"15px"}} >
                <div className="body">
                    <div className="tabs">
                       { !isLoading ?
                            <>
                            { user ? (user.projectCreate && property ?
                                <InformationForm initialData={projectData} property={property}/> 
                                : null) : null
                             }
                            </>:<Loading text="Loading..."/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProjectCreate