import axios from 'axios'
//import qs from 'qs'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { DeliverableItem,DeleteData  } from '../models'
import { config } from '../config'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL
const version = 1

export default class DeliverableItemService
{
    protected headers = {}
 protected token = ""

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
    }
    async getAll(page: number,limit: number,key:string = ""){
        let options = {
                headers: this.headers,
                params: {
                    page: page,
                    limit: limit,
                    key: encodeURI(key)
                }
            }
                                                                                        
        const { data }  = await axios.get<PageDataResponse<DeliverableItem>>(`${apiUrl}/v${version}/deliverableItems/call`, options )
        
        return data;
    }

    async getAllName(){
        let options = {
                headers: this.headers
            }
        
        const { data }  = await axios.get<PageDataResponse<DeliverableItem>>(`${apiUrl}/v${version}/deliverableitems/name`, options )
        
        return data;
    }

    async getLimit(){
        let options = {
                headers: this.headers
            }
        
        const { data }  = await axios.get<DataArrayResponse<DeliverableItem>>(`${apiUrl}/v${version}/deliverableitems/limit`, options )
        
        return data;
    }

    async Search(key:string){
        let options = {
            headers:this.headers,
            params: {
                key: encodeURI(key)
            }
        }
        const { data }   = await axios.get<DataArrayResponse<DeliverableItem>>(`${apiUrl}/v1/deliverableitems/search`,options )
        return data;
    }

    async Create(deliverableItem: DeliverableItem){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }   = await axios.post<PageResponse>(`${apiUrl}/v1/deliverableItems/call`,JSON.stringify(deliverableItem),options )
        return data;
    }

    async Update(deliverableItem: DeliverableItem){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers: this.headers
        }
        const { data }  = await axios.put<PageResponse>(`${apiUrl}/v1/deliverableItems/call`,JSON.stringify(deliverableItem),options )
        return data;
    }

    async Delete(id: number,updateBy: string){
        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<PageResponse>(`${apiUrl}/v${version}/deliverableItems/delete`,JSON.stringify(deleteData),options )
        return data;
    }


}